import React, {Component} from 'react';
import './index.scss';
import service from '../../../public/service';
import { Link } from 'react-router-dom'
import {FaTimes, FaPlus, FaMinus, FaCartPlus, FaChevronRight, FaChevronLeft} from "react-icons/fa"
import { addDays, getDay, subDays } from "date-fns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const ExampleCustomInput = (props) => {
  const { date, placeHolder, onClick, className } = props
  return(
  <button className={"Btn"} onClick={onClick}>
    { date?service.formatDate(  date , false):placeHolder  }
  </button>
  )
};

class Detail extends Component {
  constructor(props) {
    super(props)
    this.state={
      tab : "detail",
      quantity : 1,
      clicked : false,
      showGallery : false,
      gIndex : 0,
      shops : [],
      shop : "",
      //date : new Date(),
      date : null,
      isBuy : true,
      picked_options : {},
      error_options : {},
      includeDates : []
    }
    this.init = this.init.bind(this)
    this.placeOrder = this.placeOrder.bind(this)
    this.selectBrand = this.selectBrand.bind(this)

    this.getI18n = this.getI18n.bind(this)
    this.changeOption = this.changeOption.bind(this)
    this.changeShop = this.changeShop.bind(this)
  }
  
  componentDidMount() {
  }

  componentWillUnmount() {
    this.init()
  }

  componentDidUpdate(props, state) {
    if( props.selected !== this.props.selected ) {
      //console.log("HERE?")
      if(this.props.selected) {
        const {pickup_dates} = this.props.selected
        if( pickup_dates && pickup_dates.length > 0 ) {
          this.setState({
            //date : new Date(pickup_dates[0])
            date : null,
            shop : "",
            includeDates : [],
            picked_options : {}
          })
        }  
      }
    }
  }

  init() {

    const {selected} = this.props
    this.setState({
      tab : "detail",
      quantity : 1,
      clicked : false,
      showGallery : false,
      gIndex : 0,
      //shops : shops,
      brand : null,
      shop : "",
      shop_code : null,
      date : null,
      isBuy : true,
      includeDates : []
    })
  }

  placeOrder() {
    const {quantity, shop, date, shop_code, picked_options} = this.state
    const {placeOrder, selected, brands, updateParent, i18n, language, hasLogin, updatevalue} = this.props
    var error_options = {}, add_amount = 0

    //CHECK OPTIONS
    const { detail, tier_pricing, item_type } = selected

    var valid = !tier_pricing || (tier_pricing && hasLogin)

    if( valid ) {
      if( detail ) {
        var options = detail['option_groups']
        for (var o of options) {
          const p = picked_options[ o['id'] ]
          if( !p ) {
            if ( o['select_min'] > 0 ) {
              error_options[ o["id"] ] = true
            } 
          } else {
            if ( o['select_min'] > p['options'].length ) {
              error_options[ o["id"] ] = true
            }
            for (var u of p['options']) {
              add_amount += u['add_amount']
            }
          }
          
        }

        if( Object.keys(  error_options ).length > 0 ) {
          this.setState({
            error_options : error_options
          }, ()=>{
            alert(i18n['es_select_option'][language])
          })
          return
        }
      }

      if( ((quantity > 0) && (shop && shop!=="") && (date!==null)) || (item_type==="coupon") ) {
        var _this = this
        this.setState({
          clicked : true,
          error_options : error_options
        }, async ()=> {
          var obj = { 
            options : picked_options, 
            add_amount : add_amount , 
            item : selected, 
            quantity : quantity, 
            pickup : { shop : shop, date : date, shop_code : shop_code }  
          }
          let a = await placeOrder(obj)
          if( a ) {
            updateParent( { selected : null } )
            setTimeout( () => {
              _this.setState({
                clicked : false
              })

              alert(i18n['es_placed_item'][language])
            }, 300 )
          }
        })
      } else {
        alert(i18n['es_placed_error'][language])
      }
    } else {
      //console.log("LOGIN")
      const a = window.confirm(i18n['required_login'][language])
      if(a) {
        updatevalue({
          showLogin : true
        })
      }
    }


  }

  selectBrand(i) {
    const { brands,language } = this.props
    const s_brand = brands.filter( (v) => v['id'] === parseInt(i, 10) )
    var shops = []
    if( s_brand.length > 0 ) {
      shops = s_brand[0]['shop']
    }
    this.setState({
      brand :parseInt(i, 10),
      shops : shops
    })
  }

  getI18n(key) {
    const { i18n, language } = this.props
    return ( key in i18n )?i18n[key][language]:key
  }

  changeOption(e, value, original_option) {
    var {picked_options} = this.state
    const {i18n, language} = this.props
    if( original_option['id'] in picked_options ) {
      var op = picked_options[original_option['id']]['options'].filter( (v) => v['id'] === value['id'] )
      if( op.length === 0 ) {
        if( original_option['select_max'] > picked_options[original_option['id']]['options'].length ) {
          picked_options[original_option['id']]['options'].push( value )
        } else {
          picked_options[original_option['id']]['options'].splice(0, 1)
          picked_options[original_option['id']]['options'].push( value )
          //alert(i18n['es_excceed_selection'][language])
          //e.preventDefault()
        }
      } else {
        var nop = picked_options[original_option['id']]['options'].filter( (v) => v['id'] !== value['id'] )
        picked_options[original_option['id']]['options'] = nop
      }
    } else {
      picked_options[original_option['id']] = JSON.parse( JSON.stringify( original_option ) )
      picked_options[original_option['id']]['options'] = []
      picked_options[original_option['id']]['options'].push( value )
    }
    
    this.setState({
      picked_options : picked_options
    })
  }

  changeShop(shop) {

    const {brands, selected} = this.props
    const shops = service.pickupShops( brands, selected['pickup_shop_codes'] )
    const s = shops.filter( (v)=>v['shop_code']===shop )[0]
    const {ui_text} = s
    const {pickup_dates} = selected
    var _pickup_dates = []
    if( ui_text['business_hour'] ) {
      const bh = ui_text['business_hour']['value'].split("|")
      const days = bh[0].split(",")
      _pickup_dates = pickup_dates.filter( (v)=> days.includes(   ( ((new Date(v)).getDay()===0)?7:((new Date(v)).getDay()) ).toString()    ) )
    } else {
      _pickup_dates = pickup_dates
    }
    this.setState({ shop : shop, date : null, includeDates : _pickup_dates })
  }

  render() {
    const { brands, i18n, language, config, selected, updateParent, user, hasLogin } = this.props
    const { tab, quantity, clicked, showGallery, gIndex, brand, shop, date, isBuy, picked_options, error_options, includeDates  } = this.state
    if(selected) {
      try {
        //console.log( user )
        //console.log(selected)
        var price_display = 0
        const { ui_text, asset, detail, pickup_dates, price, tier_pricing, item_type} = service.massageAsset2( selected )
        var image = null, options = null, add_amount = 0, valid_day_before = 0

        if(picked_options) {
          //add_amount = p['options'].reduce((a, b) => a['add_amount'] + b['add_amount'], 0)
          for (var o of Object.values(picked_options) ) {
            for (var b of o['options']) {
              var valid = ((hasLogin && tier_pricing) || hasLogin || (!tier_pricing) )
              if(valid) {
                add_amount += b['add_amount']
              }
            }
          }
        }

        if( detail ) {
          options = detail['option_groups']
        }

        if( ui_text && ui_text['cover'] ) {
          if( asset && asset[ ui_text['cover']['value'] ] ) {
            image = `${service.asset}/${asset[ ui_text['cover']['value'] ]['file_path']}`
          }
        }

        if( ui_text && ui_text['valid_day_before'] ) {
          valid_day_before = parseInt( ui_text['valid_day_before']['value'], 10  )
        }

        const shops = service.pickupShops( brands, selected['pickup_shop_codes'] )

        const {original_price, discount_type} = ui_text

        var appendix = ""
        
        if( tier_pricing ) {
          if ( hasLogin ) {
            const gp = tier_pricing.filter( (v)=>v['tier_level']===user['user']['tier_level'] )
            if( gp.length > 0 ) {
              appendix = original_price?`${i18n[`es_member_price`][language]}:`:""
              price_display = gp[0]['price']>-1?gp[0]['price']:price
            } else {
              price_display = price
            }
          } else {
            appendix = original_price?`${i18n[`es_member_price`][language]}:`:""
            price_display = tier_pricing[0]['price']>-1?tier_pricing[0]['price']:price
          }
        } else {
          appendix = original_price?
                         `${(discount_type?i18n[`es_${discount_type['value']}_price`][language]:i18n[`es_special_price`][language])}:`:""
          price_display = price
        }
        //appendix = original_price?`${(discount_type?i18n[`es_${discount_type['value']}_price`][language]:i18n[`es_special_price`][language])}:`:""

        var start = service.timeDiff( new Date() , valid_day_before * 24 * 60  )

        const minDate = Math.max(new Date( start ) ,new Date())
        
        const _includeDates = includeDates.map( (v) => new Date( v ) )


        return (
          <div className={["Detail" , selected?"Show":""  ].join(" ")}>
              <div className={["D-Header"].join(" ")}>
                <Link to={`${window.location.pathname}${window.location.search}`}>
                  <div className={["D-I-Holder"  ].join(" ")} onClick={()=>{
                    this.init()
                    updateParent( { selected : null } )
                  }}> 
                    <FaTimes className={["Fa"].join(" ")}/>
                    <span>  { `${i18n["close_window"][language]}` } </span>
                  </div>
                </Link>
              </div>
              <div className={["D-Content"].join(" ")}>
                <div className={["D-C-Cover"].join(" ")} >
                  <div className={["D-C-C-Top"].join(" ")}>
                    <div className={["D-C-C-Wrapper"].join(" ")}>
                      {
                        original_price && original_price['value'] &&
                        <div className={["D-C-C-P-Original"].join(" ")}> { `${i18n['es_original_price'][language]} : $ ${  parseFloat(original_price['value'], 10).toLocaleString()}` } </div>
                      }
                      <div className={["D-C-C-P-Price"].join(" ")}> 
                          <span className={["Appendix"].join(" ")}>{ appendix }</span>
                          <span>{`$ ${ price_display.toLocaleString()}`}</span>
                      </div>
                    </div>
                    
                  </div>
                  { 
                    image &&
                    <img src={image} className={["D-C-C-Img"].join(" ")} />
                  }
                  {
                    !image &&
                    <div className={["D-C-C-Img"].join(" ")}></div>
                  }
                  <div className={["D-C-C-Gallery"].join(" ")}>
                    {
                      ui_text['gallery'] &&
                      ui_text['gallery']['value'].split(',').map( (v, k) => {
                        try {
                          var img = `${service.asset}/${asset[ v ]['file_path']}`
                          return (
                            <img key={k} src={img} onClick={ (e)=>{
                              this.setState({
                                showGallery : true,
                                gIndex : k
                              })
                            } }/>
                          )
                        } catch(e) {
                          return null
                        }
                      } )
                    }
                  </div>
                </div>
                <div className={["D-C-Content"].join(" ")}>
                  <div className={["D-C-Title"].join(" ")}>
                    {
                      item_type==="coupon" &&
                      <span className={["D-Badge"].join(" ")}>{ i18n["es_coupon"][language]  }</span>
                    }
                    {    
                      ui_text['display']?
                        ui_text['display'][language]:
                          selected['name']
                    }
                  </div>
                  <div className={["D-C-Valid"].join(" ")}>  
                    {
                      `${ selected['start_time'] } - ${ selected['stop_time'] }`
                    }
                  </div>

                  <div className={["D-C-Terms"].join(" ")}> 
                    <div className={["D-C-Tabs"].join(" ")}>  
                      <div className={["D-C-Tab", (tab==="detail")?"Selected":""].join(" ")} onClick={ ()=>{ this.setState({tab : "detail"}) } }>{ i18n['es_pu_details'][language] }</div>
                      {
                        (ui_text["terms"]) &&
                        <div className={["D-C-Tab", (tab==="terms")?"Selected":"" ].join(" ")} onClick={ ()=>{ this.setState({tab : "terms" }) }} >{ i18n['es_pu_terms'][language] }</div>
                      }
                    </div>
                    <div className={["D-C-T-Content"].join(" ")}> 
                      {
                        (tab==="detail")?ui_text["desc"][language]:""
                      }
                      {
                        (tab==="terms")?(ui_text["terms"]?ui_text["terms"][language]:""):""
                      }
                    </div>
                  </div>

                </div>
              </div>
              {
                isBuy &&
                <div className={["D-Options"].join(" ")}>
                  {
                    options && options.length > 0 &&
                    <div className={["Options"].join(" ")}>
                      <div className={["O-Title"].join(" ")}>{i18n['es_pick_options'][language]}</div>
                      {
                        options.map( (v1, k1) => {
                          const str = (v1['select_min']!==v1['select_max'])?`${v1['select_min']}-${v1['select_max']}`:v1['select_max']
                          return (
                            <div className={["D-Option-Wrapper", (v1['id'] in error_options)?"Error":""  ].join(" ")} key={k1}> 
                              <div className={["D-Option-Title"].join(" ")}>{
                                `${  this.getI18n( v1['description'] ) }  [${ i18n['es_pick_num_options'][language].replace("<val>", str) }]`
                              }</div>
                              <div className={["D-Option-Group"].join(" ")}> 
                                {
                                  v1['options'].map( (v2, k2) => {
                                    var o = []
                                    if( picked_options[ v1['id'] ] ) {
                                      o = picked_options[ v1['id'] ]['options'].filter( (vv)=>vv['id']===v2['id'] )
                                    }
                                    //console.log(picked_options)
                                    //console.log(o)
                                    return (
                                      <div className={["D-Option-Group-Option"].join(" ")} key={k2}>
                                        <input 
                                          className={["D-Option-Group-Option-Checkbox"].join(" ")}
                                          type="checkbox" 
                                          checked={ (o.length > 0)  }
                                          name={ this.getI18n( v2['name'] ) } 
                                          value={ k2 }
                                          onChange={ (e)=>{
                                            this.changeOption(e, v2, v1 )
                                          } }
                                        />
                                        <div className={["D-Option-Group-Option-Name"].join(" ")}>  { this.getI18n( v2['name'] ) } </div>
                                        <div className={["D-Option-Group-Option-Price"].join(" ")}> { `+ $${v2['add_amount'].toLocaleString()}` } </div>
                                      </div>
                                    )
                                  } )
                                }
                              </div>
                            </div>
                          )
                        } )
                      }
                    </div>
                  }
                  
                  {/*PICKUP PLACE*/}
                  {
                    item_type !== "coupon" &&
                    <div className={["D-Pick-Up"].join(" ")}>
                      <div className={["Column"].join(" ")}>
                        <div className={["Title"].join(" ")}>{ i18n['es_pu_pickup'][language] }</div>
                        <div className={["Row"].join(" ")}>

                          <select className={['Select'].join(" ")} value={shop} onChange={(e)=>{
                            this.changeShop( e.target.value )
                          }}>
                            <option value={ "" }>{i18n['es_pu_point'][language]}</option>

                            {
                              shops.map( (v,k) => {         
                                try {
                                  const name = (v['ui_text'] && v['ui_text']['display'])?v['ui_text']['display'][language]:v['name']
                                  return (
                                    <option key={k} value={
                                      v['shop_code']
                                    }> {`${name}`}  </option>
                                  )
                                } catch(e) {
                                  return null
                                }
                              } )
                            }

                          </select>
                        </div>
                      </div>
                      <div className={["Column"].join(" ")}>
                        <div className={["Title"].join(" ")}>{i18n['es_pu_pickup_time'][language]}</div>
                        <div className={["Row"].join(" ")}>
                          <DatePicker 
                            dateFormat={'dd/MM/yyyy'}
                            minDate={ /*new Date()*/
                              minDate
                            }
                            wrapperClassName={"Calendar"}
                            includeDates={_includeDates}
                            placeholderText={i18n['es_pu_date'][language]}
                            popperPlacement="top-end"
                            selected={ date } 
                            customInput={
                             <ExampleCustomInput 
                                className={ "Calendar" }
                                date={ date} 
                                placeHolder={i18n['es_pu_date'][language]}/>
                            }
                            onChange={(date) => this.setState({ date : date }) } />
                        </div>
                      </div>
                    </div>
                  }
                  
                  <>
                    <div className={['D-Contact'].join("")} 
                      dangerouslySetInnerHTML={{__html: i18n['es_contact_pt_html'][language]}}
                    >
                    </div>

                    <div className={["D-Cart"].join(" ")}>
                        <div className={["D-C-Q-Wrapper"].join(" ")}>
                            <div className={["Q-Text"].join(" ")}>{"QTY"}</div>
                            <div className={["Q-Wrapper"].join(" ")}>
                              <div className={["Fa-Wrapper"].join(" ")} onClick={ ()=>{
                                if( item_type !== "coupon" ) {
                                  if( quantity-1 >= 1 ) {
                                    this.setState({
                                      quantity : quantity-1
                                    })
                                  }
                                } else {
                                  this.setState({
                                    quantity : 1
                                  })
                                }
                              } }>
                                <FaMinus className={["Fa"].join(" ")}/>
                              </div>
                              <input
                                className={["Quantity"].join(" ")} 
                                value={quantity} onChange={ (e)=>{
                                if( item_type !== "coupon" ) {
                                  if( service.validateNum(e.target.value) || e.target.value.length === 0 ) {
                                    this.setState({
                                      quantity : (e.target.value.length !== 0)?parseInt( e.target.value , 10):0
                                    })
                                  }
                                } else {
                                  this.setState({
                                    quantity : 1
                                  })
                                }
                              } } />
                              <div className={["Fa-Wrapper"].join(" ")} onClick={ ()=>{
                                //if( quantity+1 < 0 ) {
                                  if( item_type !== "coupon" ) {
                                    this.setState({
                                      quantity : quantity+1
                                    })
                                  } else {
                                    this.setState({
                                      quantity : 1
                                    })
                                  }
                                //}
                              } }>
                                <FaPlus className={["Fa"].join(" ")}/>
                              </div>
                            </div>
                        </div>
                        <div className={["D-C-Confirm", clicked?"Clicked":"", (((quantity > 0) && (shop && shop!=="") && (date!==null)) || (item_type === "coupon")  )?"":"Disabled"].join(" ")} onClick={()=>{
                            if( ((quantity > 0)  && (shop && shop!=="") && (date!==null)) || (item_type === "coupon") ) {
                              this.placeOrder()
                            }
                          }}>
                            <FaCartPlus className={["Text"].join(" ")}/>
                            {
                              quantity > 0 &&
                              <span className={["Sub"].join(" ")}>{  
                                `$ ${( quantity * (price_display + add_amount) ).toLocaleString()}`
                              }</span>
                            }
                        </div>
                    </div>
                  </>
                </div>
              }


              {/*PHOTO GALLERY*/}
              <div className={["D-S-Gallery", showGallery?"Show" : ""].join(" ")}>
                <div className={["D-Header"].join(" ")}>
                  <div className={["D-I-Holder"  ].join(" ")} onClick={()=>{
                    this.setState({
                      showGallery : false
                    })
                  }}> 
                    <FaTimes className={["Fa"].join(" ")}/>
                    <span>  { `${i18n["close_window"][language]}` } </span>
                  </div>
                </div>
                <div className={["D-Photo"].join(" ")}>
                  {
                    ui_text['gallery'] &&
                    ui_text['gallery']['value'].split(',').map( (v,k) => {
                      try {
                        var img = `${service.asset}/${asset[ v ]['file_path']}`
                        return (
                          <img key={k} 
                            src={img} 
                            className={['D-Img' , gIndex===k?"Selected":"" ].join(" ")}/>
                        )
                      } catch(e) {
                        return null
                      }
                    } )
                  }
                  <div className={["Left"].join(" ")} onClick={()=>{
                    var l = ui_text['gallery']['value'].split(',')
                    var i = (gIndex-1>=0)?(gIndex-1):(l.length-1)
                    this.setState({ gIndex :i })
                  }}>
                    <FaChevronLeft className={["Fa"].join(" ")} />
                  </div>
                  <div className={["Right"].join(" ")} onClick={()=>{
                    if( showGallery ) {
                      var l = ui_text['gallery']['value'].split(',')
                      var i = (gIndex+1>=l.length)?0:(gIndex+1)
                      this.setState({ gIndex :i })
                    }
                  }}>
                    <FaChevronRight className={["Fa"].join(" ")} />
                  </div>
                  <div className={["Dots-Selector"].join(" ")}>
                    {
                      ui_text['gallery'] &&
                      ui_text['gallery']['value'].split(',').map( (v,k) => {
                        return (
                          <div onClick={ ()=>{
                            this.setState({ gIndex : k })
                          } } 
                            className={['Dot', k===gIndex?"Selected":""].join(" ")} key={k}></div>
                        )
                      })
                    }
                  </div>
                </div>
              </div>

          </div>
        )
      } catch(e){
        console.log(e)
        return null
      }
    } else {
      return null
    }
  }
}

export default Detail;


