import React, {Component} from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';

import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link';

import { FaRegEnvelopeOpen } from 'react-icons/fa';
import service from '../../public/service';
//

const sub = [
  //{"key" : "name"},
  {"key" : "email"}
]

class Footer extends Component {
  constructor(props) {
    super(props)
    this.state={
      //name : "",
      email : "",
      isPrivacy : false,
      isTerms : false,
      tree : {},
    }
  }
  
  componentDidMount() {
    var _keys = {}, isPrivacy = false, isTerms = false
    for (var i in this.props.content) {
      
      if(  this.props.content[i].footer   ) {
        if( !(this.props.content[i].footer in _keys) ) {
          _keys[ this.props.content[i].footer ] = []
        }
        _keys[ this.props.content[i].footer ].push(   this.props.content[i]    )
      }
      if( this.props.content[i].key === "privacy") {
        isPrivacy = true
      }
      if( this.props.content[i].key === "terms") {
        isTerms = true
      }

    }
    this.setState({
      tree : _keys,
      isPrivacy : isPrivacy,
      isTerms : isTerms 
    })
  }

  componentDidUpdate(props, state) {
    //console.log("A")
  }

  render() {

    const {config} = this.props

    var showFooter = true

    for (var s of service.footer_excecptions) {
      if( window.location.pathname.indexOf(s) > -1 ) {
        showFooter = false
        break;
      }
    }

    return (
      <div className={["Footer", !showFooter?"Disabled":""].join(" ")}>
        <div className={["Footer-Top-Frame"].join(" ")}>

          <div className={["Lefts"].join(" ")}>
            <div className={["Left"].join(" ")}>
              <img 
                src={ window.logo } 
                className={["Logo"].join(" ")}/>
            </div>
            {
              Object.keys(this.state.tree).map( (v, k) => {
                return (
                  <div className={["Left"].join(" ")} key={k}>
                    {
                      this.state.tree[v].map( (val, key) => {
                        if( key !== 0) {
                          if( val.link ) {
                            return (
                              <a
                                onClick={ ()=>{
                                  window.scrollTo(0,0)
                                  this.props.updatevalue({ selected  : val.key})
                                  this.setState({
                                    show : false
                                  })
                                } }
                                href={ val.link}
                                key={key}
                                target="_blank"
                                className={["Link"].join(" ")}
                              > 
                                {  val.display[this.props.language]  } 
                              </a>
                            )
                          } else {
                            if( val.component ) {
                              return (
                                <Link 
                                  onClick={ ()=>{
                                    this.props.updatevalue({ selected  : val.key})
                                    window.scrollTo(0, 0)
                                  } }
                                  key={key}
                                  to={val.key}
                                  className={['Link'].join(" ")}> 
                                  {  val.display[this.props.language]  }
                                </Link>
                              )
                            } else {
                              return(
                                <HashLink
                                  smooth
                                  scroll={(el) => {
                                    window.scrollTo(0,0)
                                    this.props.updatevalue({ selected  : val.key})
                                    setTimeout( ()=>{
                                      el.scrollIntoView({ behavior: "auto", block: 'start', inline : "start" })
                                    }, 400)
                                  }}
                                  to={"/"+val.footer+"#"+val.key}
                                  key={key}
                                  className={["Link"].join(" ")}>
                                    {  val.display[this.props.language]  }
                                </HashLink>
                              )
                            }
                          }
                        } else {
                          return(
                              <div
                                key={key}
                                className={["Link", "None"].join(" ")}
                              > 
                                {  val.display[this.props.language]  } 
                              </div>
                          )
                        }
                      })
                    }
                  </div>
                )
              } )
            }
          </div>


          {
            this.props.config && this.props.config.subscribe &&
            <div className={["Right"].join(" ")}>

              <div className={["Title-Frame"].join(" ")}>
                <div className={["Icon-Wrapper"].join(" ")}>
                  <FaRegEnvelopeOpen className={['Icon'].join(" ") } />
                </div>
                <div className={["T-Wrapper"].join(" ")}>
                  <div className={["Text"].join(" ")}>{  this.props.i18n?this.props.i18n['subscribeus'][this.props.language]:""  }</div>
                  { this.state.isPrivacy &&
                    <Link className={["Policy"].join(" ")}
                      to={"privacy"}
                    >
                      { "* " + (this.props.i18n?this.props.i18n['subscribe_policy'][this.props.language]:"")  }
                    </Link>
                  }
                </div>
              </div>
              {
                sub.map( (v,k) => {
                  return (
                    <div className={["Frame"].join(" ")} key={k}>
                      <div className={["Name"].join(" ")}> {  this.props.i18n?this.props.i18n[v.key][this.props.language]:""  } </div>
                      <input 
                        placeholder={  this.props.i18n?this.props.i18n[v.key][this.props.language]:""  }
                        className={["Input"].join(" ")} 
                        onChange={ (e)=> {
                          var o = {}
                          o[v.key] = e.target.value
                          this.setState(o) 
                        }}
                        value={this.state[v.key]} />
                        <div className={["Signup"].join(" ")}>
                          <div className={["Text"].join(" ")}>
                            {  this.props.i18n?this.props.i18n['signup'][this.props.language]:""  }
                          </div>
                          <div className={["SFrame"].join(" ")}></div>
                        </div>
                    </div>
                  )
                } )
              }
              
            </div>
          }
        </div>


        <div className={["Footer-Bottom-Frame"].join(" ")}>
          <div className={["Copyright"].join(" ")}>
            {  this.props.config?this.props.config['general']['copyright'][this.props.language]:""  }
          </div>
          <div className={["Terms-Frame"].join(" ")}> 
            { 
              this.state.isPrivacy &&
              <Link 
                onClick={()=>window.scrollTo(0,0)}
                className={["Term-Btn"].join(" ")} to={ this.props.content['privacy'].key }> 
                { this.props.content['privacy']['display'][this.props.language] } 
              </Link>
            }
            { 
              this.state.isTerms &&
              <Link 
                onClick={()=>window.scrollTo(0,0)}
                className={["Term-Btn"].join(" ")} to={ this.props.content['terms'].key }> 
                { this.props.content['terms']['display'][this.props.language] } 
              </Link>
            }
            {
              config && config['eshop'] &&
              <Link 
                onClick={()=>window.scrollTo(0,0)}
                className={["Term-Btn"].join(" ")} to={ this.props.content['eshop_terms'].key }> 
                { this.props.content['eshop_terms']['display'][this.props.language] } 
              </Link>
            }
          </div>
        </div>
      </div>
    )
  }
}

export default Footer;

