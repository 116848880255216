import React, {Component} from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import service from "../../public/service"

//https://justinmahar.github.io/react-social-media-embed/?path=/docs/embeds-facebookembed--text-post-example


class FB extends Component {
  constructor(props) {
    super(props)
    this.state={
      posts : [],
    }
    this.readPosts = this.readPosts.bind(this)
  }
  
  componentDidMount() {
    //godofteppanyak
    //https://graph.facebook.com/demirobot?fields=id,name,posts&access_token=EAAHbxgi6Yg0BAExvnQncYoQiiMi2DdGBYG7qNXKAo8vVjancbir1rzHzP2XySMpHoz4e0iEwE6rCEZCZCAZCnumerz6RtHggcbQQU31BZCLSemRd6jIgi1wCWs023mHqwpZBBnJCay6ls9rDZCZC3B1bxRuK35hUsT88keVJY8HZAMzVITfaZBASEQ1SCskFkmKBsIsYIiZAnTV4V0U5Le2QHHVaxx6BvIK9wZD"
    //var url = "https://graph.facebook.com/" + this.props.config.fbpage 
    //console.log(url)
    //service.getFBAcc( this.props.config.fbpage , this.props.config.fb_access_token  ).then(
      //(res) => {
        //console.log(res)
        //if( res.access_token ) {
          
        //}
      //}
    //)
    this.readPosts()
  }

  readPosts() {
    service.getFBAcc( "godofteppanyak" , this.props.config.fb_token  ).then(
      (res) => {
        console.log(res)
        service.getPostContent( "godofteppanyak" , res.access_token  ).then(
          (response) => {
            console.log( response )
            if( response.posts  ) {
              
              this.setState({
                posts : response.posts.data
              })
            }
          }
        )
      }
    )
  }

  render() {
    return (
      <div className={["FB"].join(" ")}>
        <div class="fb-page" 
data-tabs="events"
data-href="https://www.facebook.com/YoloBookStore"
data-width="380"></div>
        <div className={["Content"].join(" ")}>
          {
            this.state.posts.map( (v,k) => {
              if( v.message ) {
                var arr = [], title = ""
                if( v.message) {
                  arr = v.message.split("\n")
                  title = arr.shift()
                }
                return (
                  <a
                    href={v.permalink_url} 
                    key={k} className={["Box"].join(" ")}>
                    <div className={["Image-Holder"].join(" ")}>
                      <img src={v.full_picture} className={['Cover-Img'].join(" ")} />
                    </div>
                    <div className={["Message"].join(" ")}> 
                      <div className={["Title"].join(" ")}>{title}</div>
                      <div className={["Body"].join() }>
                        {
                          arr.map( (val, key) => {
                            return (
                              <p className={[].join(" ")} key={key}> {val} </p>
                            )
                          } )
                        }
                      </div>
                    </div>
                  </a>
                )
              }
            } )
          }
        </div>
      </div>
    )
  }
}

export default FB;