import React, {Component} from 'react';
import './index.scss';
import service from '../../public/service';

class GiftCard extends Component {
  constructor(props) {
    super(props)
    this.state={
      fr : null,
      m : "",
      f : "",
      t : "",
      a : "",
      v : 0
    }
  }
  
  componentDidMount() {
    const params = service.getParamQuery()
    if( "r" in params ) {
      try {
        const m = JSON.parse( service.decode(params['r']) )
        var o = {}
        for( var i in m ) {
          o[i] = service.decodeChar( m[i] )
        }
        this.setState(o)
      } catch(e) {
        window.location.href = window.location.origin
      }
    } else {
      window.location.href = window.location.origin
    }
    
    
  }

  componentWillUnmount() {

  }

  render() {
    //const {i18n, language } = this.props
    const { fr,f,a,m,t, v } = this.state
    return (
      <div className={["GiftCard"].join(" ")}>
        {
          fr &&
          <div className={["Card"].join(" ")} 
            style={{
              backgroundImage : `url(${ service.asset }/assets/gifts/frame/${fr}.png)`
            }}>
            <div className='Amount'> 
              <span className='sign'>$</span>
              <span className='value'>{ v.toLocaleString() }</span>
            </div>
          </div>
        }
        <div className='anim-typewriter'> 
          
          {
            ["t", "m", "a", "f"].map( (v,k) => {
              if(v==="m") {
                return (
                  <h1 key={k} style={{
                    animationDelay : `${k}s`
                  }}>{ this.state[v]?this.state[v]:'' }</h1>
                )
              } else if (v==="t") {
                return (
                  <pre key={k} 
                    style={{
                      animationDelay : `${k}s`
                  }}>{`Dear ${this.state[v]?this.state[v]:""} ,` }</pre>
                )
              } else if (v==="f") {
                return (
                  <div key={k} >
                    <pre className='From' style={{
                        animationDelay : `${k}s`
                    }}>{"From"}</pre>
                    <pre 
                      style={{
                        animationDelay : `${k+1}s`
                    }}>{ this.state[v]?this.state[v]:"" }</pre>
                  </div>
                )
              } else {
                return (
                  <pre key={k} style={{
                    animationDelay : `${k}s`
                  }}>{ this.state[v]?this.state[v]:""}</pre>
                )
              }
            } )
          }
          
        </div>
        <div className='Logo'>
          <img className='Img' src={window.logo} />
        </div>
        <div className='BG'></div>
      </div>
    )
  }
}

export default GiftCard;


