import React, {Component} from 'react';
import './index.scss';
import service from '../../../public/service';

//`${window.location.origin}/wallet/topup?session=${session_key}&id=${user_id}&language=${language}`

class Topup extends Component {
  constructor(props) {
    super(props)
    this.state={
      user : null,
      isLogin : false,
      redirect : false,
      offers : [],
    }
    this.getOffers = this.getOffers.bind(this)
    this.getUser = this.getUser.bind(this)
    this.purchase = this.purchase.bind(this)
    this.i18n=this.i18n.bind(this)
  } 
  
  componentDidMount() {
    var session_key = null, user_id = null, redirect = false
    const params = service.getParamQuery()
    if( params['session'] ) {
      session_key = params['session']
    } else {
      if( service.session_key  ) {
        session_key = service.session_key
      } else {
        window.location.href = window.location.origin
      }
    }

    if( params['id'] ) {
      user_id = params['id']
    } else {
      if( service.user  ) {
        user_id = service.user.id
      } else {
        window.location.href = window.location.origin
      }
    }

    if( params['redirect'] ) {
      redirect = (params['redirect']==="1")
    }
    if(session_key&&user_id) {
      service.session_key = session_key 
      this.setState({
        redirect : redirect
      }, ()=>{
        this.getUser( user_id )
        this.getOffers()
      })
    } else {
      window.location.href = window.location.origin
    }

  }

  componentWillUnmount() {

  }

  async getUser(user_id) {
    let user = await service.getUser( user_id )
    service.user = user
    if( user.id ) {
      this.setState({ isLogin : true , user : user})
    }
  }

  async getOffers() {
    let data = await service.getWalletOffer()
    this.setState( {
      offers : data
    } )
  }

  i18n(key){
    const {language, i18n} = this.props
    return (key in i18n)?i18n[key][language]:key
  }

  async purchase(offer) {
    const {redirect} = this.state
    const {language} = this.props
    const response = window.confirm( this.i18n("confirm_purchase") )
    if( response ) {
      const { user } = this.state
      if( user ) {
        let data = await service.purchaseCreate(  user['id'] , offer['id']  )
        if( data['id'] ) {
          //const {id, total} = data
          const {id} = data
          const url = `${window.location.origin}/payment?purchase_id=${id}&language=${language}&mode=${"dev"}`
          if( !redirect ) {
            window.open( url, "_blank" )
          } else {
            window.location.href = url
          }
        } else {
          alert(  this.i18n("tp_fail_purchase")  )
        }
      }
    }
  }

  render() {

    const { i18n, language } = this.props
    const { isLogin, user, offers } = this.state
    const bg = `${service.asset}/assets/gifts/card_bg.png`
    return (
      <div className={["Topup"].join(" ")} >
        {
          isLogin &&
            <>
            <div className={["Content"].join(" ")}> 
              {
                user && user['wallet'] &&
                <div className="Holder">
                  <div className="Title"> { i18n["wallet_my_token"][language]  } </div>
                  <div className="Token"> {   user['wallet']['token'].toLocaleString()   } </div>
                </div>
              }
              {
                !(user && user['wallet']) &&
                <div className="Holder">
                  <pre className="Title"> { i18n["wallet_my_token"][language]  } </pre>
                  <div className="Token"> {   (0).toLocaleString()   } </div>
                </div>
              }
            </div>
            <div className={["T-Header"].join(" ")}>{i18n["token_package"][language]}</div>
            <div className={["Container"].join(" ")}>
              {
                offers.map( (v,k) => {
                  return (
                    <div className={["Card"].join(" ")} 
                      style={{
                        backgroundImage : `url(${bg})`,
                        backgroundSize: "100%",
                        backgroundPosition: "right top",
                        backgroundRepeat : "no-repeat"
                      }}
                      key={k} onClick={ ()=>{
                      this.purchase(v)
                    } }>
                      <div className={["Top"].join(" ")}> 
                        <div className={["Dollar"].join(" ")}>$</div>
                        <div className={["Price"].join(" ")}> { v['price'].toLocaleString(undefined, {minimumFractionDigits:0}) } </div>
                      </div>
                      <div className={["Spliter"].join(" ")}> </div>
                      <div className={["Bottom"].join(" ")}> 
                        {
                          Object.keys( v['detail'] ).map( (v1, k1) => {
                            return (
                              <div className={["Wrapper"].join(" ")} key={k1}>
                                <div className={["Title"].join(" ")}> { i18n[`wallet_${v1}`][language] } </div>
                                <div className={["Body"].join(" ")}> 
                                  { 
                                    v['detail'][v1].toLocaleString(undefined, {minimumFractionDigits: 0})
                                  } 
                                </div>
                              </div>
                            )
                          } )
                        }
                        
                      </div>
                    </div>
                  )
                } )
              }
            </div>
            </>
        }

      </div>
    )
  }
}

export default Topup;


