import React, {Component} from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import { FaTimes, FaChevronLeft, FaMapMarkerAlt, FaPhoneAlt } from 'react-icons/fa';
import { RiWhatsappFill } from 'react-icons/ri';
import service from '../../public/service';


var to = null, interval=null
//const SHOW = 99999999
const SHOW = 30

class Popup extends Component {
  constructor(props) {
    super(props)
    this.state={
      selected : 0,
      display : this.props.display,
      countdown : SHOW,
      isFlip : false,
      selectedImage : null
    }
    
    this.initial = this.initial.bind(this)
    this.close = this.close.bind(this)
    this.renderImages = this.renderImages.bind(this)

  }
  
  componentDidMount() {
    var index = -1
    if(this.props.selectedpopup) {
      for (var i in this.props.popups) {
        if( this.props.popups[i].key === this.props.selectedpopup ) {
          index = i
          break
        }
      }
    }
    if( index > -1) {
      this.setState({
        selected : index,
        countdown : null
      })
    } else {
      this.initial()
    }


    
  }

  initial() {
    var _this = this
    interval = setInterval(()=>{
      _this.setState({
        selectedImage :null,
        countdown : (this.state.countdown-1)
      })
    }, 1000)

    to=setTimeout( () => {
      if( _this.state.display ) {
        _this.setState({
          display : false
        })
        _this.close()
      }
    } , SHOW*1000 )
  }

  close() {
    if(interval) {
      clearInterval( interval )
    }
    if(to) {
      clearTimeout(to)
    }

  }

  componentWillUnmount() {
    this.close()
  }

  renderImages(v, k) {
    const {selectedImage} = this.state
    return (
      <div className={["gallery"].join(" ")} key={k}>
        {
          v['images'].map( (v1, k1) => {
            return (
              <img 
                onClick={ ()=> {
                  this.setState({
                    selectedImage : (selectedImage===k1)?null:k1
                  })
                }}
                src={v1} 
                key={k1} 
                className={["img", (selectedImage===k1)?"Full":null].join(" ")}/>
            )
          } )
        }
      </div>
    )
  }


  render() {
    var _c = (this.props.popups.length>0)?this.props.popups[ this.state.selected ]:null
    const {brands, i18n, language} = this.props
    try {
      var _clist = [], _rlist = []
      if ( _c.store_list ) {
        for (var i in _c.store_list) {
          var o = {}
          o.title = _c.store_list[i].title

          var _arr = brands.filter( (v)=>Object.keys(_c.store_list[i].stores).includes(v.brand_code)  )

          o.stores = []
          for (var j in _arr) {
            var _o = _arr[j]
            try {
              _o.stores = _o['shop'].filter( (v)=>_c.store_list[i].stores[ _o["brand_code"] ].includes(v['shop_code']) )
              o.stores.push( _o )
            } catch(e){}
          }

          o.stores=o.stores.sort((a, b) => {
            return (
              Object.keys( _c.store_list[i].stores ).indexOf(a.key) - Object.keys( _c.store_list[i].stores ).indexOf(b.key)
          );


        });
          _clist.push( o )
        }
      }

      if ( _c.redemption_store_list ) {
        for (var i in _c.redemption_store_list) {
          var o = {}
          o.title = _c.redemption_store_list[i].title

          var _arr = brands.filter( (v)=>Object.keys(_c.redemption_store_list[i].stores).includes(v.brand_code)  )
          o.stores = []
          for (var j in _arr) {
            var _o = _arr[j]
            try {
              _o.stores = _o['shop'].filter( (v)=>_c.redemption_store_list[i].stores[ _o["brand_code"] ].includes(v['shop_code']) )
              o.stores.push( _o )
            } catch(e){}
          }

          o.stores = o.stores.sort((a, b) => {
            return (
              Object.keys( _c.redemption_store_list[i].stores ).indexOf(a.key) - Object.keys( _c.redemption_store_list[i].stores ).indexOf(b.key)
          );


        });
          _rlist.push( o )
        }
      }

      var store_list = (_clist.length>0)?_clist:[ { stores : brands }]
      var redemption_store_list = (_rlist.length>0)?_rlist:[ { stores : [] }]
  
      //document.title =  _c?(((this.props.language in _c.display)?(_c.display[this.props.language] + " | "):"") + this.props.config['general']['name'][this.props.language]):this.props.config['general']['name'][this.props.language]
      var image = (typeof( _c.image )==="object")?image[this.props.language]:_c.image


      return (
        <div className={["Popup", this.state.display?"Show":"Hide"].join(" ")}>
          <div className={["Close"].join(" ")} onClick={(e)=> {
            this.close()
            this.setState({ display : false, countdown : null, isFlip : false })
          }}>
            <FaTimes className={["Icon"].join(" ")} />
            <div className={["Text"].join(" ")}> { `${this.props.i18n["close_window"][this.props.language]} ${(this.state.countdown&&this.state.countdown>-1)?("("+this.state.countdown+")"):""}` } </div>
          </div>
          <div className={["Upper"].join(" ")}>
            <div className={["Holder", this.state.isFlip?"Flip":""].join(" ")} onClick={
              (e) => {
                if( !_c.link ) {
                  //if(_c.stores && !this.state.isFlip) {
                    if( !this.state.isFlip) {
                    this.setState( {
                      isFlip : true,
                      countdown : null
                    })
                    this.close()
                  }
                } else {
                  window.open( _c.link, "_self" )
                }
              }
            }>
              {
                (_c && _c.image) &&
                  <img
                    className={["Image"].join(" ")}
                    src={ 
                      image
                    } />
                
              }
              {
                !(_c && _c.image) &&
                <div className={["Image-Cover"].join(" ")}>
                  { this.props.i18n['commingsoon'][ this.props.language ] }
                </div>
              }
              <div className={["Stores-Frame"].join(" ")}>
                <div className={["Top-Panel"].join(" ")}> 
                  <div className={["Back"].join(" ")} onClick={ (e)=>{ this.setState({ isFlip : false }) } }> 
                    <FaChevronLeft className={["Icon"].join(" ")}/>
                    <div className={["Title"].join(" ")}>{ _c['display'][this.props.language]  }</div>
                  </div>
                </div>
                <div className={["Mid-Panel"].join(" ")}> 
                  <img className={["Logo"].join(" ")} src={ window.location.origin + "/resources/img/l7_logo_h_constrast.png "} />

                  <div className={["Messages"].join(" ")}>
                    {
                      _c.messages.map( (v,k) => {
                        if( !v.link  ) {
                          try {
                            if ( v.type === 'gallery' ) {
                              return this.renderImages(v, k)
                            } else {
                              const _m = v.message[this.props.language].split("\n")
                              return (
                                <span key={k}> 
                                  {
                                    _m.map( (v1,k1) => {
                                        return (
                                          <p
                                            key={k1}
                                            className={[ ((v.type)?v.type:"") ].join(" ")}> 
                                              { v1 } 
                                          </p>
                                        )
                                      }
                                    )
                                  }
                                </span>
                              )
                            }
                          } catch(e) {}
                        
                        } else if ( v.type === 'video' ) {
                          if( this.state.isFlip && v.link ) {
                            /*
                            return (
                              <video 
                                loop={true}
                                controls 
                                src={v.link} 
                                autoPlay={this.state.isFlip?true:false} 
                                className={["video"].join(" ")}>
                              </video>
                            )*/
                            return (
                              <div dangerouslySetInnerHTML={{
                                __html: v.link
                              }} />
                            )
                          } else {
                            return null
                          }
                        } else {
                          if( v.type === "whatsapp" ) {
                            try {
                              return (
                                <a className={['icon-holder'].join(" ")} href={v.link} key={k}>
                                    <RiWhatsappFill className={['icon'].join(" ")}/>
                                    <div className={['text'].join(" ")}> { v.message[this.props.language] }  </div>
                                </a>
                              )
                            } catch(e) {}
                          } else {
                            try {
                              return (
                                  <a className={['link'].join(" ")} href={v.link} target='_self' key={k}>
                                    { v.message[this.props.language] } 
                                  </a>
                              )
                            } catch(e) {}
                          }
                        }
                      } )
                    }
                  </div>
                </div>
                <div className={["Bottom-Panel"].join(" ")}> 
                    {
                      _c['stores'] &&
                      <>
                      {
                        store_list.map( (store, index) => {
                            return (
                              <div key={index} className={['Store-Group'].join(" ")}>
                                {
                                  store.title &&
                                  <div className={['Store-Group-Title'].join(" ")}> 
                                    {  store.title[this.props.language].split("\n").map(
                                      (text,idx) => {
                                        return (
                                          <div key={idx}>
                                            { text }
                                          </div>
                                        )
                                      }
                                    )  }
                                  </div>
                                }
                                {
                                  store.stores.map( (v,k) => {
                                    if( v.enabled ) {
                                      const {ui_text, asset} = v
                                      return (
                                        <div className={["Brand-Holder"].join(" ")} key={k}> 
                                          {
                                            ui_text['image'] && ui_text['image']['value'] && asset[  ui_text['image']['value'] ] &&
                                            <img className={["Logo"].join(" ")} src={  `${service.asset}/${asset[  ui_text['image']['value'] ]['file_path']}` }/>
                                          }
                                          <div className={["Shop-Holder"].join(" ")}> 
                                            <div className={["Brand-Name"].join(" ")}>
                                              {
                                                ui_text["display"][this.props.language]
                                              }
                                            </div>
                                            {
                                              v['stores'].map( (val,key) => {
                                                if( val.enabled ) {
                                                  const { ui_text } = val
                                                  return(
                                                    <div className={["ShopBox"].join(" ")} key={key}> 
                                                      <div className={['Name'].join(" ")}> { ui_text['display'][this.props.language] } </div>
                                                      {
                                                      _c.address && ui_text['location'] && ui_text['location']['value'] &&
                                                        <a
                                                          target={"_blank"}
                                                          href={ ui_text['location']['value'] } 
                                                          className={['Address'].join(" ")}>
                                                          <FaMapMarkerAlt className={['Icon'].join(" ")}/>
                                                          <div className={['Text'].join(" ")}> { ui_text['address'][this.props.language] } </div>
                                                        </a>
                                                      }
                                                      {
                                                      _c.tel && 
                                                        <div className={['Tel'].join(" ")} onClick={  () => {
                                                          var a =  ui_text['tel']['value'].replace(/ /g, "").split(")")
                                                          if( a.length > 1 ) {
                                                            window.open( ('tel:' + a[1]), "_self" )
                                                          }
                                                        }}>
                                                          <FaPhoneAlt className={['Icon'].join(" ")}/>
                                                          <div className={['Text'].join(" ")}> { this.props.i18n['tel'][this.props.language].replace("<phone>",ui_text['tel']['value'] ) } </div>
                                                        </div>
                                                      }
                                                    </div>
                                                  )
                                                } else {
                                                  return null
                                                }
                                              } )
                                            }
                                          </div>
                                        </div>
                                      )
                                    } else {
                                      return null
                                    }
                                  } )
                                }
                              </div>
                            )
                          }
                        )
                      }
                      </>
                    }
                </div>

                {/*REDEMPTION LIST*/}
                <div className={["Bottom-Panel"].join(" ")}> 
                    {
                      _c['stores'] &&
                      <>
                        {
                          redemption_store_list.map( (store, index) => {
                              return (
                                <div key={index} className={['Store-Group'].join(" ")}>
                                  {
                                    store.title &&
                                    <div className={['Store-Group-Title'].join(" ")}> 
                                      {  store.title[this.props.language].split("\n").map(
                                        (text,idx) => {
                                          return (
                                            <div key={idx}>
                                              { text }
                                            </div>
                                          )
                                        }
                                      )  }
                                    </div>
                                  }
                                  {
                                    store.stores.map( (v,k) => {
                                      if( v.enabled ) {
                                        const {ui_text, asset} = v
                                        return (
                                          <div className={["Brand-Holder"].join(" ")} key={k}> 
                                            {
                                              ui_text['image'] && ui_text['image']['value'] && asset[  ui_text['image']['value'] ] &&
                                              <img className={["Logo"].join(" ")} src={  `${service.asset}/${asset[  ui_text['image']['value'] ]['file_path']}` }/>
                                            }
                                            <div className={["Shop-Holder"].join(" ")}> 
                                              <div className={["Brand-Name"].join(" ")}>
                                                {
                                                  ui_text["display"][this.props.language]
                                                }
                                              </div>
                                              {
                                                v['shop'].map( (val,key) => {
                                                  if( val.enabled ) {
                                                    const { ui_text } = val
                                                    return(
                                                      <div className={["ShopBox"].join(" ")} key={key}> 
                                                        <div className={['Name'].join(" ")}> { ui_text['display'][this.props.language] } </div>
                                                        {
                                                        _c.address && 
                                                          <a
                                                            target={"_blank"}
                                                            href={ ui_text['location']['value'] } 
                                                            className={['Address'].join(" ")}>
                                                            <FaMapMarkerAlt className={['Icon'].join(" ")}/>
                                                            <div className={['Text'].join(" ")}> { ui_text['address'][this.props.language] } </div>
                                                          </a>
                                                        }
                                                        {
                                                        _c.tel && 
                                                          <div className={['Tel'].join(" ")} onClick={  () => {
                                                            var a =  ui_text['tel']['value'].replace(/ /g, "").split(")")
                                                            if( a.length > 1 ) {
                                                              window.open( ('tel:' + a[1]), "_self" )
                                                            }
                                                          }}>
                                                            <FaPhoneAlt className={['Icon'].join(" ")}/>
                                                            <div className={['Text'].join(" ")}> { this.props.i18n['tel'][this.props.language].replace("<phone>",ui_text['tel']['value'] ) } </div>
                                                          </div>
                                                        }
                                                      </div>
                                                    )
                                                  } else {
                                                    return null
                                                  }
                                                } )
                                              }
                                            </div>
                                          </div>
                                        )
                                      } else {
                                        return null
                                      }
                                    } )
                                  }
                                </div>
                              )
                            }
                          )
                        }
                      </>
                    }
                </div>


              </div>
            </div>
            <div className={['Frame'].join(" ")} onClick={(e)=> {
                if ( this.state.display ) {
                  this.close()
                  this.setState({ display : false, countdown : null, isFlip : false })
                }
            }}>
            </div>
          </div>
          <div className={["Lower"].join(" ")}>
            <div className={["Event"].join(" ")}>
              {
                this.props.popups.map( (v,k) => {
                  try {
                    var _image = (typeof( v.image )==="object")?image[this.props.language]:v.image
                    return (
                      <div className={["Box"].join(" ")} 
                        onClick={
                          (e) => {
                            clearTimeout(to)
                            clearInterval(interval)
                            this.setState({
                              selected : k,
                              display : true,
                              countdown : null,
                              selectedImage : null
                            })
                          }
                        }
                        key={k}>
                        <img 
                          src={ _image }
                          className={["Img"].join(" ")}/>
                        <div className={["Title"].join(" ")}> 
                          {  v.display[this.props.language] }
                        </div>
                      </div>
                    )
                  } catch(e) {
                    console.log(e)
                  }
                } )
              }
            </div>
          </div>
          
        </div>
      )
    } catch(e) {
      console.log(e)
      return null
    }
  }
}

export default Popup;


/*
[
            {
                "id" : 0,
                "title" : { 
                        "eng" : "Redemption Branch (With Gift Set)",
                        "zhtc" : "月餅禮券發售及禮盒換領分店",
                        "zhcn" : "月饼礼券发售及礼盒换领分店" 
                },
                "stores" : {
                    "kingsdelicacy" : ["CWB", "TST", "KT", "TKO" ],
                    "godofteppanyaki" : ["CWB"]    
                }
            },
            {
                "id" : 1,
                "title" : { 
                        "eng" : "Redemption Branch\n(No Gift Set)",
                        "zhtc" : "月餅禮券發售分店\n(不設禮盒換領)",
                        "zhcn" : "月饼礼券发售分店\n(不设礼盒换领)" 
                },
                "stores" : {
                    "godofteppanyaki" : ["WC", "TST", "KT"]    
                }
            }
        ]
        */