import React, {Component} from 'react';
import './index.scss';
import service from '../../../public/service';

const COUNT = 100

const titles = [
  "id",
  "offer_name",
  "status",
  "total",
  "creation_time",
  "confirmation_time"
]

class TogoHistory extends Component {
  constructor(props) {
    super(props)
    this.state={
      history : [],
      page : 0,
      totalPage : 0,
      shops : {},
      match : {},

      selected : null
    }
    this.findOrder = this.findOrder.bind(this)

    this.renderItemString = this.renderItemString.bind(this)
  }
  
  componentDidMount() {
    //const {brands } = this.props
    //console.log( brands )
    this.findOrder()
  }

  componentWillUnmount() {

  }

  async findOrder() {

    let data = await service.findOrder("OFFIC")
    
    data = data.filter( (v)=>v['status']!=="pending" )
    this.setState({
      history : data
    })
  }

  renderItemString(item) {
    const {offer_detail, id, creation_time, total} = item
    const {ordered_items}  = offer_detail
    const {match} = this.state
    const {language, gi18n} = this.props
    try {
      return (
        <div className={["Th-item"].join(" ")}>
          <h2> { `${gi18n(`to_entitle_order_id`)} : ${ id }` } </h2>
          <h4> { `${gi18n(`select_date`)} : ${ creation_time }` } </h4>
          <h2> { `${gi18n(`payment_subtotal`)} : $${ total.toLocaleString(undefined, {
            minimumFractionDigits: 1,
            maximumFractionDigits: 1
          }) }` } </h2>
          {
            ordered_items.map( (v, k) => {
              var item_name = v["name"]
              if( v['item_code'] in match ) {
                const {ui_text} = match[ v['item_code'] ]
                if( ui_text && ui_text['display'] ) {
                  item_name = ui_text['display'][language]
                }
              }
              return (
                <div key={k} className={["I-Column"].join(" ")}>
                  <div className={["I-Line-1"].join(" ")}>
                    <pre>{ `[${v['item_code']}] ${item_name}` }</pre>
                    <pre className={["Price"].join(" ")}>{ v['total'].toLocaleString(undefined, {
                        minimumFractionDigits: 1,
                        maximumFractionDigits: 1
                    }) }</pre>
                  </div>
                  {
                    (v["option_groups"] && v["option_groups"].length > 0) &&
                    <>
                      <div className={["I-Line-2"].join(" ")}>{`${gi18n("to_entitle_option")}`}</div>
                      {
                        v["option_groups"].map( (v1, k1) => {
                          if( v1['options'] && v1['options'].length > 0  ) {
                            return (
                              <div className={["I-Column"].join(" ")}>
                                <pre className={["I-Line-3"].join(" ")}>{ `${gi18n("to_entitle_option")} ${k1+1} - ${v1["description"]?v1["description"]:"--"}` }</pre>
                                {
                                  v1['options'].map( (v2, k2) => {
                                    return (
                                      <div key={k2} className={["I-Line-3"].join(" ")}>
                                        <pre> { `- ${gi18n(`to_entitle_${v2['name']}`)}` } </pre>
                                        <pre className={["Price"].join(" ")}> { `+${v2['add_amount'].toLocaleString(undefined, {
                                            minimumFractionDigits: 1,
                                            maximumFractionDigits: 1
                                        })}` } </pre>
                                      </div>
                                    )
                                  } )
                                }
                              </div>
                            )
                          } else {
                            return null
                          }
                        })
                      }
                    </>
                  }
                  {
                    ( v['ordered_groups'] && v['ordered_groups'].length > 0 ) && 
                    <>
                      <pre className={["I-Line-2"].join(" ")}>{ `${gi18n("to_entitle_selection")} :` }</pre>
                      {
                        v['ordered_groups'].map( (v1, k1) => {
                          if( v1['ordered_items'] && v1['ordered_items'].length>0 ) {
                            return (
                              <div key={k1} className={["I-Column"].join(" ")}>
                                <pre className={["I-Line-3"].join(" ")}>{ `${gi18n("to_entitle_selection")} ${k1+1}` }</pre>
                                {
                                  v1['ordered_items'].map( (v2,k2) => {
                                    var item_name = v2["name"]
                                    if( v2['item_code'] in match ) {
                                      const {ui_text} = match[ v2['item_code'] ]
                                      if( ui_text && ui_text['display'] ) {
                                        item_name = ui_text['display'][language]
                                      }
                                    }
                                    return (
                                      <div className={["I-Column"].join(" ")}> 
                                        <div className={["I-Line-4"].join(" ")}>
                                          <pre>{ `[${v2["item_code"]}] ${item_name}`}</pre>
                                          <pre className={["Price"].join(" ")}>{ `+${v2['price'].toLocaleString(undefined, {
                                              minimumFractionDigits: 1,
                                              maximumFractionDigits: 1
                                          })}` }</pre>
                                        </div>
                                        {
                                          v2['option_groups'] && v2['option_groups'].length > 0 &&
                                          <div className={["I-Column"].join(" ")}>
                                            {
                                              v2['option_groups'].map( (v3, k3) => {
                                                
                                                if( v3['options'] && v3['options'].length > 0 ) {
                                                
                                                  return (
                                                    <div key={k3} className={["I-Column"].join(" ")}>
                                                      {
                                                        v3['options'].map( (v4, k4) => {
                                                          return (
                                                            <div key={k4} className={["I-Line-5"].join(" ")}>
                                                              <pre> { `- ${gi18n(`to_entitle_${v4['name']}`)}` } </pre>
                                                              <pre className={["Price"].join(" ")}> { 
                                                                `+${
                                                                v4['add_amount'].toLocaleString(undefined, {
                                                                  minimumFractionDigits: 1,
                                                                  maximumFractionDigits: 1
                                                                }) 
                                                                }`
                                                              } </pre>
                                                            </div>
                                                          )
                                                        })
                                                      }
                                                    </div>

                                                  )

                                                } else {
                                                  return null
                                                }
                                              } )
                                            }
                                          </div>
                                        }
                                      </div>
                                    )
                                  } )
                                }
                              </div>
                            )
                          } else {
                            return null
                          }
                        })
                      }
                    </>
                  }
                </div>
              )
            } )
          }
        </div>
      )
    } catch(e) {
      console.log(e)
      return null
    }
  }

  render() {

    const { gi18n, language } = this.props
    const { history, shops, selected } = this.state
    return (
      <div className={["TogoHistory"].join(" ")}>
        <div className={["P-Title"].join(" ")}>{ gi18n("ch_togo_history") }</div>
        <div className={["Row","Title"].join(" ")} >
            {
              titles.map( (v,k) => {
                return (
                  <div className={["Td"].join(" ")} key={k} style={ { flexBasis : `${(1/titles.length*100)}%` } }>
                    { gi18n(`ph_${v}`) }
                  </div>
                )
              } )
            }
        </div>
        <div className={["P-Holder"].join(" ")}>
            {
              history.length > 0 &&
              history.map( (v, k) => {
                return(
                  <div className={["Row"].join(" ")} key={k} onClick={  
                    () => {
                      if( v['offer_detail'] && v['offer_detail']['ordered_items'] ) {
                        this.setState({ selected : v })
                      }
                    }
                  }>
                    {
                      titles.map( (v1,k1) => {
                        
                        if( v1 === "id" ) {
                          return(
                            <div className={["Td"].join(" ")} key={k1} style={ { flexBasis : `${(1/titles.length*100)}%` } }>
                              { v[v1] }
                            </div>
                          )
                        } else {
                          const vp = v1
                          if(  v1.indexOf("_time") > -1 ) {
                            return (
                              <div className={["Td"].join(" ")} key={k1} style={ { flexBasis : `${(1/titles.length*100)}%` } }>
                                { 
                                  v[v1]
                                }
                              </div>
                            )
                          } else if( v1.indexOf("total") > -1 ) {
                            return (
                              <div className={["Td"].join(" ")} key={k1} style={ { flexBasis : `${(1/titles.length*100)}%` } }>
                                { 
                                  `$${v['total']&&v['total'].toLocaleString(undefined, {minimumFractionDigits: 1})}`
                                }
                              </div>
                            )
                          } else if( v1 === "shop_code" ) {
                            try {
                              var ui_text = v['purchase']&&v['purchase'][ vp ]?(shops[ v['purchase'][ vp ] ]&&shops[ v['purchase'][ vp ] ]['ui_text']?shops[ v['purchase'][ vp ] ]['ui_text']:null):null
                              var b_ui_text = v['purchase']&&v['purchase'][ vp ]?(shops[ v['purchase'][ vp ] ]&&shops[ v['purchase'][ vp ] ]['brand']['ui_text']?shops[ v['purchase'][ vp ] ]['brand']['ui_text']:null):null
                              var name = null
                              if( b_ui_text && b_ui_text['display'] ) {
                                name = b_ui_text['display'][language]
                              }
                              if( ui_text && ui_text['display'] ) {
                                name += ("("+ui_text['display'][language]+")")
                              }
                              return (
                                <div className={["Td"].join(" ")} key={k1} style={ { flexBasis : `${(1/titles.length*100)}%` } }>
                                  { 
                                    name?name:"--"
                                  }
                                </div>
                              )
                            } catch(e) {
                              return (
                                <div className={["Td"].join(" ")} key={k1} style={ { flexBasis : `${(1/titles.length*100)}%` } }> -- </div>
                              )
                            }
                          } else if( v1.indexOf("status") > -1 || v1.indexOf("offer_name") > -1  ) {
                            return (
                              <div className={["Td"].join(" ")} key={k1} style={ { flexBasis : `${(1/titles.length*100)}%` } }>
                                { 
                                  (gi18n( `ph_${ v[v1].replace(/ /g, "_") }` ) !== `ph_${ v[v1].replace(/ /g, "_") }` )?
                                  gi18n( `ph_${ v[v1].replace(/ /g, "_") }` ) :
                                  v[v1]
                                }
                              </div>
                            )
                          }
                        }
                      } )
                    }
                  </div>
                )
              })
            }
            {
              history.length === 0 &&
              <div className={["Row"].join(" ")} >
                { gi18n('ph_no_record_found') }
              </div>
            }


            

        </div>

        <div className={["Th-Popup", (selected && selected['offer_detail'] && selected['offer_detail']['ordered_items'])?"Show":""  ].join(" ")}>
              <div className={["Th-Frame"].join(" ")} onClick={
                () => {
                  this.setState({selected : null})
                }
              }></div>
              <div className={["Th-View"].join(" ")}>
                { 
                  (selected && selected['offer_detail'] && selected['offer_detail']['ordered_items']) &&
                  this.renderItemString(selected)
                }
              </div>
        </div>
      </div>
    )
  }
}

export default TogoHistory;


