import React, {Component} from 'react';
import './index.scss';
import service from '../../../public/service';

const titles = [
  "expiration_time",
  "promo_ui_text_display"
]

const COUNT = 100

class Unusable extends Component {
  constructor(props) {
    super(props)
    this.state={
      history : [],
      page : 0,
      totalPage : 0,
      shops : {}
    }
    this.getUnusable = this.getUnusable.bind(this)
  }
  
  componentDidMount() {

    this.getUnusable()

  }

  componentWillUnmount() {

  }

  async getUnusable() {
    const {user, brands} = this.props
    if( service.session_key && user && ("id" in user) )  {
      let data = await service.getUnusable( user['id'] )
      this.setState({
        history : data['data']
      })
    }
  }


  render() {
    const { i18n, language } = this.props
    const { history } = this.state
    return (
      <div className={["Unusable"].join(" ")}>
        <div className={["P-Title"].join(" ")}>{ i18n["ch_coupon_history"][language] }</div>
        <div className={["Row","Title"].join(" ")} >
            {
              titles.map( (v,k) => {
                return (
                  <div className={["Td"].join(" ")} key={k} style={ { flexBasis : `${(1/titles.length*100)}%` } }>
                    { i18n[`ch_${v}`][language] }
                  </div>
                )
              } )
            }
        </div>
        <div className={["P-Holder"].join(" ")}>
            {
              history.length > 0 &&
              history.map( (v, k) => {
                return(
                  <div className={["Row"].join(" ")} key={k}>
                    {
                      titles.map( (v1,k1) => {
                        if( v1 === "expiration_time" ) {
                          return (
                            <div className={["Td"].join(" ")} key={k1} style={ { flexBasis : `${(1/titles.length*100)}%` } }>
                              { 
                                v[ v1 ]?v[ v1 ]:"--"
                              }
                            </div>
                          )
                        } else if( v1 === "promo_ui_text_display" ) {
                          const name = (v['promo'] && v['promo']["ui_text"] && v['promo']["ui_text"]['display'])?v['promo']["ui_text"]['display'][language]:v['name']
                          return (
                            <div className={["Td"].join(" ")} key={k1} style={ { flexBasis : `${(1/titles.length*100)}%` } }>
                              { 
                                name
                              }
                            </div>
                          )
                        }
                      } )
                    }
                  </div>
                )
              })
            }
            {
              history.length === 0 &&
              <div className={["Row"].join(" ")} >
                {i18n['ph_no_record_found'][language]}
              </div>
            }
        </div>
      </div>

    )
  }
}

export default Unusable;


