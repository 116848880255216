import React, {Component} from 'react';
import './index.scss';
import service from '../../../public/service';

const titles = [
  "id",
  "reservation_time",
  "phone",
  "name",
  "person_count",
  "brand",
  "shop"
]

const matches = {
  "reservation_time" : "select_date",
  "person_count" : "people"
}

class Booking extends Component {
  constructor(props) {
    super(props)
    this.state={
      history : [],
      page : 0,
      totalPage : 0,
      shops : {}
    }
    this.getBooking = this.getBooking.bind(this)
  }
  
  componentDidMount() {
    this.getBooking()

  }

  componentWillUnmount() {

  }

  async getBooking() {
    const {user, brands} = this.props
    const shops = service.rebrand( brands, true )
    if( service.session_key && user && ("id" in user) )  {
      let data = await service.getBooking( user['id'] )
      this.setState({
        history : data,
        shops : shops
      })
    }
  }


  render() {
    const { i18n, language } = this.props
    const { history, shops } = this.state
    //console.log( i18n )
    //console.log( shops )
    //console.log( history )



    return (
      <div className={["U-Booking"].join(" ")}>
        <div className={["P-Title"].join(" ")}>{ `${i18n["booking"][language].replace(/\n/g, "")} (30${i18n['day'][language]})` }</div>
        <div className={["Row","Title"].join(" ")} >
            {
              titles.map( (v,k) => {
                const name = (`${v}` in i18n)?i18n[`${v}`][language]:( (v in matches)?i18n[matches[v] ][language]:v )
                return (
                  <div className={["Td"].join(" ")} key={k} style={ { flexBasis : `${(1/titles.length*100)}%` } }>
                    { name }
                  </div>
                )
              } )
            }
        </div>
        <div className={["P-Holder"].join(" ")}>
            {
              history.length > 0 &&
              history.map( (v, k) => {
                try{
                  return(
                    <div className={["Row"].join(" ")} key={k}>
                      {
                        titles.map( (v1,k1) => {
                          if( v1 === "brand" ) {
                            const {brand} = shops[ v['shop_id'] ]
                            const { ui_text } = brand
                            var name = (ui_text && ('display' in ui_text))?ui_text['display'][language]:brand['name']
                            return (
                              <div className={["Td"].join(" ")} key={k1} style={ { flexBasis : `${(1/titles.length*100)}%` } }>
                                { 
                                  name
                                }
                              </div>
                            )
                          } else if( v1 === "shop" ) {
                            const { ui_text } = shops[ v['shop_id'] ]
                            var name = (ui_text && ('display' in ui_text))?ui_text['display'][language]:shops[ v['shop_id'] ]['name']
                            return (
                              <div className={["Td"].join(" ")} key={k1} style={ { flexBasis : `${(1/titles.length*100)}%` } }>
                                { 
                                  name
                                }
                              </div>
                            )
                          } else if( v1 === "phone" ) {
                              var name =  v[ v1 ].split( ":" )
                              return (
                                <div className={["Td"].join(" ")} key={k1} style={ { flexBasis : `${(1/titles.length*100)}%` } }>
                                  { 
                                    name[1]
                                  }
                                </div>
                              )
                          } else {
                            return (
                              <div className={["Td"].join(" ")} key={k1} style={ { flexBasis : `${(1/titles.length*100)}%` } }>
                                { 
                                  v[ v1 ]
                                }
                              </div>
                            )
                          }
                        } )
                      }
                    </div>
                  )
                } catch(e) {
                  return null
                }
              })
            }
            {
              history.length === 0 &&
              <div className={["Row"].join(" ")} >
                {i18n['ph_no_record_found'][language]}
              </div>
            }
        </div>
      </div>

    )
  }
}

export default Booking;


