import React, { Component } from 'react';
import { BrowserRouter, Route, Navigate, Routes } from 'react-router-dom'

import Lottie from "lottie-react";
import Logo from "./assets/lottie/logo.json";

import './App.scss';
import service from "./public/service"

import Header from "./Components/Header"
import Footer from "./Components/Footer"
import Cover from "./Components/Cover"
import FB from "./Components/FB"
import Social from "./Components/Social"
import Membership from "./Components/Membership"
import Terms from "./Components/Terms"
import Booking from "./Components/Booking"
import BookingPopup from "./Components/Booking/popup"

import Cart from "./Components/EShop/Cart"
import EShop from "./Components/EShop"
import Brand from "./Components/Brand"
import Shop from "./Components/Shop"
import Debug from "./Components/Debug"
import Pdf2 from "./Components/Pdf"
//import Popup from "./Components/Popup"
import Popup from "./Components/Popup2"

import Payment from "./Components/Payment"
import Webview from "./Components/Webview"

import User from "./Components/User"

import Login from './Components/Login';
//import Record from './Components/User/Purchase';
import Status from './Components/Status';
import Wallet from './Components/Wallet';
import Topup from './Components/Wallet/Topup';
import Gift from './Components/Wallet/Gift';
import Temp from "./Components/Temp"

import GiftCard from "./Components/GiftCard"

import { FaChevronCircleUp} from 'react-icons/fa';
import PrepareBuy from './Components/PrepareBuy';
import Catering from './Components/Catering';

const exclude = [
  "/giftcard",
  '/reservation/success', 
  "/reservation/fail", 
  "/reservation/confirmed", 
  "/payment", 
  "/wallet/topup",
  "/user",
  //"/catering"
  //"/shop",
]

const Forward = (props)  =>{

  const forward = () => {
    const {getOS, config} = props
    const platform = getOS()?getOS():""

    if( window.location.search ) {
      var change = false;
      if( platform in config.redirect ) {
        window.location.href = `lucky7://?${window.location.search}`
        setTimeout( () => {
          if( !change ) {
          window.location.href = config.redirect[platform]
          }
        }, 1000 )
      } else {
        window.location.href = window.location.origin
      }
      window.onblur = function () {
        change = true;
      };
      window.onfocus = function(){
          change = false;
      }

    } else {
      if( platform in config.redirect ) {
        window.location.href = config.redirect[platform]
      } else {
        window.location.href = window.location.origin
      }
    }
  }

  forward()

  return (
    null
  )
}

class App extends Component {

  constructor(props) {
    super(props)
    this.state={
      i18n : null,
      config  : null,
      content : null,
      selected : null,
      language : null,
      languages : [],
      brands : [],
      ori_brands : [],
      popups : [],
      debugMode : true,
      selectedpopup : null,

      showHeader : true,
      showFooter : true,
      showPopup : true,

      mode : "prod",
      hasBooking : false,
      hasEShop : false,

      showBooking  : false,
      shopId : null,
      showWebView : false, 
      url : null,

      user : null,
      logindata : {},
      registerdata : {"region" : "+852"},
      formdata : { "region" : "+852" },
      hasLogin : false,
      otp : "",
      showLogin : false,

      showPrepareBuy : false,
      eshopdata : {"email" : "","region" : "+852"},

      togodata : {"email" : "" ,"region" : "+852"},

      cart : [],
      showCart : false,

      menuorder : {}

    }
    this.login = this.login.bind(this)
    this.logout = this.logout.bind(this)
    this.register = this.register.bind(this)
    this.otpConfirm = this.otpConfirm.bind(this)

    this.updatevalue = this.updatevalue.bind(this)
    this.getOS = this.getOS.bind(this)
    this.getConfig = this.getConfig.bind(this) 
    this.toggleBooking = this.toggleBooking.bind(this)

    this.booking = React.createRef()
    this.bookingPopup = React.createRef()

    /*ESSHOP*/
    this.placeOrder = this.placeOrder.bind(this)
    this.updatecart = this.updatecart.bind(this)
    this.eshopPrepareOrder = this.eshopPrepareOrder.bind(this)
    this.eshopOrder = this.eshopOrder.bind(this)
    /*ESSHOP*/

    /*TO-GOER*/
    this.placeMenuOrder = this.placeMenuOrder.bind(this)
    this.updateMenuOrder = this.updateMenuOrder.bind(this)
    this.togoPrepareOrder = this.togoPrepareOrder.bind(this)
    this.togoOrder = this.togoOrder.bind(this)
    /*TO-GOER*/

    this.createPopups = this.createPopups.bind(this)

    this.updateUser = this.updateUser.bind(this)

    this.clearTogo = this.clearTogo.bind(this)

    this.gi18n = this.gi18n.bind(this)

  }

  componentDidMount() {

  
    this.getConfig() 

  
  }

  componentDidUpdate(props, state) {
    var noshow = (exclude.includes(window.location.pathname))
    if( !noshow ) {
      noshow = (window.location.pathname.indexOf("/shop") > -1 || window.location.pathname.indexOf("/userprofile") > -1)
    }

    if( (this.state.showFooter !== (!noshow) ) ) {
      this.setState({
        showFooter : !noshow
      })
    }
  }

  getOS() {
    var userAgent = window.navigator.userAgent,
        //platform = window.navigator?.userAgentData?.platform || window.navigator.platform,
        //platform = window.navigator?window.navigator.platform:?.userAgentData?.platform,
        platform = window.navigator.platform,
        macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms = ['iPhone', 'iPad', 'iPod'],
        os = null;
    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'ios';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'windows';
    } else if (/Android/.test(userAgent)) {
      os = 'android';
    } else if (/Linux/.test(platform)) {
      os = 'linux';
    }
    return os;
  }

  async getConfig() {
    var user = await service.getData("user", {}), formdata = this.state.formdata
    if( user && ("user" in user) ) {
      service.user = user.user
      service.session_key = user.session_key
      const result = await service.refreshSession()
      if( ("session_key" in result) ) {
        user = result
        service.session_key = user.session_key
        formdata['name'] = user['user']['first_name']
        formdata['email'] = user['user']['email']
      } else {
        user = {}
        service.clearAllData()
      }
    }
    
    var cart = await service.getData("cart", {})
    var menuorder = await service.getData("menuorder", {})
    //await service.storeData("menuorder", {})
    //var menuorder = {}
    try {
      if(cart && cart['timestamp']) {
        if( (new Date()).getTime() > cart['timestamp'] ) {
          cart = []
          await service.storeData("cart", [])
        } else {
          if( "cart" in cart ) {
            cart = cart['cart']
            for (var i in cart) {
              try{
                cart[i]['pickup']['date'] = new Date(cart[i]['pickup']['date'])
              } catch(e){ }
            }
          }
        }
      } else {
        cart = []
      }
    } catch(e) {
      cart = []
    }

    try {
      if(menuorder && menuorder['timestamp']) {
        if( (new Date()).getTime() > menuorder['timestamp'] ) {
          menuorder = {}
          await service.storeData("menuorder", {})
        } else {
          menuorder = menuorder['menuorder']
        }
      } else {
        menuorder = {}
      }
    } catch(e) {
      menuorder = {}
    }
    
    var popups = [], params = {}, language = ""
    var _params = window.location.search.replace("?" , "")
    _params = _params.split("&")
    for (var i in _params) {
      var _s = _params[i].split("=")
      params[ _s[0] ]= _s[1]
    }
    var noShow = false, _g = []

    let _mode = await service.getMode()
    const mode = _mode['mode']
    let content = await service.getContent()
    let brands = await service.getBrands()
    const ori_brands = service.massageAsset(brands)
    if( mode !== "dev" ) {
      brands = brands.filter( (v)=>v['brand_code'].toLowerCase().indexOf("test") === -1 )
    }
    let config = await service.getConfig2(mode)

    const i18n = config["i18n"]
    try {
      var lang = window.localStorage.getItem("@lang")
      if(lang) {
        language = lang
      } else {
        language = config.languages[0].key
      }
    } catch(e) {
      language = config.languages[0].key
    }
    
    
    if( "language" in params) {
      _g = config.languages.filter( (v)=>v.key===params['language'] )
      if( _g.length > 0 ) {
        language = _g[0].key
      } 
    }
    if(window.location.pathname.indexOf("l7_privacy") > -1 ) {
      var _lang = window.location.pathname.substring( 1,3 )
      _g = config.languages.filter( (v)=>v.key===_lang )
      if( _g.length > 0 ) {
        language = _g[0].key
      }
      noShow = true
    }

    const prior = config.prior
    var ordering = {}
    for (var i=0; i<prior.length; i++)
      ordering[prior[i]] = i;
    brands = brands.sort( (a, b) => {
      if( b.brand_code in ordering ) {
        return (ordering[a.brand_code] - ordering[b.brand_code]);
      } else {
        return -1
      }
    });

    //popups = Object.values( content ).filter( (v,k)=>v.popup===true  )
    let data =  await service.getShopItems()
    var {popups, newcontent} =  this.createPopups( data, brands, content, i18n )
    content = newcontent
    
    if( exclude.includes(window.location.pathname) ) {
      noShow = true
    }

    const noPopup = (window.location.pathname.indexOf("/catering") > -1)


    this.updatevalue({
      user : ((user && ("user" in user))?user:null), 
      hasLogin : ((user && ("user" in user))?true:false), 
      formdata : formdata,

      menuorder : menuorder,
      cart : cart,
      mode : mode,
      i18n : i18n,
      content : content,
      config : config,
      selected : content[ Object.keys( content )[0] ].key,
      languages : config.languages,
      hasBooking : config['booking'],
      hasEShop : config['eshop'],
      popups : popups,
      language : language,
      //brands : config.brands,
      brands : service.massageAsset(brands),
      ori_brands : ori_brands,
      debugMode : config.debug_mode,
      showHeader : !noShow?(params['header']?((params['header']==="0")?false:true):true):false,
      showFooter: !noShow?(params['footer']?((params['footer']==="0")?false:true):true):false,
      showPopup :  noPopup?false:(!noShow?(params['popup']?((params['popup']==="0")?false:true):true):false),
      selectedpopup : (("e" in params) && (window.location.pathname === "/popup"))?params["e"]:null
    })

  }

  updatevalue(obj, cb) {
    const { config } = this.state
    if("language" in obj) {
      var cng = obj.config?obj.config:config
      document.description = cng['general']['description'][obj.language]
      try {
        window.localStorage.setItem("@lang", obj['language'])
      } catch(e){

      }
    }
    this.setState(obj, cb)
  }

  toggleBooking(bool) {
    const { hasLogin, user } = this.state
    var obj = { showBooking : bool }
    if( !bool ) {
      obj['shopId'] = null
    }

    try {
      if(hasLogin && user && ("user" in user) ) {
        var formdata = this.state.formdata

        var {phone} = user.user
        phone = phone.split(":")

        formdata['email'] = user.user.email
        formdata['name'] = user.user.first_name

        formdata['region'] = `+${phone[0]}`
        formdata['phone'] = phone[1]

        obj['formdata'] = formdata

      }


      
    } catch(e) {

    }
    this.setState( obj )
  }

  async login() {
    const { logindata } = this.state
    const { i18n, language } = this.state
    let obj = await service.sessionGet( logindata )

    var vaild = true
    if( !("email" in logindata) || !("password" in logindata) ) {
      vaild = false
    } 
    for (var i in logindata) {
      if( logindata[i].length === 0 ) {
        vaild = false
      }
    }

    if( vaild ) {
      const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if( logindata['email'].match(validRegex)) {
      } else {
        vaild = false
      }
    }

    if(vaild) {
      try {
        const { user } = obj
        if( user ) {

          var formdata = this.state.formdata
          formdata['name'] = user['first_name']
          var _p = user['phone'].split(":")
          if( _p.length > 1 ) {
            formdata['phone'] = _p[1]
            formdata['region'] = `+${_p[0]}`
          }
          formdata['email'] = user['email']
          formdata['user_id'] = user['id']
          //this.setState({ formdata : formdata, isLogin : false, hasLogin : true, user : user })
          
          service.session_key = obj['session_key']
          service.user = obj
          service.storeData( "user", obj )

          this.setState({ formdata : formdata, hasLogin : true, user : obj, showLogin : false })


          try {
            if( this.booking && this.booking.current ) {
              this.booking.current.updatevalue({
                isLogin : false
              })
            }
            if( this.bookingPopup && this.bookingPopup.current ) {
              this.bookingPopup.current.updatevalue({
                isLogin : false
              })
            }
          } catch(e) {}

        } else {
          alert( i18n['invalid_login'][language] + "\n" +i18n['invalid_login_msg'][language] )
        }
      } catch(e){
        console.log(e)
      }
    } else {
      alert( i18n['invalid_login_msg'][language] )
    }

  }

  async register(obj, cb) {
    const {i18n, language} = this.state
    let result = await service.createUser(obj)
    if( "user_id" in result && "session_key" in result ) {
      service.otp_session_key = result['session_key']
      service.otp_user_id = result['user_id']
      let response = await service.otpSend( result['user_id'], true )
      console.log(response)
      if( response.status === 200 ) {
        cb()
      }
    } else {
      alert( i18n['invalid_register'][language] )
    }
  }

  async otpConfirm(otp, cb) {
    const {i18n, language} = this.state
    if(  service.otp_user_id ) {
      let result = await service.userRegister(service.otp_user_id, otp)
      if( result.status === 200 ) {
        alert( i18n['et_registration'][language] )
        cb()
      }
    }

  }

  logout() {
    service.clearAllData()
    const {i18n, language} = this.state
    var {eshopdata, formdata, togodata} = this.state
    if("name" in formdata) {
      delete formdata['name']
    }
    if("email" in formdata) {
      delete formdata['email']
    }
    if("phone" in formdata) {
      delete formdata['phone']
    }
    if ("user_id" in eshopdata) {
      delete eshopdata['user_id']
    }
    if ("user_id" in togodata) {
      delete togodata['user_id']
    }
    alert( i18n['logout_message'][language] )
    this.setState({
      user : null,
      hasLogin : false,
      showLogin : false,
      formdata : formdata,
      eshopdata : eshopdata,
      cart : []
    }, ()=>{
      window.location.href = window.location.origin
    })
  }

  async updateUser() {
    var {user} = this.state
    let data = await service.getUser( user['user']['id'] )
    user['user'] = data
    service.user = user
    this.setState({
      user : user
    })
  }

  /*ESHOP*/
  async placeOrder(obj) {
    var {cart} = this.state
    const {i18n, language} = this.state
    if( obj['item']['item_type'] === "coupon" ) {
      var _cart = cart.filter( (v) => v['item']['promo_id'] === obj['item']['promo_id'] )
      if( _cart.length === 0 ) {
        cart.push( obj )
      } else {
        alert( i18n['es_item_has_been_placed'][language] )
        return false
      }
    } else {
      cart.push( obj )
    }


    this.setState({
      cart : cart
    }, ()=>{
      //30 MINUTES EXPIRATION
      var ts = (new Date()).getTime() + 30 * 60 * 1000
      var o = { "cart" : cart , "timestamp" : ts }
      service.storeData("cart", o)
    })
    return true
  }

  async updatecart(cart, cb) {
    this.setState({ cart : cart }, async ()=>{
      var ts = (new Date()).getTime() + 30 * 60 * 1000
      var o = { "cart" : cart , "timestamp" : ts }
      await service.storeData("cart", o)
      if(cb) {
        cb()
      }
    })
  }

  eshopPrepareOrder() {
    const {cart, hasLogin, i18n, language, user} = this.state
    var {eshopdata} = this.state
    
    var valid = true

    for (var item of cart) {

      if( item['item']['item_type'] !== "coupon"  ) {
        if( !item['pickup']['date'] || (item['pickup']['shop'] === "") ) {
          valid = false
        }
        if( item['item']['tier_pricing'] && !hasLogin ) {
          valid = false
        }
      } else {
        if(  !hasLogin ) {
          valid = false
          var a = window.confirm( i18n["required_login"][language] )
          if(a) {
            this.setState({
              showLogin : true
            })
          }
          return
        }
      }

    }
       
    if( valid ) {
      if( hasLogin ) {
        eshopdata['user_id'] = user['user']['id']
        this.setState({
          eshopdata : eshopdata
        }, ()=>{
          this.eshopOrder()
        })
      } else {

        var a = window.confirm( i18n["es_is_member"][language] )

        if(a) {
          this.setState({
            showLogin : true
          })
        } else {        
          this.setState({
            showPrepareBuy : true
          })
        }


      }
    } else {
      alert(i18n['es_placed_error'][language])
    }
  }

  async eshopOrder() {
    const {cart, eshopdata, i18n, language, hasLogin, user, mode} = this.state

    var orders = []

    if( cart.length === 0 ) {
      alert(i18n['es_empty_cart'][language])
      return
    }

    if( !("user_id" in eshopdata) ) {
      if( !service.validateEmail(eshopdata['email']) ) {
        alert(i18n["es_invalid_email"][language])
        return 
      }
    }

    for (var o of cart) {
      const {item, pickup, options, add_amount} = o
      for ( var i = 0; i < o['quantity']; i++ ){

        var price_display = 0

        if( item['tier_pricing'] ) {
          if(hasLogin) {
            const gp = item['tier_pricing'].filter( (v) => v['tier_level'] === user['user']['tier_level'] )

            if( gp.length > 0 ) {
              price_display = (gp[0]['price'] > -1)?gp[0]['price']:item['price']
            } else {
              price_display = item['price']
            }
          } else {
            price_display = item['price']
          }
        } else {
          price_display = item['price']
        }

        var obj = {
          detail : {'option_groups': Object.values( options ) },
          item_id : item['id'],
          item_code : item['item_code'],
          name : item['name'],
          //pickup_shop_code : pickup['shop'],
          //scheduled_pickup_date	: service.formatDate(  pickup['date'], false ),
          total	: price_display+(add_amount),
          //item_type : item['item_type']
        }

        if(item['item_type']) {
          obj["item_type"] = item['item_type']
          obj["promo_id"] = item["promo_id"]
        }

        if(pickup['shop'] !== "") {
          obj["pickup_shop_code"] = pickup['shop']
          obj["scheduled_pickup_date"] = service.formatDate(  pickup['date'], false )
        } else {
         // obj["scheduled_pickup_date"] = service.formatDate(  new Date(), false )
        }

        if( item['tier_pricing'] ) {
          if ( hasLogin ) {
            orders.push(obj)
          }
        } else {
          orders.push(obj)
        }
      } 
    }

    var _orderObj = {}
    if( "user_id" in eshopdata) {
      _orderObj['user_id'] = eshopdata['user_id']
    } else {
      _orderObj['email'] = eshopdata['email']
    }

    _orderObj['purchase_items'] = orders

    let data = await service.purchaseCreateEshop( _orderObj )

    if("msg" in data) {
      if (data['msg'].indexOf('promo is not applicable') > -1 ) {
        alert(i18n['es_coupon_item_has_purchased'][language])
        return
      }
    }


    if( data['status'] === "pending" ) {
      this.setState({
        eshopdata : {"email" : ""},
        cart : []
      },async ()=>{
        await service.storeData("cart", [])
        const {id} = data
        const url = `${window.location.origin}/payment?purchase_id=${id}&language=${language}&mode=${ (mode=="dev")?"dev":"default" }`
        window.location.href = url
      })
      
    } else {
      alert(i18n['es_fail_purchase'][language])
    }

  }
  /*ESHOP*/

  /*TO-GOER*/
  async placeMenuOrder(obj, shop_code, cb) {
    var {menuorder} = this.state
    const {i18n, language} = this.state

    if( !(shop_code in menuorder) ) {
      menuorder[shop_code] = []
    }

    menuorder[shop_code].push( obj )

    this.setState({
      menuorder : menuorder
    }, ()=>{
      //30 MINUTES EXPIRATION
      var ts = (new Date()).getTime() + 10 * 60 * 1000
      var o = { "menuorder" : menuorder , "timestamp" : ts }
      service.storeData("menuorder", o)
      if(cb) {
        cb()
      }
    })
    return true
  }

  async updateMenuOrder(menuorder, cb) {
    this.setState({ menuorder : menuorder }, async ()=>{
      var ts = (new Date()).getTime() + 30 * 60 * 1000
      var o = { "menuorder" : menuorder , "timestamp" : ts }
      await service.storeData("menuorder", o)
      if(cb) {
        cb()
      }
    })
  }

  togoPrepareOrder() {
    const {menuorder, hasLogin, i18n, language, user} = this.state
    var {togodata} = this.state
    
    var valid = true

    for (var shopcode in menuorder) {
      for ( var item of menuorder[shopcode] ) {
        if( item['item']['tier_pricing'] && !hasLogin ) {
          valid = false
        }
      }
    }
       
    if( valid ) {
      if( true === false ) {
      //if( hasLogin ) {
        togodata['user_id'] = user['user']['id']
        this.setState({
          togodata : togodata
        }, ()=>{
          this.togoOrder()
        })
      } else {

        //var a = window.confirm( i18n["es_is_member"][language] )
        var a = false
        if(a) {
          this.setState({
            showLogin : true
          })
        } else {        
          this.setState({
            showPrepareBuy : true
          })
        }
      }
    } else {
      alert(i18n['es_placed_error'][language])
    }
  }

  async togoOrder() {

    const {menuorder, togodata, i18n, language, hasLogin, user, mode} = this.state

    const params = service.getParamQuery()

    var orders = []
    
    if( Object.keys(menuorder).length === 0 ) {
      alert(i18n['es_empty_cart'][language])
      return
    } 

    if( !("user_id" in togodata) ) {
      if( !service.validateEmail(togodata['email']) ) {
        alert(i18n["es_invalid_email"][language])
        return 
      }
    }



    var total = 0
    for (var shopcode in menuorder) {
      for ( var item of menuorder[shopcode] ) {
        total += ((item['add_amount'] + item['item']['price'] ) * item['quantity'])
        for ( var i = 0; i < item['quantity'] ; i++ ) {
          var o = {}
          o['shop_code'] = shopcode
          o['name'] = item['item']['name']
          o['item_code'] = item['item']['item_code']
          o['price'] = item['item']['price']
          o['total'] = item['item']['price'] + item['add_amount']
          o['ordered_groups'] = []
          o['option_groups'] = []
          
          //SELECIONS
          /*
          if(  Object.keys(item['picked_selections']).length > 0 ) {
            const {selection_groups} = item['item']
            var ordered_groups = []
            for ( var idx in item['picked_selections']) {
              const ob = {}
              const sg = selection_groups[idx]
              const {id} = sg
              ob['id'] = id
              ob['ordered_items'] = []
              for (var s of item['picked_selections'][idx]['selections']  ) {
                const obj = {}
                const {options, add_amount} = s
                const { shop_code, name, item_code,  option_groups } = s['menuitem']
                obj['shop_code'] = shop_code
                obj['name'] = name
                obj['item_code'] = item_code
                obj['price'] = add_amount
                var appendix = 0
                if( option_groups ) {
                  obj['option_groups'] = []
                  for (var g of option_groups) {

                    var _o = {}
                    _o['id'] = g['id']
                    _o['select_min'] = g['select_min']
                    _o['select_max'] = g['select_max']
                    if( options ) {
                      if( g['id'] in options ) {
                        _o['options'] = options[g['id']]
                        for( var _s of options[g['id']] ) {
                          appendix += _s['add_amount']
                        }
                      } else {
                        _o['options'] = []
                      }
                    } else {
                      _o['options'] = []
                    }
                    obj['option_groups'].push( _o )
                    //  }
                    //}
                  }
                } 
                obj['total'] = (add_amount + appendix)
                ob['ordered_items'].push( obj )
              }

              if( ob['ordered_items'].length === 0 ) {
                ob['ordered_items'] = null
              }

              ordered_groups.push( ob )
            } 
            o['ordered_groups'] = ordered_groups
          }
          */

          const {selection_groups} = item['item']
          var ordered_groups = []
          if(  selection_groups ) {
            for ( var idx in selection_groups) {
              const ob = {}
              const sg = selection_groups[idx]
              const {id} = sg
              ob['id'] = id
              ob['ordered_items'] = []
              if(  idx in item['picked_selections']  ) {
                for (var s of item['picked_selections'][idx]['selections']  ) {
                  const obj = {}
                  const {options, add_amount} = s
                  const { shop_code, name, item_code,  option_groups } = s['menuitem']
                  obj['shop_code'] = shop_code
                  obj['name'] = name
                  obj['item_code'] = item_code
                  obj['price'] = add_amount
                  var appendix = 0
                  if( option_groups ) {
                    obj['option_groups'] = []
                    for (var g of option_groups) {

                      var _o = {}
                      _o['id'] = g['id']
                      _o['select_min'] = g['select_min']
                      _o['select_max'] = g['select_max']
                      if( options ) {
                        if( g['id'] in options ) {
                          _o['options'] = options[g['id']]
                          for( var _s of options[g['id']] ) {
                            appendix += _s['add_amount']
                          }
                        } else {
                          _o['options'] = []
                        }
                      } else {
                        _o['options'] = []
                      }
                      obj['option_groups'].push( _o )
                      //  }
                      //}
                    }
                  } 
                  obj['total'] = (add_amount + appendix)
                  ob['ordered_items'].push( obj )
                }
              }
              ordered_groups.push( ob )
            }
          } else {
            ordered_groups = null
          } 
          o['ordered_groups'] = ordered_groups


          //OPTIONS
          /*
          if(  Object.keys(item['picked_options']).length > 0 ) {
            for (var id in item['picked_options']) {
              var _o = {}
              _o['id'] = id
              _o['select_min'] = item['picked_options'][id]['select_min']
              _o['select_max'] = item['picked_options'][id]['select_max']
              _o['options'] = item['picked_options'][id]['options']
              o['option_groups'].push( _o )
            }
          }
          */
         if(item['item']['option_groups'])  {
          for( var i of item['item']['option_groups'] ) {
            var _o = {}
            _o['id'] = i['id']
            _o['select_min'] = i['select_min']
            _o['select_max'] = i['select_max']
            if( i['id'] in item['picked_options'] ) {
              _o['options'] = item['picked_options'][ i['id'] ]['options']
            } else {
              _o['options'] = []
            }
            o['option_groups'].push( _o )
          }
         } else {
          o['option_groups'] = null
         }



          orders.push( o )
        }

        
      }
    }

    var orderObj = {}

    var destination = ""
    if( params['d'] ) {
      if ( params['t'] ) {
        destination = `${params['d']}_${params['t']}`
      } else {
        destination = `${params['d']}`
      }
    }

    var des = []
    des.push( destination )
    des.push( togodata['phone'] )

    //orderObj['destination'] = destination
    orderObj['destination'] = des.join("|")


    if("user_id" in togodata) {
      orderObj['user_id'] = togodata['user_id']
    } else {
      orderObj['email'] = togodata['email']
    }
    orderObj['ordered_items'] = orders
    orderObj["total"] = total

    //console.log( orderObj )
    //return

    let data = await service.purchaseCreateTogo( orderObj )

    if("msg" in data) {
      if (data['msg'].indexOf('promo is not applicable') > -1 ) {
        alert(i18n['es_coupon_item_has_purchased'][language])
        return
      }
    }


    if( data['status'] === "pending" ) {
      this.setState({
        togodata : {"email" : ""},
        menuorder : {}
      },async ()=>{
        await service.storeData("menuorder", {})
        const {id} = data
        console.log()
        const url = `${window.location.origin}/payment?purchase_id=${id}&language=${language}&mode=${ (mode=="dev")?"dev":"default" }`
        //setTimeout( () => {
        window.location.href = url
        //}, 1000 )
        
      })
      
    } else {
      alert(i18n['es_fail_purchase'][language])
    }


  }

  clearTogo() {
    this.setState({
      menuorder : {}
    },
      async () => {
        await service.storeData("menuorder", {})
      }
    )
  }
  /*TO-GOER*/

  createPopups(data, brands, content, i18n) {

    const news = data['list'].filter( (v)=>((v['order']>=1000)&&(![1001, 2001, 2002].includes(v['order']))) )
    var popups = []

    for (var i of news) {
      try {
        const { ui_text, asset } = service.massageAsset2( i )
        var obj = {
          "page" : false,
          "header" : false,
          "footer" : null,
          "template" : null,
          "component" : "popup",
          "link" : null,
          "shortcut" : false,
          "popup" : true,
        }

        const _line = (_ct) => {
          var _langs = Object.keys( ui_text['desc'] )
          var _o = {}
          for (var l of _langs) {
            _o[ l ] = ""
          }
          return {
            "id" : _ct, 
            "type" : "line",
            "message" : _o
          }
        }
        if( ui_text['display'] && ui_text['desc']) {
          obj['key'] = `p${i['id']}`
          obj['display'] = ui_text['display']
          obj['stores'] = ui_text['shopcode']?true:false
          obj['address'] = ui_text['shopcode']?true:false
          obj['tel'] = ui_text['tel']?true:false
          obj['whatsapp'] = ui_text['whatsapp']?true:false
          obj['image'] = `${service.asset}/${asset[ ui_text['image']['value'] ]['file_path']}`
          obj["messages"] = []
          var _ct = 1
          obj["messages"].push(
            {
              "id" : _ct,
              "type" : "titled",
              "message" : ui_text['display']
            }
          )
          _ct += 1
          if( ui_text['video'] ) {

            /*const decode = (str)=> {
              var _sp = str.split(" ")
              var a = _sp.filter((v)=>v.indexOf('src=')>-1)
              if (a.length > 0) {
                a = a[0].replace(/"/g, "" ).replace("src=", "").replace(/ /g, "")
                return a
              } else {
                return null
              }
            }*/
            try {
              obj["messages"].push(
                {
                  "id" : _ct,
                  "type" : "video",
                  "link" : ui_text['video']['value']
                }
              ) 
              _ct += 1
            } catch(e){

            }
          }

          obj["messages"].push(
            {
              "id" : _ct, 
              "type" : "message1",
              "message" : ui_text['desc']
            }
          )
          _ct += 1
          if( ui_text['hours'] ) {
            var _langs = Object.keys( ui_text['desc'] )
            var _o = {}
            for (var l of _langs) {
              _o[ l ] = ""
            }
            obj["messages"].push( _line( _ct ) )
            _ct += 1
            obj["messages"].push(
              {
                "id" : _ct, 
                "type" : "message",
                "message" : ui_text['hours']
              }
            )
            _ct += 1
          }

          if(ui_text['whatsapp']) {
            obj["messages"].push( _line( _ct ) )
            _ct += 1
            //console.log(  )
            obj["messages"].push(
              {
                "id" : _ct, 
                "type" : "whatsapp",
                "link" : ui_text['whatsapp']['value'],
                "message" : { 
                    "en" : "WhatsApp appointment",
                    "hk" : "WhatsApp 預約",
                    "zh" : "WhatsApp 预约",
                    "jp" : "WhatsAppでの予約",
                    "th" : ""
                }
              } 
            )
            _ct += 1
            //obj["messages"].push( _line( _ct ) )
            //_ct += 1
          }

          const _get = (s, brands) => {
            var obj = {}
            for (var b of brands) {
              if( b['enabled']) {
                for (var _s of b['shop']) {
                  if( _s['enabled']) {
                    if( s.includes(_s['shop_code']) ) {
                      if( !(b['brand_code'] in obj) ) {
                        obj[b['brand_code']] = []
                      }
                      obj[b['brand_code']].push( _s['shop_code'] )
                    }
                  }
                }
              }
            }
            return obj
          }

          
          if( ui_text['shopcode'] ) {
            const _s = ui_text['shopcode']['value'].split(",")
            obj["store_list"]  =  [
              {
                  "id" : _ct,
                  "title" : i18n['pp_shop_code'],
                  "stores" : _get(_s, brands)
              }
            ]
            _ct += 1
          }
          
          if( ui_text['redemption_shopcode'] ) {
            const _rs = ui_text['redemption_shopcode']['value'].split(",")
            obj["redemption_store_list"]  =  [
              {
                  "id" : _ct += 1,
                  "title" : i18n['pp_redemption_shop_code'],
                  "stores" : _get(_rs, brands)
              }
            ]
            _ct += 1
          }

          if( ui_text['gallery'] ) {
            const _images = ui_text['gallery']['value'].split(",")
            var images = []
            for (var i of _images) {
              try {
                images.push(`${service.asset}/${asset[ i ]['file_path']}`)
              } catch(e){}
            }

            obj["messages"].push(
              {
                "id" : _ct, 
                "type" : "gallery",
                "images" : images
              } 
            )
            _ct += 1
          }

          popups.push( obj )

          
          content[ obj["key"] ] = obj

        }
      } catch(e) {
        console.log(e)
      }

    }

    return {  
      "popups" : popups,
      "newcontent" : content
    }
     
    

    /*
    "poonchoi5" :  {
        !"key" : "poonchoi5",
        !"display" : {"en" : "Braised Abalone and Fish Maw Delight", "hk" : "鮑魚花膠福滿盤套餐", "zh" : "鲍鱼花胶福满盘套餐", "jp" : "鮑魚花膠福滿盤套餐" },
        !"page" : false,
        !"header" : false,
        !"footer" : null,
        !"template" : null,
        !"component" : "popup",
        !"link" : null,
        !"shortcut" : false,
        !"popup" : true,
        !"stores" : true,
        !"image" : "ricecake.jpg",
        !"address" : true,
        !"tel" : false,
        !"whatsapp" : true,
        "messages" : [
                {
                    "id" : 1, 
                    "type" : "titleu",
                    "message" : { 
                        "en" : "2024",
                        "hk" : "2024年",
                        "zh" : "2024年", 
                        "jp" : "2024年"
                    }
                },
                {
                    "id" : 2, 
                    "type" : "titled",
                    "message" : { 
                        "en" : "Braised Abalone and Fish Maw Delight",
                        "hk" : "鮑魚花膠福滿盤套餐",
                        "zh" : "鲍鱼花胶福满盘套餐",
                        "jp" : "鮑魚花膠福滿盤套餐"
                    }
                },
                {
                    "id" : 3, 
                    "type" : "message1",
                    "message" : { 
                        "en" : "Lucky 7 Chinese New Year Braised Abalone and Fish Maw Delight are now available for pre-order.",
                        "hk" : "Lucky 7 賀年鮑魚花膠福滿盤套餐 現正接受預訂",
                        "zh" : "Lucky 7 贺年鲍鱼花胶福满盘套餐 现正接受预订",
                        "jp" : "Lucky 7 賀年鮑魚花膠福滿盤套餐 現正受け付けております" 
                    }
                },
                {
                    "id" : 31, 
                    "type" : "message1",
                    "message" : { 
                        "en" : "We are delighted to present our meticulously crafted Braised Abalone and Fish Maw Delight set, offering a symphony of flavors at an irresistible price !",
                        "hk" : "新春佳節盤滿缽滿，以一個價錢享受豐盛四重美味！",
                        "zh" : "新春佳节盘满钵满，以一个价钱享受丰盛四重美味！",
                        "jp" : "新春のお祝いには、満腹のお皿をお楽しみください！1つの価格で4つの豪華な味をお楽しみいただけます！" 
                    }
                },
                {
                    "id" : 4, 
                    "type" : "message1",
                    "message" : { 
                        "en" : "The Braised Abalone and Fish Maw Delight includes:",
                        "hk" : "鮑魚花膠福滿盤套餐包括：",
                        "zh" : "鲍鱼花胶福满盘套餐包括：",
                        "jp" : "鮑魚花膠福滿盤套餐には、以下が含まれています：" 
                    }
                },
                {
                    "id" : 5, 
                    "type" : "message1",
                    "message" : { 
                        "en" : "A generous serving of braised abalone and fish maw",
                        "hk" : "鮑魚花膠福滿盤",
                        "zh" : "鲍鱼花胶福满盘",
                        "jp" : "鮑魚花膠福滿盤" 
                    }
                },
                {
                    "id" : 6, 
                    "type" : "message1",
                    "message" : { 
                        "en" : "A delectable assortment of four pastry delights (Coconut Sticky Rice Cake | Dried Scallop and Radish Cake | Waxed Fragrant Yam Cake | Rose and White Peach Cake)",
                        "hk" : "主廚炮製賀年四式糕點(椰汁年糕｜瑤柱蘿蔔糕｜臘香芋頭糕｜玫瑰白桃糕)",
                        "zh" : "主厨炮制贺年四式糕点(椰汁年糕｜瑶柱萝卜糕｜腊香芋头糕｜玫瑰白桃糕)",
                        "jp" : "シェフ特製のお正月の四式糕点（椰子年糕｜瑤柱蘿蔔糕｜臘香芋頭糕｜バラと白桃の糕）" 
                    }
                },
                {
                    "id" : 7, 
                    "type" : "message1",
                    "message" : { 
                        "en" : "A bottle of handmade XO sauce inspired by the culinary traditions of King's Delicacy",
                        "hk" : "京滬佳餚純手工製XO醬",
                        "zh" : "京沪佳肴纯手工制XO酱",
                        "jp" : "京滬佳餚の手作りXO醤" 
                    }
                },
                {
                    "id" : 8, 
                    "type" : "message1",
                    "message" : { 
                        "en" : "A complimentary coupon for our signature Hong Kong barbecued meats from Hong Kong BBQ",
                        "hk" : "港燒味太空叉燒1斤優惠券",
                        "zh" : "港烧味太空叉烧1斤优惠券",
                        "jp" : "香港焼肉のチャーシュー1斤割引クーポン" 
                    }
                },
                {
                    "id" : 9, 
                    "type" : "line",
                    "message" : { 
                        "en" : "",
                        "hk" : "",
                        "zh" : "",
                        "jp" : "" 
                    }
                },
                {
                    "id" : 10, 
                    "type" : "message",
                    "message" : { 
                        "en" : "Redemption period: January 20 to February 15, 2024",
                        "hk" : "換領日期：2024年1月20日至2月15日",
                        "zh" : "换领日期：2024年1月20日至2月15日",
                        "jp" : "換領日期：2024年1月20日至2月15日" 
                    }
                },
                {
                    "id" : 11, 
                    "type" : "message",
                    "message" : { 
                        "en" : "Hotline service hours : 9:00 am to 6:00 pm",
                        "hk" : "熱線服務時間 : 上午九時至下午六時",
                        "zh" : "热线服务时间 : 上午九时至下午六时",
                        "jp" : "ホットラインのサービス時間: 午前9時から午後6時" 
                    }
                },
                {
                    "id" : 12, 
                    "type" : "appendix",
                    "message" : { 
                        "en" : "(Monday to Friday)",
                        "hk" : "(星期一至五)",
                        "zh" : "(星期一至五)",
                        "jp" : "(月曜日から金曜日まで)" 
                    }
                },
                {
                    "id" : 13, 
                    "type" : "appendix",
                    "message" : { 
                        "en" : "Please make a reservation via WhatsApp hotline at 5487 6659 one day prior to collection",
                        "hk" : "請於換領前一天 WhatsApp 熱線 5487 6659 預約",
                        "zh" : "请于换领前一天 WhatsApp 热线 5487 6659 预约",
                        "jp" : "難領前日に WhatsAppホットライン5487 6659で予約をお願いします" 
                    }
                },
                {
                    "id" : 14, 
                    "type" : "appendix",
                    "message" : { 
                        "en" : "Or make an appointment by pressing the WhatsApp button below",
                        "hk" : "或按以下 WhatsApp 按鈕預約",
                        "zh" : "或按以下 WhatsApp 按钮预约",
                        "jp" : "以下のWhatsAppボタンをクリックして予約してください" 
                    }
                },
                {
                    "id" : 15, 
                    "type" : "whatsapp",
                    "link" : "https://wa.me/85254876659",
                    "message" : { 
                        "en" : "WhatsApp appointment",
                        "hk" : "WhatsApp 預約",
                        "zh" : "WhatsApp 预约",
                        "jp" : "WhatsAppでの予約" 
                    }
                }        
        ],
        "store_list" : [
            {
                "id" : 0,
                "title" : { 
                        "en" : "Purchaing Branch",
                        "hk" : "發售分店",
                        "zh" : "发售分店",
                        "jp" : "販売場所" 
                },
                "stores" : {
                    "KD" : ["KD01", "KD02", "KD03", "KD04" ],
                    "GT" : ["GT01"],
                    "YK" : ["YK01"]
                }
            }
        ],
        "redemption_store_list" : [
            {
                "id" : 0,
                "title" : { 
                        "en" : "Redemption Branch",
                        "hk" : "換領分店",
                        "zh" : "换领分店",
                        "jp" : "換領場所" 
                },
                "stores" : {
                    "KD" : ["KD01", "KD02", "KD03", "KD04" ],
                    "YK" : ["YK01"]
                }
            }
        ]
    }
    */
  }


  gi18n( key ) {
    const {i18n, language} = this.state
    return (key in i18n)?i18n[key][language]:key
  }

  render() {
    const {cart, showCart, config, i18n, language, languages, debugMode, brands, menuorder} = this.state
    const isToGo = (window.location.pathname.indexOf("/catering") > -1)

    if( config ) {
      if( !debugMode ) {
        return(
          <div className={['App'].join(" ")} onContextMenu={ (e)=>{
            if(this.state.mode !== "dev") {
              e.preventDefault()
            }
          } }>
          
            <BrowserRouter>
              {
                (this.state.showHeader) &&
                <Header 
                  showLogin={this.state.showLogin}
                  user={this.state.user}
                  hasLogin={this.state.hasLogin}
                  hasBooking={ this.state.hasBooking }
                  hasEShop={ this.state.hasEShop }
                  showBooking={ this.state.showBooking }
                  toggleBooking={ this.toggleBooking }
                  i18n={i18n}
                  languages={ languages }
                  language={ language }
                  selected={  this.state.selected  }
                  content={this.state.content} 
                  config={config}
                  brands={brands}
                  updatevalue={this.updatevalue}
                  cart={this.state.cart}
                />
              }
              <Routes>
                <Route exact path={ "/" } element={ 
                    <Cover 
                      page={"home"}
                      i18n={i18n}
                      languages={ languages }
                      language={ language }
                      selected={this.state.selected}
                      content={this.state.content} 
                      config={config}
                      brands={brands}
                      updatevalue={this.updatevalue}
                    />
                }/>
                {
                  this.state.content && 
                  Object.keys( this.state.content ).map( (v, k)=> {
                    if( (this.state.content[v].page===false) && (this.state.content[v].popup!==true)  ) {
                      var Comp = Temp
                      if( this.state.content[v].component  === "cover" ) {
                        Comp = Cover
                      } else if (  this.state.content[v].component === "FB" ) {
                        Comp = FB
                      } else if( this.state.content[v].component  === "membership" ) {
                        Comp = Membership
                      } else if( this.state.content[v].component  === "terms" ) {
                        Comp = Terms
                      } else if( this.state.content[v].component  === "social" ) {
                        Comp = Social
                      } else if( this.state.content[v].component  === "pdf" ) {
                        Comp = Pdf2
                      }
                      var attr = this.state.content[v].language?"/:lang":""
                      return (
                        <Route exact path={ attr + "/" + v } element={ 
                          <Comp 
                            page={v}
                            i18n={i18n}
                            languages={ languages }
                            language={ language }
                            selected={this.state.selected}
                            content={this.state.content} 
                            config={config}
                            brands={brands}
                            updatevalue={this.updatevalue}
                            hasBooking={this.state.hasBooking}
                          />
                        } key={"C_"+k} 
                        />
                      )
                    } else {
                      if( !this.state.content[v].link ) {
                        return <Route exact path={ "/"+this.state.content[v].page+"#" + v } element={ <Navigate to={"/#" + v} /> } key={"C_"+k}  />
                      }
                    }
                  } )
                  
                }

                {
                  config && 
                  brands.map( (v,k) => {
                    if( v.enabled ) {
                      return (
                        v.shop.map( (val, key) => {
                          if( val.enabled) {
                              return (
                                  <Route 
                                    path={ "/brand/" + v['brand_code'].toLowerCase() + "/shop/" + val['shop_code'].toLowerCase() } 
                                    element={ 
                                      <Shop 
                                        page={ "brand" }
                                        updatevalue={this.updatevalue}
                                        parent={v}
                                        body={val}
                                        i18n={i18n}
                                        languages={ languages }
                                        language={ language }
                                        selected={this.state.selected}
                                        content={this.state.content} 
                                        config={config}
                                        brands={brands}
                                        hasBooking={this.state.hasBooking}
                                      />
                                    } key={k+"_"+key} 
                                  />
                              )
                          } else {
                            return null
                          }
                        } )
                        
                      )
                    } else {
                      return null
                    }
                  } )
                }


                { 
                  config && 
                  brands.map( (v,k) => {
                    if( v.enabled ) {
                      return (
                        <Route exact path={ "/brand/" + v['brand_code'].toLowerCase() } element={ 
                          <Brand 
                            hasBooking={this.state.hasBooking}
                            updatevalue={this.updatevalue}
                            page={ "brand" }
                            body={v}
                            i18n={i18n}
                            languages={ languages }
                            language={ language }
                            selected={this.state.selected}
                            content={this.state.content} 
                            config={config}
                            brands={brands}
                          />
                        } key={"B_"+k} 
                        />
                      )
                    } else {
                      return null
                    }
                  } )
                }
                
                {
                  (this.state.popups.length > 0) &&
                  <Route exact path={ "/popup" } element={ 
                    <Cover 
                      page={this.state.content['home']}
                      i18n={i18n}
                      languages={ languages }
                      language={ language }
                      selected={"home"}
                      content={this.state.content} 
                      config={config}
                      brands={brands}
                      updatevalue={this.updatevalue}
                    />
                  } />
                }
                
                <Route path="/reservation/success" element={ 
                    <Status 
                      title={ i18n["transaction_success"][language] } 
                      msg={ i18n['transaction_check_email'][language] }
                    /> 
                } />
                <Route path="/reservation/fail" element={ 
                    <Status 
                      title={ i18n["transaction_failed"][language] } 
                    /> 
                } />
                <Route path="/reservation/confirmed" element={ 
                    <Status 
                      title={ i18n["transaction_confirmed"][language] } 
                      msg={ i18n['thank_you'][language] }
                    /> 
                } />

                <Route exact path="/booking" element={<Booking brands={brands} config={config} i18n={i18n} language={language} updatevalue={this.updatevalue} login={this.login} logindata={this.state.logindata} formdata={this.state.formdata} hasLogin={this.state.hasLogin} ref={this.booking}/> } />
                <Route exact path="/booking/:shopid" element={<Booking brands={brands} config={config} i18n={i18n} language={language} updatevalue={this.updatevalue} login={this.login} logindata={this.state.logindata} formdata={this.state.formdata} hasLogin={this.state.hasLogin} ref={this.booking}/> } />
                <Route exact path="/payment" element={<Payment config={config} i18n={i18n} language={language} updatevalue={this.updatevalue} mode={this.state.mode}/> } />

                <Route exact path="/shop" element={<EShop brands={brands} config={config} i18n={i18n} language={language} updatevalue={this.updatevalue} cart={this.state.cart} placeOrder={this.placeOrder} hasLogin={this.state.hasLogin} user={this.state.user} /> } />
                <Route exact path="/shop/:itemid" element={<EShop brands={brands} config={config} i18n={i18n} language={language} updatevalue={this.updatevalue} cart={this.state.cart} placeOrder={this.placeOrder} hasLogin={this.state.hasLogin} user={this.state.user}/> } />

                <Route exact path="/userprofile" element={ <User i18n={i18n} language={language} brands={this.state.ori_brands} user={this.state.user} hasLogin={this.state.hasLogin} config={config} gi18n={this.gi18n}/> }/>
                <Route exact path="/user" element={ <User i18n={i18n} language={language} brands={this.state.ori_brands} user={this.state.user} hasLogin={this.state.hasLogin} config={config} gi18n={this.gi18n}/> }/>
                
                { 
                  config['wallet'] &&
                  <Route exact path="/wallet" element={ <Wallet i18n={i18n} language={language} user={this.state.user} hasLogin={this.state.hasLogin} config={config} showHeader={this.state.showHeader} updatevalue={this.updatevalue} updateUser={this.updateUser}/> } />
                }
                { 
                  config['wallet'] && config['topup'] &&
                  <Route exact path="/wallet/topup" element={ <Topup i18n={i18n} language={language} updatevalue={this.updatevalue} /> }/>
                }
                { 
                  config['wallet'] && config['topup'] &&
                  <Route exact path="/wallet/gift" element={ <Gift i18n={i18n} language={language} user={this.state.user} hasLogin={this.state.hasLogin} config={config} updatevalue={this.updatevalue} showHeader={this.state.showHeader} updateUser={this.updateUser} /> }/>
                }      


                <Route exact path="/giftcard" element={ <GiftCard i18n={i18n} language={language} user={this.state.user} config={config} /> }/>



                <Route exact path="/catering" element={<Catering brands={brands} config={config} i18n={i18n} language={language} updatevalue={this.updatevalue} hasLogin={this.state.hasLogin} user={this.state.user} menuorder={menuorder} placeMenuOrder={this.placeMenuOrder} updateMenuOrder={this.updateMenuOrder} togoOrder={this.togoPrepareOrder} clearTogo={this.clearTogo}/> } />


                <Route path="/forward" element={ <Forward getOS={this.getOS} config={config} /> } />
                <Route path="/home" element={ <Navigate to="/" /> }/>



                <Route path="/*" element={ <Navigate to="/" /> }/>
              </Routes>
              
              {
                !exclude.includes(window.location.pathname) && 
                !['/catering'].includes(window.location.pathname) &&
                <FaChevronCircleUp 
                  onClick={()=> window.scrollTo(
                    {top: 0,
                    left: 0,
                    behavior: "smooth",
                  }) }
                  className={['Shoutcut'].join(" ")} />
              }

              {
                (this.state.popups.length > 0) && (this.state.showPopup) &&
                <Popup 
                  brands={brands}
                  display={ true }
                  selectedpopup={ this.state.selectedpopup }
                  config={config}
                  selected={this.state.selected}
                  content={this.state.content}
                  popups={this.state.popups}
                  i18n={i18n} 
                  language={language}/>
              }


              <BookingPopup
                login={this.login}
                toggleBooking={ this.toggleBooking }
                shopId={ this.state.shopId }
                show={ this.state.showBooking }
                brands={brands} 
                config={config} 
                i18n={i18n} 
                language={language} 
                updatevalue={this.updatevalue}
                
                ref={this.bookingPopup}

                logindata={this.state.logindata}
                user={this.state.user}
                formdata={this.state.formdata}  
                hasLogin={this.state.hasLogin}
              />

              <Webview
                url={this.state.url}
                show={ this.state.showWebView }
                i18n={i18n} 
                language={language} 
                updatevalue={this.updatevalue}
              />

              <Login 
                logindata={this.state.logindata}
                registerdata={this.state.registerdata}
                config={config}
                i18n={i18n}
                language={language}
                showLogin={this.state.showLogin}
                hasLogin={this.state.hasLogin}
                otp={this.state.otp}
                login={this.login}
                logout={this.logout}
                updatevalue={this.updatevalue}
                register={this.register}
                otpConfirm={this.otpConfirm}
              />

              <PrepareBuy
                
                config={config}
                i18n={i18n}
                language={language}
                showPrepareBuy={this.state.showPrepareBuy}
                hasLogin={this.state.hasLogin}
                updatevalue={this.updatevalue}

                data={!isToGo?this.state.eshopdata:this.state.togodata}
                order={!isToGo?this.eshopOrder:this.togoOrder}

                
              />

              <Cart 
                eshopOrder={this.eshopPrepareOrder}
                brands={brands}
                i18n={i18n}
                language={language}
                config={config}
                cart={cart}
                showCart={showCart}
                updatevalue={this.updatevalue}
                updatecart={this.updatecart}
                user={this.state.user}
                hasLogin={this.state.hasLogin}
              />

              {
                (this.state.showFooter) &&
                <Footer 
                  i18n={i18n}
                  languages={ languages }
                  language={ language }
                  selected={this.state.selected}
                  content={this.state.content} 
                  config={config}
                  brands={brands}
                  updatevalue={this.updatevalue}
                />
              }

            </BrowserRouter>

          </div>
        )
      } else {
        return (
          <Debug
            passcode={config.passcode}
            i18n={i18n} 
            language={ language }
            updatevalue={this.updatevalue} />
        )
      }
    } else {
      return (
        <div className={["App","Buffer"].join(" ")}>
          <Lottie 
            animationData={ Logo } 
            className={["B-Logo"].join(" ")} 
            loop={true}/>
          <div className={["Loading"].join(" ")}>NOW LOADING...</div>
        </div>
       )
    }
  }
}

export default App;


