import React, {Component} from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';


import { FaChevronCircleLeft, FaChevronCircleRight} from 'react-icons/fa';

import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`


class Pdf2 extends Component {
  constructor(props) {
    super(props)
    this.state={
      pageNumber : 1,
      numPages : 1,
      width : 951,
    }
    this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this)
    this.pagnation = this.pagnation.bind(this)
    this.pdfWrapper = React.createRef()
    this.setPdfSize = this.setPdfSize.bind(this)
  }
  
  componentDidMount() {
    window.addEventListener('resize', this.setPdfSize());
    this.setPdfSize();
  }

  componentDidUpdate(props, state) {

  }

  setPdfSize() {
    if (this.pdfWrapper && this.pdfWrapper.current) {
      this.setState({
        width : Math.min( 900, this.pdfWrapper.current.getBoundingClientRect().width)
      })
    }
  };

  onDocumentLoadSuccess(o) {
    this.setState({
      numPages : o.numPages
    });
  }

  pagnation(next) {
    const {pageNumber, numPages} = this.state
    if( next  ) {
      if( pageNumber+1 <= numPages ) {
        
        this.setState({ 
          pageNumber : pageNumber+1
        })
      }
    } else {
      if( pageNumber-1 >= 1 ) {
        this.setState({ 
          pageNumber : pageNumber-1
        })
      }
    }
  }

  render() {
    const {page, language, i18n, content} = this.props
    const url = `${window.location.origin}/resources/${content[page]['pdf'][language]}`
    const {pageNumber, numPages, width} = this.state
    //console.log( url )
    return(
      <div className={["Pdf"].join(" ")} ref={this.pdfWrapper}>

        <Document file={url} onLoadSuccess={ this.onDocumentLoadSuccess } >
          <Page pageNumber={ pageNumber } width={width}/>
        </Document>
        <div className={['Control'].join(" ")}>
          <FaChevronCircleLeft 
            onClick={()=>{
              if(pageNumber>1) {
                this.pagnation(false)
              }
            }}
            className={['icon', "left", (pageNumber===1)?"disabled":"" ].join(" ")}/>
          <p>
            {`${pageNumber} / ${numPages}`}
          </p>
          <FaChevronCircleRight 
            onClick={()=>{
              if(pageNumber<numPages) {
                this.pagnation(true)
              }
            }}
            className={['icon', "right" , (pageNumber===numPages)?"disabled":""].join(" ")}/>
        </div>
      </div>
    )

  }
}

export default Pdf2;