import React, {Component} from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';




class Membership extends Component {
  constructor(props) {
    super(props)
    this.state={

    }
  }
  
  componentDidMount() {
    this.props.updatevalue({ selected : this.props.page  })
  }

  render() {
    var content = this.props.content['membership']
    document.title =  content.display[this.props.language] + " | " + this.props.config['general']['name'][this.props.language]
    return (
      <div className={["Membership"].join(" ")}>
        {
          content.messages.map( (v,k) => {
            if( (v.length === 1) && v[0].type === "title" ) {
              return (
                <div className={['Title']} key={k}> { v[0]['message'][this.props.language]  } </div>
              )
            } else if( (v.length === 1) && v[0].type === "image" ) {
              return (
                <img 
                  id={ v[0].key }
                  key={k}
                  className={['Image']}
                  style={{
                    padding : (v[0].padding?v[0].padding:0) + "rem 0rem"
                  }}
                  src={ 
                    window.location.origin + "/resources/img/" +
                    ((this.props.language in v[0]['images'])?v[0]['images'][this.props.language]:v[0]['image'])
                   }
                />
              )
            } else if( (v.length === 1) && v[0].type === "remark" ) {
              return (
                <div className={['Remark']} key={k}> { v[0]['message'][this.props.language]  } </div>
              )
            } else {
              return  (
                <div className={['M-Wrapper'].join(" ")} key={k}>
                  {
                    v.map( (val, key) => {
                      if( val.type === "title" ) {
                        return(
                          <div className={["W-Title"]} key={key}> { val.message[ this.props.language] } </div>
                        )
                      } else if( val.type === "message" ) {
                        return(
                          <div className={["W-Message"]} key={key}> { val.message[ this.props.language] } </div>
                        )
                      } else if( val.type === "button" ) {
                        return(
                          <div className={["W-Button"]} key={key} onClick={ (e) => { if(val.link){ window.open(val.link, "_blank") } } }> 
                            { val.message[ this.props.language] } 
                          </div>
                        )
                      }
                    } )
                  }
                </div>
              )
            }
          })
        }
      </div>
    )
  }
}

export default Membership;