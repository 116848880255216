import React, {Component} from 'react';
import './index.scss';

import { FaTimesCircle } from 'react-icons/fa';

class Webview extends Component {
  constructor(props) {
    super(props)
    this.state={

    }
  }
  
  componentDidMount() {

  }

  componentWillUnmount() {

  }

  render() {
    const  {i18n, language, url, show, updatevalue } = this.props
    if( url ) {
      return (
        <div className={["Webview", show?"Show":""].join(" ")}>
          <div className={["Control"].join(" ")}>
            <div className={["Close-Btn"].join(" ")} onClick={ ()=>{ updatevalue({ showWebView : false, url : null }) } }> 
              <FaTimesCircle className='Icon' />
              <div className='Text'> { i18n['close_window'][language] } </div>
            </div>
          </div>
          <iframe className={["Iframe"].join(" ")} src={url}>
          </iframe>
        </div>
      )
    } else {
      return null
    }
  }
}

export default Webview;


