


var colors = {
  font : window.colors?window.colors.font:"14px/1.5 'Noto Sans TC', sans-serif",
  /*FRAME COLORS*/
  bgcolor: window.colors?window.colors.bgcolor:"#ffffff",
  whiteColor: window.colors?window.colors.whiteColor:"#ffffff",
  darkColor: window.colors?window.colors.darkColor:"#000000",
  greyColor: window.colors?window.colors.greyColor:"#EEEEEE",
  frameColor: window.colors?window.colors.frameColor:"#B28E43",

  accentColor : window.colors?window.colors.accentColor:"#714A23",
  accentColor2 : window.colors?window.colors.accentColor2:"#E4DBC0",
  accentColor3 : window.colors?window.colors.accentColor3:"#7C5B9E",

  frameColorSub : window.colors?window.colors.frameColorSub:"#714A23",
  frameColor2: window.colors?window.colors.frameColor2:"#2d184b",
  frameColor2Sub : window.colors?window.colors.frameColor2Sub:"#E0CB9C",

  /*FONT COLORS*/
  textColor: window.colors?window.colors.textColor:"#333333",
  textColorWhite: window.colors?window.colors.textColorWhite:"#FFFFFF",
  textColorLight: window.colors?window.colors.textColorLight:"#999999",
};

export default colors;
