import React, {Component} from 'react';
import './index.scss';
import service from '../../public/service';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//http://localhost:3000/booking/gt03?header=0&footer=0&popup=0

class Booking extends Component {
  constructor(props) {
    super(props)
    this.state={
      errors : {},
      records : {},
      selectedDate : new Date(), 
      selectedShop : null,
      shops : [],
      people : [],
      timeslots : {},
      timeslot : [],
      isLogin : false,
    }
    this.tableVacancyGet = this.tableVacancyGet.bind(this)
    this.setDate = this.setDate.bind(this)
    this.onSubmit = this.onSubmit.bind(this)

    this.set = this.set.bind(this)
    this.getPeopleTS = this.getPeopleTS.bind(this)
    this.validate = this.validate.bind(this)
  }
  
  componentDidMount() {
    const id = window.location.pathname.replace("/booking/", "")
    if( id !== "" ) {
      const {brands} = this.props
      var date = service.formatDate( this.state.selectedDate, false )
      this.tableVacancyGet(id, date)

      var shops = []
      var formdata = this.props.formdata
      for( var b of brands) {
        for ( var s of b['shop'] ) {
          if( s['id'] === parseInt( id, 10 )  ) {
            formdata['brand_code'] = b['brand_code']
            formdata['shop_id'] = parseInt( s['id'], 10 )
            //formdata['shop_id'] = s['id'].toString()
            shops = b['shop']
            break
          }
        }
      }

      this.setState({
        formdata : formdata,
        shops : shops
      })

    }
  }

  componentWillUnmount() {

  }

  getPeopleTS(data) {
    var min = 999, max = 0, timeslot = []
    var formdata = this.props.formdata
    for (var i of data) {
        min = Math.min( i['person_min'] , min )   
        max = Math.max( i['person_max'] , max )   
    }
    const people = Array.from({length: (max-min+1) }, (_, i) => {
        return min+i 
    })

    if( !formdata['person_count'] ) {
        formdata['person_count'] = Math.round(   max/2  )
    }

    
    
    
    for (var i of data) {
      if(  (i['person_min'] <= formdata['person_count']) &&  (i['person_max'] >= formdata['person_count'])    ) {
          timeslot = i['time_slot']
          break
      }
    }
    
    var date = new Date()
    var selectedDate = service.formatDate( this.state.selectedDate, false )
    var today = service.formatDate( date, false )
    if( selectedDate === today   ) {
      date = `${date.getHours()}${(date.getMinutes()>=10)?date.getMinutes():("0"+date.getMinutes()) }`
      timeslot = timeslot.filter( (v)=> parseInt( v.replace(":", "") , 10 ) >= date)
    }

    return { people : people, timeslot : timeslot, formdata : formdata }
  }

  async tableVacancyGet(shop_id, date) {
    if( (shop_id !== null) && ( date !== null ) && (shop_id !== "") && ( date !== "" )   ) {
      let data = await service.tableVacancyGet(shop_id, date)
      var {people, timeslot, formdata} = this.getPeopleTS( data  )
      formdata['date'] = service.formatDate( this.state.selectedDate, false )
      this.setState({
        people : people,
        timeslots : data,
        timeslot: timeslot,
        formdata : formdata
      })

    }
  }

  clear(isBrand) {
    var obj = {}
    var formdata = JSON.parse( JSON.stringify( this.props.formdata ) )
    delete formdata['time']
    delete formdata['person_count']
    delete formdata['time']
    obj['formdata'] = formdata
    obj['timeslot'] = []
    obj['people'] = []
    obj['timeslots'] = {}
    obj['selectedShop'] = null
    if (!isBrand) {
      obj['shops'] = []
    }
    this.setState( obj )
  }

  set(key, e) {
    
    if(key === "brand_code") {
      this.clear(false)
    } else if(key === "shop_id") {
      this.clear(true)
    }

    var formdata = JSON.parse( JSON.stringify(this.props.formdata) )
    var errors = this.state.errors
    var obj = {}
    obj['formdata'] = formdata
    
    if( key === "brand_code" ) {
      const {brands} = this.props
      const val = brands.filter( (v)=>{
        return v['brand_code']===e.target.value 
      })
      if( val.length > 0 ) {
        if( val[0]['shop'] )  {
          obj['shops'] = val[0]['shop']
        }
      }
      
    } else if ( key === "shop_id") {
      //this.clear(true)
      if( this.state.selectedDate ) {
        var date = service.formatDate( this.state.selectedDate, false )
        const { shops } = this.state
        const shop = shops.filter( (v)=>v['id']===parseInt(e.target.value, 0) )[0]
        obj['selectedShop'] = shop
        this.tableVacancyGet( e.target.value, date )
      }
    } else if ( key === "person_count" ) {
      //let data = await service.tableVacancyGet(shop_id, date)
      const {people, timeslot, formdata} = this.getPeopleTS(  this.state.timeslots )
      obj['people'] = people
      obj['timeslot'] = timeslot
    } 

    if( errors[key] ){
      delete errors[key]
      obj['errors'] = errors
    }

    formdata[key] = e.target.value
    obj['formdata'] = formdata
    this.setState(obj)
  }


  setDate(date) {
    this.setState({
      selectedDate : date
    })
  }

  setLogin(key , e) {
    /*var logindata = this.state.logindata
    logindata[key] = e.target.value
    this.setState( {
      logindata : logindata
    } )*/
    var {logindata, updatevalue} = this.props
    logindata[key] = e.target.value
    updatevalue({
      logindata : logindata
    })
  }

  async onSubmit(prod=false) {
    var {i18n, language, updatevalue, formdata} = this.props
    var { selectedShop, records, submiting } = this.state
    formdata = JSON.parse( JSON.stringify( formdata ))
    const _this =this
    if( submiting === false ) {
      this.setState( { submiting : true } , async () => {
        var errors = this.state.errors
        formdata['date'] = service.formatDate( this.state.selectedDate, false )  
        var checker = ["brand_code", "shop_id", 'time', "person_count", "name","email", "phone", "region" , "date"], valid = true
        for (var k of checker) {
          if( formdata[k] ) {
            if( formdata[k].length === 0 ) {
              valid = false
              errors[ k ] = true
            } else {
              if( errors[k] ) {
                delete errors[k]
              }
            }
          } else {
            valid = false
            errors[ k ] = true
            //break
          } 
        }
        valid = valid && service.validateNum( formdata['phone'] )
        valid = valid && service.validateEmail( formdata['email'] )
        if( valid) {
          formdata['phone'] = `${ formdata['region'].replace("+","") }:${ formdata['phone']}`
          formdata['reservation_time'] = `${formdata['date']} ${formdata['time']}:00`
          delete formdata['region']
          delete formdata['date']
          delete formdata['time']

          if( "remark" in formdata ) {
            if( formdata['remark'].length === 0 ) {
              delete formdata['remark']
            }
          }

          if(  selectedShop && selectedShop['reservation_setting'] && selectedShop['reservation_setting']['deposit_per_person'] && (selectedShop['reservation_setting']['deposit_per_person'] > 0) ) {
            let res = await service.reservationCreate( formdata )
            if( res['reservation_time'] ) {
              ///payment?header=0&popup=0&footer=0&reservation_id=45&chargetotal=200
              const total = selectedShop['reservation_setting']['deposit_per_person'] * res['person_count']

              var link = `${window.location.origin}/payment?reservation_id=${res['id']}&chargetotal=${total}`
              if( prod ) {
                link = link + "&mode=default"
              }

              window.open( 
                link,
                "_blank"
              )
             _this.clear()
              updatevalue({
                showBooking : false,
              })
            } else {
              //console.log(selectedShop['ui_text']['tel']['value'])
              var tel = ""
              try {
                tel  = selectedShop['ui_text']['tel']['value']
              } catch(e) {}
              alert( 
                i18n['booking_fail'][language] +
                ((tel!=="")?("\n" + i18n['booking_extra'][language].replace("<phone>", tel)):"")
              )
            }
          } else if(  selectedShop && selectedShop['reservation_setting'] && selectedShop['reservation_setting']['deposit_per_person'] && ((selectedShop['reservation_setting']['deposit_per_person'] == 0) || (!selectedShop['reservation_setting']['deposit_per_person'])) ) {
            let res = await service.reservationCreate( formdata )
            if( res['reservation_time'] ) {
              var shopstr = this.string("shop")
              const msg = i18n["booking_success"][language]
                + "\n" 
                + i18n['select_date'][language] +"\n" 
                + formdata['reservation_time'] + "\n"
                +"\n" 
                + i18n['select_restaurant'][language] +"\n"  
                + shopstr + "\n"
                +"\n" 
                + i18n['contact_person'][language] + "\n"
                + formdata['name'] + " ("+ formdata['phone'] + ")\n"
                +"\n" 
                + i18n['people'][language] + "\n"
                + formdata['person_count'] + "\n"
                +"\n" 
                + i18n['remark'][language] + "\n"
                + formdata['remark'] 
              alert( msg )
              _this.clear()
              updatevalue({
                showBooking : false,
              })
            } else {
              alert( i18n['booking_fail'][language] )
            }

          }
        } else {
          this.setState({ errors : errors })
        }
        _this.setState( { submiting : false} )
        
      } )
    }

  }

  validate(key, e, validator) {
    var valid = validator(e.target.value)
    var errors = this.state.errors
    if( !valid) {
      errors[key] = true
      this.setState({ errors : errors})
    } else {
      if( errors[key] ) {
        delete errors[key]
      }
      this.setState({ errors : errors})
    }
  }
  
  string(key) {   
    const { brands, language, i18n } = this.props
    var str = i18n["please_select"][language]
    switch(key) {
      case "shop":
        try {
          var _brand = brands.filter( (v)=>v['brand_code']=== this.state.selectedbrand )
          var _store = _brand[0].shop
          _store = _store.filter( (v)=>v['shop_code']=== this.state.selectedshop )
          str = _brand[0]['ui_text']['display'][language] + " (" + _store[0]['ui_text']['display'][language] + ")"
        } catch(e) {}
        break; 
      case "time":
        if(this.state.selectedTime) {
          var s = this.state.timeOptions.filter( (v)=>v.key===this.state.selectedTime  )
          str = s[0].value
        }
        break;
      default:
        str = i18n["please_select"][language]
        break;
    }
    return str
  }

  render() {

    const { brands, i18n, language, config, logindata, login, formdata, hasLogin } = this.props
    const { shops, selectedShop, isLogin, user } = this.state

    const _brands = service.massageBookingBrand( brands )

    try {
      const {regions} = config
      return (
        <div className={["Booking" ].join(" ")}>
          {
            /* 
            <Test />
            */
          }
          <div className={["Container"].join(" ")}>
            <div className={["Topic"].join(" ")}> { i18n['insert_a_reservation'][language] } </div>
            <div className={["Form"].join(" ")}>

              {/*BRANDS*/}
              <div className={["Holder"].join(" ")}>
                <div className={["Title", this.state.errors['brand_code']?"Error":""].join(" ")}>{ 
                  i18n["select_restaurant"][language] 
                  + 
                  (this.state.errors['brand_code']?("*" + i18n["required"][language]):"" )
                }</div>
                <select className={["Select"].join(" ")} onChange={ (e)=>this.set("brand_code", e) } value={ formdata['brand_code']?formdata['brand_code']:"" } >
                  <option value={ "" } >{ i18n['please_select'][language] }</option>
                  {
                    _brands.map( (v,k) => {
                      const {ui_text} = v
                      if( v['enabled'] && (v['brand_code'].toLowerCase().indexOf("test") === -1) ) {
                        return (
                          <option value={ v['brand_code'] } key={k}>
                            {  
                              ui_text['display']?
                                ui_text['display'][language]:
                                v['name']
                            }
                          </option>
                        )
                      } else {
                        return null
                      }
                    } )
                  }
                </select>
              </div>

              {/*SHOPS*/}
              <div className={["Holder"].join(" ")}>
                <div className={["Title", this.state.errors['shop_id']?"Error":""].join(" ")}>{ 
                  i18n["shop"][language] 
                  +
                  (this.state.errors['shop_id']?("*" + i18n["required"][language]):"" )
                }</div>
                <select className={["Select"].join(" ")} onChange={ (e)=>this.set("shop_id", e) } value={ formdata['shop_id']?formdata['shop_id']:"" } >
                  <option value={ "" } >{ i18n['please_select'][language] }</option>
                  {
                    shops.map( (v,k) => {
                      try {
                        if( v['enabled'] && (v['shop_code'].toLowerCase().indexOf("test") === -1) ) {
                          const { ui_text } = v
                          const name = (ui_text&&ui_text['display'])?ui_text['display'][language]:v['name'] 
                          return (
                            <option value={ v['id'] } key={k}>{ 
                              name
                            }</option>
                          )
                        } else {
                          return null
                        }
                      } catch(e) {
                        return null
                      }
                    } )
                    
                  }
                </select>
              </div>
          
              {/*DATE PICKER*/}
              <div className={["Holder"].join(" ")}>
                <div className={["Title", this.state.errors['reservation_time']?"Error":""].join(" ")}>{ 
                  i18n["select_date"][language] +
                  (this.state.errors['reservation_time']?("*" + i18n["required"][language]):"" )
                 }</div>
                <DatePicker 
                  selected={ this.state.selectedDate } 
                  onChange={(date) => this.setDate(date)} />
              </div>

              {/*PEOPLE*/}
              <div className={["Holder"].join(" ")}>
                <div className={["Title", this.state.errors['person_count']?"Error":""].join(" ")}>{
                   i18n["people"][language] +
                   (this.state.errors['person_count']?("*" + i18n["required"][language]):"" )
                }</div>
                <select className={["Select"].join(" ")} onChange={ (e)=>this.set("person_count", e) } value={ formdata['person_count']?formdata['person_count']:"" } >
                  <option value={ "" } >{ i18n['please_select'][language] }</option>
                  {
                    this.state.people.map( (v,k) => {
                      return (
                        <option value={ v } key={k}>{ v }</option>
                      )
                    } )
                  }
                </select>
              </div>

              {/*TIMESLOT*/}
              <div className={["Holder"].join(" ")}>
                <div className={["Title", this.state.errors['reservation_time']?"Error":""].join(" ")}>{ 
                  i18n["select_time"][language] +
                  (this.state.errors['reservation_time']?("*" + i18n["required"][language]):"" )
                }</div>
                <select className={["Select"].join(" ")} onChange={ (e)=>this.set("time", e) } value={ formdata['time']?formdata['time']:"" } >
                  <option value={ "" } >{ i18n['please_select'][language] }</option>
                  {
                    this.state.timeslot.map( (v,k) => {
                      return (
                        <option value={ v } key={k}>{ v }</option>
                      )
                    } )
                  }
                </select>
              </div>

              {/*Remarks*/}
              <div className={["Holder"].join(" ")}>
                <div className={["Title"].join(" ")}>{ 
                  i18n["remark"][language]
                }</div>
                <textarea 
                  className={["Textarea"].join(" ")}
                  value={ formdata['remark']?formdata['remark']:"" } 
                  onChange={ (e)=>{
                    const {formdata} = this.state
                    formdata['remark'] = e.target.value
                    this.setState({
                      remark : e.target.value
                    })
                  }} />
              </div>
              
              
              {/*Login*/}
              <div className={["Holder"].join(" ")}>
                { 
                  !hasLogin &&
                  <div className={['Switch'].join(" ")} onClick={()=>{
                    //this.setState( { isLogin : !isLogin } )
                  }}> { `${i18n['signin'][language]}?` } </div>
                }
                {
                  hasLogin && 
                  <>
                  <div className={['Statement'].join(" ")} >  {  i18n['hi_name'][language].replace( "<name>", (user?user['first_name']:"Member") )  } </div>
                  <div className={['Underline'].join(" ")}> </div>
                  </>
                }
                {
                  ( isLogin && !hasLogin) &&
                  <div className={ ['Row'].join(" ") } >
                      <div className={ ['Wrapper'].join(" ") } >
                        <div className={["Title"].join(" ")}>{ i18n["email"][language] }</div>
                        <input value={ logindata['email']?logindata['email']:"" }  onChange={ (e)=>this.setLogin( "email" , e ) } />
                      </div>
                      <div className={ ['Wrapper'].join(" ") } >
                        <div className={["Title"].join(" ")}>{ i18n["password"][language] }</div>
                        <input 
                          type={"password"}
                          value={ logindata['password']?logindata['password']:"" } 
                          onChange={ (e)=>this.setLogin( "password" , e ) } />
                      </div>
                      <div className='Login' onClick={ ()=>{
                        login()
                      } }> 
                        {i18n["signin"][language]} 
                      </div>
                  </div>
                }
              </div>

              {/*NAME*/}
              {
                ( !isLogin || hasLogin) &&
                <div className={["Holder"].join(" ")}>
                  <div className={ ['Row'].join(" ") } >
                    <div className={ ['Wrapper'].join(" ") } >
                      <div className={["Title", this.state.errors['phone']?"Error":"" ].join(" ")}>{ 
                        i18n["phone"][language] +
                        (this.state.errors['phone']?("*" + i18n["required"][language]):"" )
                      }</div>
                      <div className={["Phone"].join(" ")}>
                        <select value={ formdata['region']?formdata['region']:"" } onChange={ (e)=>{
                          this.set("region", e )
                        } } >
                          {
                            regions.map( 
                              (v,k) => {
                                return (
                                  <option value={ v['value'] } key={k} > { v['value'] } </option>
                                )
                              }
                            )
                          }
                        </select>
                        <input value={ formdata['phone']?formdata['phone']:"" } onChange={ (e)=>this.set("phone", e) } onBlur={ (e)=>this.validate( "phone", e , service.validateNum ) } />
                      </div>
                    </div>
                  </div>
                  <div className={ ['Row'].join(" ") } >
                    <div className={ ['Wrapper'].join(" ") } >
                      <div className={["Title", this.state.errors['name']?"Error":""].join(" ")}>{ 
                        i18n["first_name"][language] +
                        (this.state.errors['name']?("*" + i18n["required"][language]):"" )
                      }</div>
                      <input value={ formdata['name']?formdata['name']:"" } onChange={ (e)=>this.set("name", e) } />
                    </div>
                    <div className={ ['Wrapper'].join(" ") } >
                      <div className={["Title", this.state.errors['email']?"Error":""].join(" ")}>{ 
                        i18n["email"][language] +
                        (this.state.errors['email']?("*" + i18n["required"][language]):"" )
                      }</div>
                      <input value={ formdata['email']?formdata['email']:"" } onChange={ (e)=>this.set("email", e) } onBlur={ (e)=>this.validate( "email", e ,service.validateEmail ) } />
                    </div>
                  </div>
                  
                </div>
              }
              

              {/*DEPOSIT*/}
              {
                selectedShop && selectedShop['reservation_setting'] && selectedShop['reservation_setting']['deposit_per_person'] && (selectedShop['reservation_setting']['deposit_per_person'] > 0) &&
                <div className={["Holder", "Deposit"].join(" ")}>
                  <div className={['Title'].join(" ")}> {"Deposit"} </div>
                  <div className={['Line'].join(" ")}>
                    <div className={['L-Title'].join(" ")}>
                      {  
                        `${ "Price" }` 
                      }
                    </div>
                    <div className={['L-Item'].join(" ")}>
                      {  
                        `$${selectedShop['reservation_setting']['deposit_per_person'].toLocaleString()}/ppl` 
                      }
                    </div>
                  </div>
                  <div className={['Line'].join(" ")}>
                    <div className={['L-Title'].join(" ")}>
                      {  
                        `${i18n["people"][language]}` 
                      }
                    </div>
                    <div className={['L-Item'].join(" ")}>
                      {  
                        `${this.props.formdata['person_count']?this.props.formdata['person_count']:"0"}` 
                      }
                    </div>
                  </div>
                  <div className={['Line'].join(" ")}>
                    <div className={['L-Total'].join(" ")}>
                      {  
                        `$${ (   this.props.formdata['person_count']?(selectedShop['reservation_setting']['deposit_per_person'] * parseInt(this.props.formdata['person_count'], 10)):0     ).toLocaleString()}` 
                      }
                    </div>
                  </div>
                
                </div>
              }

              <div className={["Holder", "Sticky-Bottom"].join(" ")}>                  
                <div className={['Submit'].join(" ")} onClick={ ()=>this.onSubmit() }> { i18n['insert_a_reservation'][language] } </div>

                {
                  config["mode"] === "debug" &&
                  <div className={['Submit'].join(" ")} onClick={ ()=>this.onSubmit(true) }> { `${i18n['insert_a_reservation'][language]} - PROD` } </div>
                }

              </div>
              

            </div>
          </div>
          {
            this.props.show && 
            <div className='Frame' onClick={ ()=>{
              this.props.toggleBooking( false )
            } }> 

            </div>
          }
        </div>
      )
    } catch(e){
      console.log(e)
      return null
    }
  }
}

export default Booking;


