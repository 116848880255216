import React, {Component} from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import { Link } from 'react-router-dom'
import service from '../../public/service';



import { FaFacebookSquare, FaInstagramSquare } from 'react-icons/fa';

const HEIGHT = 360

class Brand extends Component {
  constructor(props) {
    super(props)
    this.state={
      height : 400,
    }
    this.scroll = this.scroll.bind(this)
    this.open = this.open.bind(this)
  }
  
  componentDidMount() {
    window.scrollTo(0, 0);
    window.addEventListener("scroll", this.scroll)
    this.props.updatevalue({ selected : this.props.page })
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scroll)
  }

  scroll() {
    //console.log( window.pageYOffset )
    if( Math.round(window.pageYOffset) <= HEIGHT ) {
      this.setState({
        height: (400-Math.round(window.pageYOffset)),
      })
    }
  }

  open(link) {
    window.open(link, "_blank");
  }

  render() {
    const { body, config, language, i18n, updatevalue, hasBooking } = this.props
    try {
      if( body ) {
        const { ui_text, brand_code, asset, shop, enabled, name } = body
        const { display, cover, cover_message, description, badge, fb, ig, image, image_mockup, key } = ui_text
        const url = service.asset
        document.title =  display[language] + " | " + config['general']['name'][language]
        return (
          <div className={["Brand"].join(" ")}>
            <div className={['B-Cover'].join(" ")} style={{ height : this.state.height+"px" }} >

              {cover && asset[ cover['value'] ] &&
                <img className={['Img'].join(' ')} src={  `${url}/${asset[ cover['value'] ]["file_path"]}`  } />
              }
              { !(cover && asset[ cover['value'] ]) &&
                <div className={['Img', "Mock"].join(' ')}> { i18n['commingsoon'][this.props.language] } </div>
              }
              <div
                style={{
                  backgroundColor : "rgba(0,0,0," + (HEIGHT-this.state.height)/HEIGHT*4 +")",
                }} 
                className={['Message'].join(" ")}> 
                
                  { 
                    cover_message &&
                    cover_message[language].replace(/ /g, "").split("").map(
                      (v,k) => {
                        return(
                          <span className={[  (cover_message[language].replace(/ /g, "").split("").length>5)?"Char-N":'Char'].join(" ")} key={k}> {v} </span>
                        )
                      }
                    )
                  } 
              </div>
            </div>
            <div className={['Body'].join(" ")} > 
              <div className={["Holder"].join(" ")}>
                <div className={['Left'].join(" ")}>
                  <div className={["Logo-Frame"].join(" ")}>
                    { 
                      (image && asset[ image['value'] ]) &&
                      <img className={["Logo"].join(" ")} src={  `${url}/${asset[ image['value'] ]["file_path"]}`  } />
                    }
                    {
                      /*
                    <div className={["Logo"].join(" ")}
                      style={ {
                        backgroundImage : "url(" +  (window.location.origin + "/resources/img/brand/" + this.props.body.key + "/" +this.props.body.image) +")"
                      } }
                    ></div>
                    */
                    }
                    </div>
                  <div className={['Badges'].join(" ")}>
                    {
                      badge &&
                      badge[this.props.language].split("|").map( (v1, k1) => {
                        return (
                          <div className={["Badge"].join(" ")} key={k1}>
                            {v1}
                          </div>
                        )
                      } )
                    }
                  </div>
                </div>
                <div className={['Right'].join(" ")}>
                  <div className={['Social-W']}>
                      { fb  &&
                        <FaFacebookSquare className={['Icon'].join(" ")}  onClick={ (e)=>{  this.open(  fb['value'] )  } } />
                      }
                      { ig  &&
                        <FaInstagramSquare className={['Icon'].join(" ")} onClick={ (e)=>{  this.open(  ig['value'] )  } } />
                      }
                  </div>
                  <div className={["Content"]}>
                      {
                        description &&
                        description[ this.props.language ].split("\n").map( (v,k) => {
                          return (
                            <p key={k}> {v} </p>
                          )
                        } )
                      }
                  </div>
                </div>
              </div>
              <div className={['Shops'].join(" ")}>
                {
                  shop && 
                  shop.map( (v,k) => {
                    const { asset, enabled, ui_text, shop_code, name, reservation_setting} = v
                    const { cover,  display, address, tel } = ui_text
                    if( enabled ) {
                      try{
                        return (
                          <Link
                            onClick={ ()=>window.scrollTo(0,0) }
                            to={  "/brand/" + brand_code.toLowerCase() +"/shop/" + shop_code.toLowerCase() } 
                            className={['Shop-Box'].join(" ")} key={k}>

                            { cover && asset[cover['value']] &&
                              <img className={['Bg-Img'].join(" ")} src={ `${url}/${asset[cover['value']]['file_path']}` }  />
                            }
                            { !(cover && asset[cover['value']]) &&
                              <div className={['Bg-Img', "Mock"].join(" ")} >
                                { i18n['commingsoon'][language] }
                              </div>
                            }

                            <div className={['Bottom'].join(" ")}>
                              <div className={['Title'].join(" ")}> { display?display[language]:name } </div>
                              {
                                address &&
                                <div className={['Address'].join(" ")}> { address[language] } </div>
                              }
                              <div className={['R-Holder'].join(" ")}>
                                {
                                  tel &&
                                  <div className={['Register'].join(" ")} onClick={
                                    ()=>{
                                      var a = tel['value'].replace(/ /g, "").split(")")
                                      if( a.length > 1 ) {
                                        window.open( ('tel:' + a[1]) , "_self" )
                                      }
                                  }}> {
                                    i18n["reservation"][language].replace("<phone>", tel['value'] )
                                  } </div>
                                }
                                {
                                  hasBooking && reservation_setting && reservation_setting['open'] &&
                                  <div className={['Register'].join(" ")} onClick={
                                    ()=>{
                                      updatevalue({
                                        showBooking : true,
                                        shopId : v['id']
                                      })
                                  }}> {
                                    i18n["insert_a_reservation"][language]
                                  } </div>
                                }
                              </div>
                            </div>
                          </Link>
                        )
                      }
                      catch(e) {
                        return null
                      }
                    } else {
                      return null
                    }
                  } )
                }
              </div>
            </div>
          </div>
        )
      } else {
        return null
      }
    } catch(e) {
      console.log(e)
      return null
    }
  }
}

export default Brand;