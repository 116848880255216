import React, {Component} from 'react';
import './index.scss';
import service from '../../public/service';
import Detail from "./Detail"
import { FaShoppingCart } from "react-icons/fa"
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import ToCart from './Cart';

//MEMBER TEST
//http://localhost:3000/catering?header=1&popup=0&footer=0&language=en&shopcode=OFFIC&session=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoyMTAsImNyZWF0aW9uX3RpbWUiOiIyMDI0LTA0LTAzIDE0OjE2OjAxIiwiZXhwaXJhdGlvbl90aW1lIjoiMjAyNC0wNC0xMCAxNDoxNjowMSIsImFkbWluX2dyb3VwIjoiU3lzdGVtIiwiZ3JvdXBzIjpbIlN0YWZmIiwiU3VwcG9ydCIsIlN5c3RlbSJdfQ.7CFxNTDr4aLn_m7hstBIJaMR12Gh1vdzonuA9JWSIKY&id=210&d=DS01&t=t01

//ANONOMY TEST
//http://localhost:3000/catering?header=1&popup=0&footer=0&language=en&shopcode=OFFIC&d=DS01&t=t01

const filters = [
  /*
  {
      key : "item",
      value : [
        { "key" : "all", "value" : "all" },
        { "key" : "hot_item", "value" : "hot_item" },
        { "key" : "latest", "value" : "latest" },
      ]
  },
  */
  {
    key : "type",
    value : [
      { "key" : "all", "value" : "all" },
      { "key" : "coupon", "value" : "coupon" },
      { "key" : "food", "value" : "food" },
      { "key" : "gift", "value" : "gift" },
      { "key" : "special", "value" : "special" },
    ]
  },
  {
      key : "price",
      value : [
        { "key" : "all", "value" : "all" },
        { "key" : "price_1", "value" : "$0-$100" , "up" : 100, "down" : 0 },
        { "key" : "price_2", "value" : "$101-$500", "up" : 500, "down" : 101 },
        { "key" : "price_3", "value" : "$501-$1000", "up" : 1000, "down" : 501 },
        { "key" : "price_4", "value" : "$1001+","up" : 999999999, "down" : 1001 },
      ]
  },
]

const LIMIT = 60

class Catering extends Component {
  
  constructor(props) {
    super(props)
    this.interval = 0
    this.state={
      
      page : 1,
      total : 9999,
      index : 0,
      height : 300,
      count : 0,
      selectedFilter : {"items" : "all", "price" : "all"},
      selected : null,

      isPicked : false,

      menu : [],

      items : [],
      origin_items : [],

      selectedBrand : null,
      selectedShop : null,
      selectedMenu : 0,

      avaibale_shopcodes : [],

      //selectedShop : "OFFIC"
      showMenuOrder : false
    }
    this.init = this.init.bind(this)
    this.changeFilter = this.changeFilter.bind(this)
    this.pagnation = this.pagnation.bind(this)
    this.updatevalue = this.updatevalue.bind(this)
    this.getUser = this.getUser.bind(this)

    this.getMenu = this.getMenu.bind(this)

    this.change = this.change.bind(this)

    this.gi18n = this.gi18n.bind(this)
    this.getShops = this.getShops.bind(this)

    this.renderTopBar = this.renderTopBar.bind(this)
    this.renderCover = this.renderCover.bind(this)
    this.renderMenu = this.renderMenu.bind(this)
    this.renderItem = this.renderItem.bind(this)
  }
  
  componentDidMount() {
    const { items } = this.state
    if( items.length === 0 ) {
      this.init()
    }
  }

  componentWillUnmount() {
    //clearInterval( this.interval )
  }

  componentDidUpdate(props, state) {
    //if( !state['selected'] && !this.state['selected'] ) {
      //this.searchItem()
    //}
  }

  async getUser(user_id) {
    const {updatevalue} = this.props
    let user = await service.getUser( user_id )
    if( user['id'] ) {
      service.user = { "user" : user }
      
      updatevalue({
        hasLogin : true,
        user : { "user" : user }
      })
    }
  }

  async init() {
    const { hasLogin, brands, config} = this.props
    
    const params = service.getParamQuery()
    if(!hasLogin) {
      var session_key = null, user_id = null
      
      if( params['session'] ) {
        session_key = params['session']
      }
      if( params['id'] ) {
          user_id = params['id']
      }
      if(session_key&&user_id) {
        service.session_key = session_key 
        this.getUser( parseInt( user_id , 10 ))
      } 
    }
    if ("shopcode" in params) {
      const shopcodes = params['shopcode'].split(",")
      if( shopcodes.length > 0 ) {
        var o = {}
        o["avaibale_shopcodes"] = shopcodes
        if( shopcodes.length > 0 ) {
          o['selectedShop'] = shopcodes[0]

          for (var b of brands) {

            //const valid = (isDev || b['brand_code']!=="TEST")
            const valid = true

            if(  b['enabled'] && (b['shop'].length > 0) && valid ) {
              for (var s of b['shop']) {
                if( s['enabled'] && s['shop_code'] === shopcodes[0] ) {
                  o['selectedBrand'] = b['brand_code']
                  break
                }
              }
            }
          }

        }
        this.setState(o , async ()=>{
          if( shopcodes.length > 0 ) {
            await this.getMenu( shopcodes[0] )
          }
        })
      }
    }
  }

  async pagnation(page) {
    const {cover} = this.state
    var {origin_items} = this.state
    const offset = ((page-1) * LIMIT )+ 0
    //let data = await service.getShopItems(offset, LIMIT)
    let data = await service.getEshopItems(offset, LIMIT)
    if( "list" in data) {

      const _cover = data['list'].filter( (v) => ((v['priority'] > 999) && (v["ui_text"] && v["ui_text"]['cover']) && (v["asset"])) )
      const count = parseInt(data['count'], 10)

      origin_items = origin_items.concat( data['list'] )
      origin_items = origin_items.filter((value, index) => {
        return index === origin_items.findIndex(obj => {
          return obj['id'] === value['id'];
        });
      });

      this.setState({
        page : page,
        cover : cover.length>0?cover:_cover,
        items : data["list"],
        origin_items : data["list"],
        count : count,
        totalPage : Math.ceil( count/LIMIT  )
      })
    }
  }

  async getMenu(shopcode) {
    //console.log('YOU?')
    
    let data = await service.getFoodItems(shopcode)
    if( data.length > 0 ) {
      this.setState({
        selectedMenu : 0,
        menu : data,
        items : data[0]['menuitems'],
        //isPicked : false,
      })
    } else {
      this.setState({
        selectedMenu : 0,
        menu : [],
        items : [],
        //isPicked : false,
      })
    }

  }

  changeFilter( key, value ) {
    var { selectedFilter, origin_items, items, page } = this.state
    selectedFilter[key] = value
    var obj = {}
    obj[ 'selectedFilter'] = selectedFilter
    if( key === "price" ) {
      if( value !== "all" ) {
        const f = filters.filter( (v) => v['key'] === key )
        const val = f[0]['value'].filter( (v) => v['key'] === value )[0]
        items = origin_items.filter( (v) => v['price'] >= val['down'] && v['price'] <= val['up'] )
        obj['items'] = items
        obj['count'] = items.length
      } else {
        this.pagnation(page)
      }
    } else if ( key === "type") {
      //console.log(value)
      if( value !== "all" ) {
        items = origin_items.filter( (v) => {
          const {ui_text} = v
          if (ui_text && ui_text['keywords']  ) {
            const a = ui_text['keywords']['value'].split(",")
            return a.includes( value )
          } else {
            return false
          }
        } )
        obj['items'] = items
        obj['count'] = items.length
      } else {
        this.pagnation(page)
      }
    }
    this.setState(  obj )
  }

  updatevalue(obj, cb) {
    this.setState(obj, cb)
  }

  change(key, value) {

    if( key === "selectedShop" ) {
      const params = service.getParamQuery()
      const { clearTogo } = this.props
      const {selectedShop} = this.state
      if( (selectedShop !== value) && (selectedShop !== null) ) {
        if( !("d" in params) ) {
          const a = window.confirm( this.gi18n("to_entitle_swicth_brand") )
          if(a) {
            clearTogo()
          } else {
            return 
          }
        }
      }
    }


    var obj = {}, gotoShop = null
    const val = (this.state[key] !== value)?value:null
    if( key === "selectedBrand" ) {
      const {brands} = this.getShops()
      const b = brands.filter( (v) => v['brand_code'] === value )
      obj['selectedShop'] = null
      
      
      if( b.length > 0 ) {
        if( b[0]['shop'].length > 0 ) {
          obj['selectedShop'] = b[0]['shop'][0]['shop_code']
          //this.getMenu( b[0]['shop'][0]['shop_code'] )
          gotoShop = b[0]['shop'][0]['shop_code']
        }
      }
      
    }
    obj[key] = value
    
    //console.log( `${key} | ${value}` )
    this.setState(obj, ()=>{
      if( key === "selectedShop" ) {
        this.getMenu( value )
      } else if( gotoShop !== null ) {
        this.getMenu( gotoShop )
      }
    })
    
  }

  gi18n(key) {
    const { i18n, language} = this.props
    return (key in i18n)?i18n[key][language]:key
  }

  getShops() {
    const {brands} = this.props
    const {avaibale_shopcodes} = this.state
    //console.log( avaibale_shopcodes  )
    var _brands = []
    var shops = []
    
    for (var b of brands) {
      var _b = JSON.parse( JSON.stringify( b ) )
      _b['shop'] = []
      if( b['enabled'] ) {
        for (var s of b['shop']) {
          if( s['enabled'] ) {
            if(  s['online_order'] ) {
              var valid = true
              if( avaibale_shopcodes.length > 0 ) {
                valid = (avaibale_shopcodes.includes(s['shop_code']) )
              }
              if(valid) {
                s['brand'] = JSON.parse( JSON.stringify(_b) )
                shops.push( s )
                _b['shop'].push( s )
              }
            }
          }
        }
        if( _b['shop'].length > 0 ) {
          _brands.push( _b )
        }
      }
    }

    return { shops : shops, brands : _brands }
  }

  renderCover() {

    const {isPicked} = this.state
    return (
      <div className={['M-Cover'].join(" ")} 
        onClick={ ()=>{
          this.setState( {
            isPicked : !isPicked
          } )
        }}
        style={{
          backgroundImage : `url("${service.assetImage(`assets/to-goer/to_go_cover.webp`)}")`
        }}
      >  
        <div className={['M-Message'].join(" ")}> 
          <img src={ window.logo } className={['M-C-Logo'].join(" ")} />
          <h4>{ this.gi18n("to_entitle_select_restaurant")  }</h4>
        </div>
      </div>
    )
  }

  renderTopBar() {
    const { i18n, language, config } = this.props
    const { selectedBrand, selectedShop, isPicked } = this.state
    const { brands } = this.getShops()
    return (
      <div className={["CA-Shops", isPicked?"Picked":"" ].join(" ")}
        onClick={ (e)=> {
          if( !(["CA-S-Brand", "CA-S-Brand Selected", "CA-S-Brand", "CA-Image", "CA-S-Logo"].includes( e.target.className )) )  {
            this.setState({
              isPicked : false
            })
          } else {
            this.setState({
              isPicked : true
            })
          }
        }}
      >
              <div className={["CA-S-Holder" ].join(" ")} id={"List"}>
                {
                  brands.map( (v, k) => {   
                    const {asset, ui_text, enabled, shop } = service.massageAsset2( v )
                    const valid = ( (config['mode'] === "debug") || v['brand_code']!=="TEST")
                    if( enabled && (shop.length > 0) && valid ) {
                      const name = ("display" in ui_text)?ui_text['display'][language]:v['name']
                      const image = (("image" in ui_text) && asset[ui_text['image']['value']])?`${service.asset}/${asset[ui_text['image']['value']]['file_path']}`:null
                      return (
                        <div title={name}
                          id={`b_${k}`}
                          className={["CA-S-Brand", (selectedBrand===v['brand_code'])?"Selected":"" ].join(" ")} key={k} onClick={ ()=>{
                            this.change("selectedBrand", v['brand_code']) 
                            //var elem = document.getElementById(`b_${k}`).scrollIntoView()
                          }}>
                          <div className={["CA-S-Logo" ].join(" ")} >
                            { true === false &&
                            <div className={["CA-Image" ].join(" ")} 
                              style={{
                                backgroundImage : `url("${image}")`
                              }}>
                              {!image?v['brand_code'].substring(0,1):null}
                            </div>
                            }
                            { image &&
                              <img className={["CA-Image" ].join(" ")} src={image}/>
                            }
                            {
                              !image &&
                              <div className={["CA-Image" ].join(" ")} > { v['brand_code'].substring(0,1) } </div>
                            }
                          </div>
                        </div>
                      )
                    } else {
                      return null
                    }
                  } )
                }
              </div>
              {
                selectedBrand && 
                <div className={["CA-S-Holder" ].join(" ")}>
                  {
                    brands.filter((v)=>v['brand_code']===selectedBrand)[0]['shop'].map((v,k)=>{
                      const {asset, ui_text, enabled, shop } = service.massageAsset2( v )
                      const name = ("display" in ui_text)?ui_text['display'][language]:v['name']
                      const names = name?name.split("-"):[ v['name'] ]
                      const display = names.length>1?names[1].replace(" ", ""):names[0]
                      return (
                        <div className={["CA-S-Brand", "CA-S-Shop", selectedShop===v['shop_code']?"Selected":"" ].join(" ")} 
                          key={k} 
                          onClick={ ()=>{
                            this.change("selectedShop", v['shop_code']) 
                          }}>
                          {display}
                        </div>
                      )
                    })
                  }
                </div>
              }
      </div>
    )
  }

  renderMenu() {
    const {  menu, selectedMenu  } = this.state
    const {  language } = this.props
    return (
      <div className={["CA-Menu" ].join(" ")}>
        {
          menu.map( (v, k) => {
          const {asset, ui_text } = service.massageAsset2( v )
          return (
            <div className={["CA-Menu-List", (selectedMenu===k)?"Selected":"" ].join(" ")} key={k} onClick={ ()=>{
              //items
              this.setState({
                items : v["menuitems"],
                selectedMenu : k
              })
            } }>
              {
                'cover' in ui_text &&
                  <img src={ service.assetImage( asset[  ui_text['cover']['value'] ]['file_path'] ) } />
              }
              <span>{ ui_text['display'][language] }</span>
            </div>
          )
          } )
        }
      </div>
    )
  }

  renderItem() {
    const { items, selected } = this.state
    const { hasLogin, user, i18n, language } = this.props

    try {
      return (
        <div className={["CA-Items-Holder", selected?"Locked":"" ].join(" ")}>
            {
              items.length > 0 &&
              items.map( (v,k) => {
                const {ui_text, sold_out} = v
                try {
                    const {asset} = service.massageAsset2( v )
                    var image = null
                    if( ui_text["cover"] ) {
                      if( asset && asset[ ui_text["cover"]["value"] ] ) {
                        image = `${service.asset}/${asset[ ui_text["cover"]["value"] ]["file_path"]}`
                      }
                    }
                    var price_display = 0
                    const {price, remaining, tier_pricing, item_type } = v
                    var {original_price, discount_type} = ui_text      
                    var appendix = ""
                    if( tier_pricing ) {
                      if ( hasLogin ) {
                        const gp = tier_pricing.filter( (v)=>v['tier_level']===user['user']['tier_level'] )
                        if( gp.length > 0 ) {
                          appendix = original_price?
                              `${i18n[`es_member_price`][language]}:`:""
                              price_display = gp[0]['price']>-1?gp[0]['price']:price
                        } else {
                          price_display = price
                        }
                      } else {
                        appendix = original_price?
                          `${i18n[`es_member_price`][language]}:`:""
                            price_display = tier_pricing[0]['price']>-1?tier_pricing[0]['price']:price
                          }
                    } else {
                      appendix = original_price?
                          `${(discount_type?i18n[`es_${discount_type['value']}_price`][language]:i18n[`es_special_price`][language])}:`:""
                            price_display = price
                    }
                  return (
                    <div className={["CA-Item", (remaining===0)?"Soldout":"" ].join(" ")} key={k} onClick={ ()=> {
                        //if( remaining > 0 ) {
                        if( !sold_out ) {
                          this.setState({ selected : v})
                        }
                        //}
                    } }>
                      {
                        remaining === 0 &&
                        <div className={["Soldout-Text"].join(" ")}><div className={["Text"].join(" ")}>{ i18n["es_soldout"][language] }</div></div>
                      }
                      <div className={["CA-Carrier" ].join(" ")}>
                                { 
                                  image &&
                                  <img src={image} className={["CA-I-Cover"].join(" ")} />
                                }
                                {
                                  !image &&
                                  <div src={image} className={["CA-I-Cover", "Mask"].join(" ")} >{ ui_text['display']?ui_text['display'][language]:v['name'] }</div>
                                }
                                <div className={["CA-I-Title"].join(" ")}> 
                                    {
                                      item_type==="coupon" &&
                                      <span className={["CA-Badge"].join(" ")}>{ i18n["es_coupon"][language] }</span>
                                    }
                                    <pre>{ ui_text['display']?ui_text['display'][language]:v['name']  }</pre>
                                </div>
                                { ui_text['desc'] && ui_text['desc'][language].length > 0 &&
                                  <div className={["CA-I-Desc"].join(" ")}> { ui_text['desc'][language] } </div>
                                }
                                <div className={["CA-I-Price"].join(" ")}>
                                  { 
                                    original_price && original_price['value'] &&
                                    <div className={["Original"].join(" ")}>{`${i18n['es_original_price'][language]} : $ ${ parseFloat(original_price['value'], 10).toLocaleString()  }`}</div>
                                  }
                                  <div className={["Price", sold_out?"Soldout":""].join(" ")}>
                                    {
                                      !sold_out &&
                                      <>
                                        <span className={["Appendix"].join(" ")}>{ appendix }</span>
                                        <span>{`$${  (price_display>0)?price_display.toLocaleString():" -- " }`}</span>
                                      </>
                                    }
                                    {
                                      sold_out && <span> { this.gi18n( "to_entitle_soldout" ) } </span>
                                    }
                                  </div>
                                </div>
                      </div>
                    </div>
                  )
                } catch(e) {
                  return null
                }
              } )
            }
            {
              items.length === 0 &&
              <div className={["No-Item"].join(" ")}>{  this.gi18n("to_entitle_noitem")  }</div>
            }
        </div>
      )
    } catch(e) {
      console.log(e)
      return null
    }
  }

  render() {
    const { i18n, language, config, cart, updatevalue, user, hasLogin, menuorder } = this.props
    const { selected, selectedBrand, selectedShop, showMenuOrder } = this.state
    //https://daynightcatering.com/


    var count = 0, price = 0
    for (var key in menuorder ) {
      count += menuorder[key].length
      for (var item of menuorder[key]) {
        try {
          price += (item['item']['price'] * item['quantity'] )
          price += ((item['add_amount']?item['add_amount']:0) * item['quantity'])
        } catch(e) {}
      }
    }

    try {
      return (
        <>
          <div className={["Catering", selected?"Lock":"" ].join(" ")}>

            { this.renderTopBar() }
            {
              selectedShop &&
              <div className={["CA-Content" ].join(" ")} onClick={()=>this.setState({isPicked : false})}>
                
                { this.renderMenu() }
                { this.renderItem() }
                
              </div>
            }
            {
              !selectedShop && 
              this.renderCover()
            }
            <div className='CA-Order-Btn' onClick={()=>this.setState({isPicked : false})}> 
              <div className='Top' onClick={
              () => {
                this.setState({ showMenuOrder : true })
              }
            }>
                <span>{ `${this.gi18n("to_entitle_order")} (${count})` } </span>
                <pre className='price'>{ `$ ${ price.toLocaleString(undefined, {
                      minimumFractionDigits: 1,
                      maximumFractionDigits: 1
                    }) }` }</pre>
              </div>
              <div className='Bottom' dangerouslySetInnerHTML={{
                __html : this.gi18n("to_entitle_agree_terms")
              }}/>
            </div>

          </div>

          <Detail 
            {...this.props} 
            selectedShop={selectedShop}
            updateParent={this.updatevalue}
            selected={selected}/>
          <ToCart 
            {...this.props} 
            showMenuOrder={showMenuOrder}
            updateParent={this.updatevalue}
            gi18n={this.gi18n}
          />
        </>
      )
    } catch(e){
      return null
    }
  }
}

export default Catering;



/*
<ResponsiveMasonry
                columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}}
            >
                <Masonry>
                  {
                    this.props.body.images.map( (v,k) => {
                      return(
                        <img 
                          onClick={ (e)=>{
                            this.open(k)
                          } }
                          key={k}
                          className={["Image"].join(" ")} 
                          src={  window.location.origin + "/resources/img/brand/" + this.props.parent.key + "/"  + this.props.body.id + "/" + v } />
                      )
                    } )
                  }
                </Masonry>
            </ResponsiveMasonry>
*/