import React, {Component} from 'react';
import './index.scss';
import { Link } from 'react-router-dom';
import service from '../../public/service';
import { FaPlusCircle, FaGift } from 'react-icons/fa';
import Gift from './Gift';

/*
[{"key":"g_50","value":50},{"key":"g_100","value":100},{"key":"g_500","value":500},{"key":"g_1000","value":1000}]
*/


class Wallet extends Component {
  constructor(props) {
    super(props)
    this.state={
      card_value : [],
      price_value : [],

      hasPhone : true,
      //region : null,
      phone : null,
    }
    //this.init = this.init.bind(this)
    this.getUser = this.getUser.bind(this)
  } 
  
  componentDidMount() {
    var session_key = null, user_id = null, phone = ""
    const params = service.getParamQuery()


    //console.log( service.session_key  )

    if( params['session'] ) {
      session_key = params['session']
    } else {
      if( service.session_key  ) {
        session_key = service.session_key
      } else {
        //console.log("A")
        window.location.href = window.location.origin
      }
    }

    

    if( params['id'] ) {
      user_id = params['id']
    } else {
      if( service.user  ) {
        user_id = service.user.user?service.user.user.id: service.user.id
      } else {
        window.location.href = window.location.origin
      }
    }

    if( params['phone'] ) {
      phone = params['phone']
    } 

    if( (session_key!==null)&&(user_id!=null) ) {
      service.session_key = session_key 
      var _phone = phone.split(":")
      //console.log( _phone )
      if( _phone.length === 2 ) {
        this.setState({
          hasPhone : true,
          phone : phone
        }, ()=>{
          this.getUser( parseInt(user_id, 10) )
        })
      } else {
        this.setState({
          hasPhone : false,
        }, ()=>{
          this.getUser( parseInt(user_id, 10) )
        })
      }

    } else {
      console.log("C")
      //window.location.href = window.location.origin
    }


  }

  componentWillUnmount() {

  }

  async getUser(user_id) {
    const {updatevalue} = this.props
    let user = await service.getUser( user_id )
    service.user = user
    if( user.id ) {
      var obj = {}
      obj['user'] = user
      this.setState({ isLogin : true , user : user}, ()=>{
        updatevalue({
          hasLogin : true,
          user : obj
        })
      })
    }
  }

  async init() {
    let data = await service.fetchAsset("gifts", "card_index.json")
    this.setState({
      card_value : data['card_value'],
      price_value : data['price_value'],
    })
  }

  render() {

    const { i18n, language, hasLogin, showHeader, user, config  } = this.props
    //const {card_value, price_value, hasPhone, phone} = this.state
    const { hasPhone, phone} = this.state

    //const isTopup = !config['topup']


    if( hasLogin && user ) {
      return (
        <div className={["Wallet"].join(" ")} >
          <div className={["Content", !showHeader?"Top":""].join(" ")}> 
                {
                  user && user['user'] && user['user']['wallet'] &&
                  <div className="Holder">
                    <div className="Title"> { i18n["wallet_my_token"][language]  } </div>
                    <div className="Token"> {   user['user']['wallet']['token'].toLocaleString()   } </div>
                  </div>
                }
                {
                  !(user && user['user'] && user['user']['wallet']) &&
                  <div className="Holder">
                    <div className="Title"> { i18n["wallet_my_token"][language]  } </div>
                    <div className="Token"> {   (0).toLocaleString()   } </div>
                  </div>
                }
                {
                  <Link to={"/wallet/topup"} target='new'>
                    <FaPlusCircle className='Fa'/>
                  </Link>
                }
          </div>
          {
            <Gift {...this.props} 
              hasPhone={hasPhone}
              phone={phone}/> 
          }
        </div>
      )
    } else {
      //window.location.href = window.location.origin
      return null
    }
  }
}

export default Wallet;


