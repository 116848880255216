import React, {Component} from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';




class Temp extends Component {
  constructor(props) {
    super(props)
    this.state={

    }
  }
  
  componentDidMount() {
  }

  render() {
    var _c = this.props.content[this.props.selected]
    document.title =  ((this.props.language in _c.display)?(_c.display[this.props.language] + " | "):"") + this.props.config['general']['name'][this.props.language]
    return (
      <div className={["Temp"].join(" ")}>
        <div className={["Text"].join(" ")}> {  this.props.i18n["coming"][this.props.language]  } </div>
      </div>
    )
  }
}

export default Temp;