import React, {Component} from 'react';
import './index.scss';
import service from '../../../public/service';


const board = [
  { key : "email" , value : "email", type : "input" },
  { key : "password" , value : "password", type : "password" },
]

class Login extends Component {
  constructor(props) {
    super(props)
    this.state={
      email : "",
      password : "",
    }
    this.set = this.set.bind(this)
    this.login = this.login.bind(this)
    this.refreshSession = this.refreshSession.bind(this)
  }
  
  componentDidMount() {
    try {
      const user = JSON.parse( window.localStorage.getItem( "@user"  ) )
      const s_key = window.localStorage.getItem( "@user" + user['id']  )
      service.session_key = s_key
      //this.refreshSession()
    } catch(e) {}
  }

  componentWillUnmount() {

  }
  set( key , value ) {
    var obj = {}
    obj[key] = value
    this.setState(obj)
  }

  async refreshSession( ) {
    //refreshSession
    const {  update } = this.props
    let data = await service.refreshSession()
    if( data.session_key ) {
      try {
        service.session_key = data.session_key
        //service.user = data.user
        let user = await service.getUser( data.user['id'] )
        update( {
          isLogin : true,
          user : user
        } )
      } catch(e) {
        service.session_key = null
        service.user = null
      }
    }
  }

  async login() {
    const { email , password } = this.state
    const { i18n, language, update } = this.props
    if( email.length > 0 && password.length >0 ) {
      var obj = { email : email , password : password }
      let data = await service.sessionGet(obj)
      if( data.session_key  ) {
        service.user = data.user
        service.session_key = data.session_key 
        window.localStorage.setItem( "@user", JSON.stringify( data.user )  )
        window.localStorage.setItem( "@user" + data['user']['id'] , data.session_key  )
        update( {
          isLogin : true,
          user : data.user
        } )
      } else {
        alert( `${i18n['invalid_login'][language]}\n${i18n['invalid_login_msg'][language]}`  )  
      }
    } else {
      alert( `${i18n['invalid_login'][language]}\n${i18n['invalid_login_msg'][language]}`  )
    }
    
  }

  render() {

    const { i18n, language } = this.props
    
    return (
      <div className={["U-Login"].join(" ")} >
        <img src={ window.logo } className={["Logo"].join(" ")}/>
        <div className={["Holder"].join(" ")}>
          {
            board.map( (v,k) => {
              return (
                <div className={["Container"].join(" ")} key={k}> 
                  <div className={["Placeholder",  (this.state[ v['key'] ].length > 0)?"Small":"" ].join(" ")}> { i18n[v['key']][language] } </div>
                  <input 
                    type={ v['type'] }
                    className={["Input"].join(" ")} 
                    value={ this.state[ v['key'] ] } 
                    onChange={ (e)=>this.set(v['key'], e.target.value) }
                  />
                </div>
              )
            } )
          }
          <div className="U-Login-Btn" onClick={ ()=>this.login() }>
            { i18n['signin'][language] }
          </div>
        </div>
      </div>
    )
  }
}

export default Login;


