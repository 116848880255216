var service = {
  api : "https://api.lucky7h.com",
  asset : "https://lucky7-lambda.s3.ap-southeast-1.amazonaws.com",
  eshopapi : "https://api-eshop.lucky7h.com",
  foodapi : "https://api-food.lucky7h.com",
  session_key : null,
  otp_session_key : null,
  otp_user_id : null,
  user : null,
  //header_excecptions : ["/user","/wallet"],
  header_excecptions : ["/shop", "/user","/wallet", "/catering"],
  footer_excecptions : ["/shop", "/user","/wallet", "/reservation_terms", "/privacy", "/terms", "/reservation", "/l7_terms", "/l7_privacy", "/gift", "/catering"],
  header_zero_content : ["/catering"],

  "clearAllData" : () => {
    window.localStorage.clear()
  },

  "storeData" : async (key,value) => {
    try {
      var attr = `@`
      const jsonValue = JSON.stringify(value);
      await window.localStorage.setItem( (attr+key), jsonValue);
    } catch (e) {
      await window.localStorage.setItem(key, value);
    }
  },

  "getData" : async (key, placeholder=[]) => {
    try {
      var attr = `@`
      const jsonValue = await window.localStorage.getItem( (attr+key)  );
      if ( jsonValue !== null ) {
        return JSON.parse(jsonValue)
      } else {
        return null
      }
    } catch (e) {
      return placeholder
    }
  },

  fetchFile : (str) => {
    var url = window.location.origin + "/resources/" + str
    return fetch( url ,{
      headers: {
        'Cache-Control': 'no-cache'
      }
    } ).then(
      (res) => res.json()
    )
  },

  fetchAsset : async (key , file) => {
    var url = `${service.asset}/assets/${key}/${file}`
    let res = await fetch( url ,{
      headers: {
        'Cache-Control': 'no-cache'
      }
    })
    try {
      let result = await res.json()
      return await result
    } catch (e) {
      return {}
    }
  },

  getMode: async () => {
    var url = window.location.origin + "/resources/mode.json"
    var res = await fetch( url ,{
      headers: {
        'Cache-Control': 'no-cache'
      }
    } )
    try {
      let result = await res.json()
      return await result
    } catch (e) {
      return []
    }
  },

  getConfig : async () => {
    var url = `${window.location.origin}/resources/config.json`
    var res = await fetch( url ,{
      headers: {
        'Cache-Control': 'no-cache'
      }
    } )
    try {
      let result = await res.json()
      return await result
    } catch (e) {
      return []
    }
  },

  getContent : async () => {
    var url = window.location.origin + "/resources/pages.json"
    /*
    return fetch( url ,{
      headers: {
        'Cache-Control': 'no-cache'
      }
    } ).then(
      (res) => res.json()
    )*/
    var res = await fetch( url ,{
      headers: {
        'Cache-Control': 'no-cache'
      }
    } )
    try {
      let result = await res.json()
      return await result
    } catch (e) {
      return []
    }
  },

  getCommonLanguages : async () => {
    var url = window.location.origin + "/resources/i18n.json"
    /*
    return fetch( url ,{
      headers: {
        'Cache-Control': 'no-cache'
      }
    } ).then(
      (res) => res.json()
    )
    */
    var res = await fetch( url ,{
      headers: {
        'Cache-Control': 'no-cache'
      }
    } )
    try {
      let result = await res.json()
      return await result
    } catch (e) {
      return []
    }
  },

  getFBAcc : (page, token) => {
    var url = "https://graph.facebook.com/" + page + "?fields=access_token&access_token="+token
    return fetch( url ,{
      headers: {
        'Cache-Control': 'no-cache'
      }
    } ).then(
      (res) => res.json()
    )    
  },
  
  getPostContent : (page, token) => {
    //from = from?("from="+from):+""
    var url = "https://graph.facebook.com/" + page + "?fields=posts{id,full_picture,message,created_time,permalink_url}&access_token="+token
    return fetch( url ,{
      headers: {
        'Cache-Control': 'no-cache'
      }
    } ).then(
      (res) => res.json()
    ) 
  },

  getBrands : async () => {
    var url = service.api + "/catering/brand?include_ui=true&enabled=true&include_shops=true"
    var res = await fetch( url )
    try {
      let result = await res.json()
      return await result
    } catch (e) {
      return []
    }

  },

  getConfig2 : async (mode="dev") => {
    //var url = `${service.asset}/assets/app/${ (mode==="dev"?"config_dev.json":"config.json") }`
    var url = `${window.location.origin}/resources/config.json`
    var res = await fetch( url ,{
      headers: {
        'Cache-Control': 'no-cache'
      }
    } )
    try {
      let result = await res.json()
      return await result
    } catch (e) {
      return []
    }

  },

  massageBookingBrand : (brands) => {
    var _b = []
    for (var b of JSON.parse( JSON.stringify(brands)) ) {
      var _s = []
      if(b['shop'] && b['shop'].length > 0) {
        for ( var s of b['shop'] ) {
          if( s['reservation_setting'] && s['reservation_setting']['open'] ) {
            _s.push( s )
          }
        }
        if( _s.length > 0 ) {
          b['shop'] = _s
          _b.push( b )
        }
      }
    }
    return _b
  },

  tableVacancyGet : async (shop_id, reservation_date) => {
    //tableVacancyGet
    //file:///reservation/shop/{shop_id}/vacancy/{reservation_date}
    const url = `${service.api}/reservation/shop/${shop_id}/vacancy/${reservation_date}`
    try {
      let res = await fetch( url , {
        method: 'GET',
        headers: {},
      } )
      let result = await res.json()
      return await result
    } catch (e) {
      return []
    }
  },

  paymentPrepare : async (obj) => {
    const url = `${service.api}/fiserv/payment/prepare`
    var res = await fetch( url , {
      method: 'POST',
      headers: {},
      body : JSON.stringify( obj )
    } )
    try {
      let result = await res.json()
      return await result
    } catch (e) {
      return {}
    }

  },

  sessionGet : async (obj) => {
    var url = service.api + "/user/session"
    let res = await fetch( 
      url,
      {
        method: 'POST',
        headers: {
          //'Cache-Control': 'no-cache',
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body : JSON.stringify( obj )
      }
    )
    try {
      let result = await res.json()
      service.session_key = result.session_key
      console.log('HERE?')
      return await result
    } catch (e) {
      return {}
    }

  },

  refreshSession : async () => {
    var url = service.api + "/user/session/refresh"
    //console.log( service.session_key )
    let res = await fetch( 
      url,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization' : 'Bearer ' + service.session_key
        },
      }
    )
    try {
      let result = await res.json()
      return await result
    } catch (e) {
      return {}
    }

  },

  createUser : async (obj) => {
    var url = `${service.api}/user`
    let res = await fetch( 
      url,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body : JSON.stringify( obj )
      }
    )
    try {
      let result = await res.json()
      return await result
    } catch (e) {
      return {}
    }
  },

  otpSend : async (user_id, by_email=true) => {
    var url = `${service.api}/user/${user_id}/otp`
    var obj = { by_email : by_email }
    let res = await fetch( 
      url,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization' : 'Bearer ' + service.otp_session_key
        },
        body : JSON.stringify( obj )
      }
    )
    try {
      return await res
    } catch (e) {
      return {}
    }
  },

  userRegister : async (user_id, otp) => {
    var url = `${service.api}/user/${user_id}/register`
    var obj = {
      otp : otp
    }
    let res = await fetch( 
      url,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization' : 'Bearer ' + service.otp_session_key
        },
        body : JSON.stringify( obj )
      }
    )
    try {
      return await res
    } catch (e) {
      return {}
    }
  },

  getUser : async (user_id) => {
    var url = service.api + "/user/" + user_id + "?include_point=true&include_spending=true&include_wallet=true"
    let res = await fetch( 
      url,
      {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization' : 'Bearer ' + service.session_key
        },
      }
    )
    try {
      let result = await res.json()
      return await result
    } catch (e) {
      return {}
    }

  },

  purchaseCreate : async (user_id, offer_id) => {
    // /wallet/user/{user_id}/purchase
    var url = service.api + "/wallet/user/" + user_id + "/purchase"
    var obj = { offer_id : offer_id }
    let res = await fetch( 
      url,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization' : 'Bearer ' + service.session_key
        },
        body : JSON.stringify( obj )
      }
    )
    try {
      let result = await res.json()
      return await result
    } catch (e) {
      return {}
    }
  },

  reservationCreate : async (obj) => {
    var url = service.api + "/reservation/reservation"
    let res = await fetch( 
      url,
      {
        method: 'POST',
        headers: {
          //'Cache-Control': 'no-cache',
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body : JSON.stringify( obj )
      }
    )
    try {
      let result = await res.json()
      return await result
    } catch (e) {
      return {}
    }
  },

  purchaseSearch : async (user_id, offset=0) => {
    var url = `${service.api}/user/${user_id}/spending?include_count=true&offet=${offset}` 
    let res = await fetch( 
      url,
      {
        method: 'GET',
        headers: {
          //'Cache-Control': 'no-cache',
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          "Authorization" : `Bearer ${service.session_key}`
        },
      }
    )
    try {
      //response.headers.get("X-Total-Count")
      let result = await res.json()
      return await { data : result , count : parseInt(res.headers.get("X-Total-Count"), 10), page : offset }
    } catch (e) {
      return {}
    }
  },

  pointTransaction : async (user_id, offset=0) => {
    //file:///user/{user_id}/pointtransaction
    var url = `${service.api}/user/${user_id}/pointtransaction?include_count=true&offet=${offset}` 
    let res = await fetch( 
      url,
      {
        method: 'GET',
        headers: {
          //'Cache-Control': 'no-cache',
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          "Authorization" : `Bearer ${service.session_key}`
        },
      }
    )
    try {
      //response.headers.get("X-Total-Count")
      let result = await res.json()
      return await { data : result , count : parseInt(res.headers.get("X-Total-Count"), 10), page : offset }
    } catch (e) {
      return {}
    }
  },

  getUnusable : async (user_id, offset=0) => {
    //file:///user/{user_id}/pointtransaction
    var url = `${service.api}/user/${user_id}/coupon?include_count=true&offet=${offset}&include_promo=true&usable=false` 
    let res = await fetch( 
      url,
      {
        method: 'GET',
        headers: {
          //'Cache-Control': 'no-cache',
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          "Authorization" : `Bearer ${service.session_key}`
        },
      }
    )
    try {
      //response.headers.get("X-Total-Count")
      let result = await res.json()
      return await { data : result , count : parseInt(res.headers.get("X-Total-Count"), 10), page : offset }
    } catch (e) {
      return {}
    }
  },

  getWalletOffer : async () => {
    var url = service.api + "/wallet/offer"
    let res = await fetch( 
      url,
      {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization' : 'Bearer ' + service.session_key
        }
      }
    )
    try {
      let result = await res.json()
      return await result
    } catch (e) {
      return {}
    }
  },

  getBooking : async (user_id) => {

    var today =  new Date()
    var reservation_date_max = service.formatDate( today, false )
    var reservation_date_min = service.timeDiff( reservation_date_max, -30*24*60 , false  )

    var url = `${service.api}/reservation/reservation?user_id=${user_id}&include_deleted=false&reservation_date_max=${reservation_date_max}&reservation_date_min=${reservation_date_min}`
    let res = await fetch( 
      url,
      {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization' : 'Bearer ' + service.session_key
        },
      }
    )
    try {
      let result = await res.json()
      return await result
    } catch (e) {
      return {}
    }
  },

  getShopItems : async (page=1, limit=18) => {
    var url = `${service.api}/catering/news?offset=${page-1}&include_count=true&limit=${limit}`
    var headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }
    if( service.session_key ) {
      headers['Authorization'] = 'Bearer ' + service.session_key
    }
    let res = await fetch( 
      url,
      {
        method: 'GET',
        headers: headers
      }
    )
    try {
      let result = await res.json()      
      return await { "list" : result, "count" : res.headers.get("X-Total-Count") }
    } catch (e) {
      return {}
    }
  },

  getShopItemById : async (item_id) => {
    var url = `${service.api}/catering/news/${item_id}`
    var headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }
    if( service.session_key ) {
      headers['Authorization'] = 'Bearer ' + service.session_key
    }
    let res = await fetch( 
      url,
      {
        method: 'GET',
        headers: headers
      }
    )
    try {
      let result = await res.json()      
      return await result
    } catch (e) {
      return {}
    }
  },

  //ESHOP
  getEshopItems : async (offset=0, limit=100) => {
    var url = `${service.eshopapi}/eshop/item?offset=${offset}&show_ui=true&include_count=true&include_disabled=false&limit=${limit}&include_remaining=true`
    var headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }
    if( service.session_key ) {
      headers['Authorization'] = 'Bearer ' + service.session_key
    }
    let res = await fetch( 
      url,
      {
        method: 'GET',
        headers: headers
      }
    )
    try {
      let result = await res.json()  
      //console.log(  result )    
      //return await result
      return await { "list" : result, "count" : parseInt(res.headers.get("X-Total-Count"), 10) }
    } catch (e) {
      return {}
    }
  }, 

  getEShopItemById : async (item_id) => {
    var url = `${service.eshopapi}/eshop/item/${item_id}?include_ui=true`
    var headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }
    if( service.session_key ) {
      headers['Authorization'] = 'Bearer ' + service.session_key
    }
    let res = await fetch( 
      url,
      {
        method: 'GET',
        headers: headers
      }
    )
    try {
      let result = await res.json()      
      return await result
    } catch (e) {
      return {}
    }
  },

  purchaseCreateEshop : async ( obj ) => {
    var url = `${service.eshopapi}/eshop/purchase`
    var headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }
    if( service.session_key ) {
      headers['Authorization'] = 'Bearer ' + service.session_key
    }
    let res = await fetch( 
      url,
      {
        method: 'POST',
        headers: headers,
        body : JSON.stringify( obj )
      },
    )
    try {
      let result = await res.json()      
      return await result
    } catch (e) {
      return {}
    }
  },

  //TAKEOUT
  getFoodItems : async (shop_code) => {
      const shopCodes = typeof(shop_code)==="string"?shop_code:shop_code.join(",")
      var url = `${service.foodapi}/food/menu2?shop_code=${ shopCodes }`
      var headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
      if( service.session_key ) {
        headers['Authorization'] = 'Bearer ' + service.session_key
      }
      let res = await fetch( 
        url,
        {
          method: 'GET',
          headers: headers
        }
      )
      try {
        let result = await res.json()  
        //console.log(  result )    
        return await result
        //return await { "list" : result }
      } catch (e) {
        return {}
      }
  }, 
  
  getFoodItemById : async (item_id) => {
      var url = `${service.eshopapi}/eshop/item/${item_id}?include_ui=true`
      var headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
      if( service.session_key ) {
        headers['Authorization'] = 'Bearer ' + service.session_key
      }
      let res = await fetch( 
        url,
        {
          method: 'GET',
          headers: headers
        }
      )
      try {
        let result = await res.json()      
        return await result
      } catch (e) {
        return {}
      }
  },

  purchaseCreateTogo : async ( obj ) => {
    var url = `${service.foodapi}/food/purchase`
    var headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }
    if( service.session_key ) {
      headers['Authorization'] = 'Bearer ' + service.session_key
    }
    let res = await fetch( 
      url,
      {
        method: 'POST',
        headers: headers,
        body : JSON.stringify( obj )
      },
    )
    try {
      let result = await res.json()      
      return await result
    } catch (e) {
      return {}
    }
  },

  findOrder :  async() => {
    var url = `${service.api}/wallet/purchase?limit=500&user_id=${service.user.id}`
    var headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }
    if( service.session_key ) {
      headers['Authorization'] = 'Bearer ' + service.session_key
    }
    let res = await fetch( 
      url,
      {
        method: 'GET',
        headers: headers
      },
    )
    try {
      let result = await res.json()      
      return await result
    } catch (e) {
      return {}
    }
  },

  

  //TAKEOUT
  userfind : async (phone) => {
    var url = `${service.api}/find/user?phone=${phone}`
    var headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }
    headers['Authorization'] = 'Bearer ' + service.session_key
    let res = await fetch( 
      url,
      {
        method: 'GET',
        headers: headers
      },
    )
    try {
      let result = await res.json()      
      return await result
    } catch (e) {
      return {}
    }
  },

  treatAMeal : async (obj) => {
    const user_id = service.user.id
    if( user_id ) {
      var url = `${service.api}/wallet/user/${user_id}/treatameal`
      var headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization' : 'Bearer ' + service.session_key
      }

      let res = await fetch( 
        url,
        {
          method: 'POST',
          headers: headers,
          body : JSON.stringify( obj )
        },
      )
      try {
        let result = await res.json()      
        return await result
      } catch (e) {
        return {}
      }
    } else {
      return {}
    }
  },

  /*LOCAL FUNCTION*/
  formatDate : (date, ymdhms=true) => {
    const padTo2Digits = (num) => {
      return num.toString().padStart(2, '0');
    }

    return (
      [
        date.getFullYear(),
        padTo2Digits(date.getMonth() + 1),
        padTo2Digits(date.getDate()),
      ].join('-') +
      (!ymdhms?"":
      (' ' +
      [
        padTo2Digits(date.getHours()),
        padTo2Digits(date.getMinutes()),
        padTo2Digits(date.getSeconds()),
      ].join(':')
      ))
    );
  },

  timeDiff : (str, diff, ymdhms=true) => {
    /* diff is in minute */
    var date = new Date(str)
    date = date.getTime() + diff * 60 * 1000
    date = new Date( date )
    return service.formatDate(date, ymdhms)
  },

  validateNum : (z) => {
    try {
      return (z.match(/^\d+$/)||z.length===0)?true:false
    } catch(e) {
      return false
    }

  },

  validateEmail : (z) => {
    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return z.match(validRegex)
  },

  rebrand : (brands, id=false)=> {
    const _brands = JSON.parse( JSON.stringify(brands) )
    var o = {}
    for (var b of _brands ) {
      if( b['shop'].length > 0 ) {
        for (var s of b['shop']) {
          s['brand'] = b
          if( id ) {
            o[ s[ "id" ] ] = s
          } else {
            o[ s['shop_code'] ] = s
          }
        }
      }
    }
    return o
  },

  getParamQuery : () => {
    var params = {}
    if( window.location.search.length > 0 ) {
      try {
        const str = window.location.search.substring( 1, window.location.search.length )
        const arr = str.split("&")
        //var session_key = null, user_id = null, redirect = false
        for( var s of arr) {
          const _a = s.split("=")
          if( _a.length === 2 ) {
            params[ _a[0] ] = _a[1]
          } else {
            params[ _a[0] ] = s.replace( _a[0]+"=", "" )
          }
        }
        return params
      } catch(e) {
        return params
      }
    } else {
      return params
    }
  },

  massageAsset : (b) =>{
    for ( var i in b ) {
      var ba = {}
      for (var j in b[i].asset) {
        ba[  b[i].asset[j].name ] = b[i].asset[j]
      }
      b[i].asset  = ba
      if( "shop" in b[i] ) {
        var _s = b[i].shop
        _s = _s.filter((v)=>v.enabled===true )
        for ( var k in _s){
          var _sa = {}
          for (var j in _s[k].asset) {
            _sa[  _s[k].asset[j].name ] = _s[k].asset[j]
          }
          _s[k].asset = _sa
        }
        b[i].shop = _s
      }
    }
    return b
  },

  massageAsset2 : (v1) =>{
    var v = JSON.parse( JSON.stringify(v1) )
    if( v.asset ) {
      var ba = {}
      for (var j in v.asset) {
        ba[  v.asset[j].name ] = v.asset[j]
      }
      v.asset  = ba
      return v
    } else {
      return v
    }
  },

  pickupShops : (brands, shopcodes) => {
    var shops = []
    for (var b of brands) {
      if( b['enabled'] ) {
        for (var s of b['shop'] ) {
          if( s['enabled'] ) {
            if( (!shopcodes) || (shopcodes && (shopcodes.includes(s['shop_code'])))) {
              var _s = JSON.parse(  JSON.stringify(s) )
              _s['brand'] = b
              shops.push( _s )
            }
          }
        }
      }

    }
    return shops
  },

  generateHashId : () => {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  },

  encode : (str) => {
    try {
      return window.btoa( str )
    } catch(e) {
      return ""
    }
  },

  decode : (code) => {
    try {
      return window.atob(code)
    } catch(e) {
      return ""
    }
  },

  encodeChar : (str) => {
    try {
      return window.btoa( encodeURI(str) )
    } catch(e) {
      return ""
    }
  },

  decodeChar : (code) => {
    try {
      return decodeURI(window.atob(code))
    } catch(e) {
      return ""
    }
  },

  //GET IMAGE LOCATIO

  assetImage : (name) => {
    return `${service.asset}/${name}`
  }

}

export default service;
