import React, {Component} from 'react';
import './index.scss';
import service from '../../public/service';

class Payment extends Component {
  constructor(props) {
    super(props)
    this.state={
      records : {},
      error : {},
      mode : "prod"
    }

    this.get = this.get.bind(this)
  }
  
  componentDidMount() {
    const { config } = this.props
    var params = {}, r_id = null, p_id = null, chargetotal = null, mode=null
    var _params = window.location.search.replace("?" , "")
    _params = _params.split("&")
    for (var i in _params) {
      var _s = _params[i].split("=")
      params[ _s[0] ]= _s[1]
    }

    if( "reservation_id" in params ) {
      r_id = parseInt(params["reservation_id"], 10)
    }

    if( "purchase_id" in params ) {
      p_id = parseInt(params["purchase_id"], 10)
    }
    
    //TEMP//
    
    if( "mode" in params ) {
        mode = params["mode"]
    } else {
        mode = (config['mode']=="debug")?"dev":"default"
    }
    
    //mode = "default"

    //if( "chargetotal" in params ) {
    //  chargetotal = parseInt(params["chargetotal"], 10)
    //}

    //if( ( r_id > 0 || p_id > 0 )&& chargetotal > 0 ) {
    if( ( r_id > 0 || p_id > 0 ) ) {
      this.get(r_id, p_id, chargetotal, mode)
    } else {
      //console.log("HERE?")
      window.location.href = window.location.origin
    }
    
  }

  componentWillUnmount() {

  }

  async get(r_id, p_id, chargetotal, mode) {
    const { language } = this.props
    var obj = {
      mode : (mode==="dev")?"dev":"default" ,
      //mode : "default" ,
      chargetotal : chargetotal,
      language : language,
      //purchase_id : r_id,
      //reservation_id : r_id,
      responseFailURL : `${window.location.origin}/reservation/fail`,
      responseSuccessURL : `${window.location.origin}/reservation/success`
    }

    if( r_id ) {
      obj['reservation_id'] = r_id
    } 

    if( p_id ) {
      obj['purchase_id'] = p_id
    } 

    if(mode) {
      obj['mode'] = (mode==="prod")?"default":mode
    }

    let data = await service.paymentPrepare(obj)
    if( data["code"] ) {
      this.setState({
        error : data,
        records : null
      })
    } else {
      this.setState({
        records : data
      })
    }
    
  }

  render() {
    const {i18n, language, config} = this.props
    const {records, error, mode} = this.state
    //const connect_url =

    try {
      //const connect_url = mode==="dev"?"https://test.ipg-online.com/connect/gateway/processing":records['connect_url']
      const connect_url = records['connect_url']

      if( Object.keys(records).length > 0 ) {
        return (
          <div className={["Payment"].join(" ")}  >

            {
              config['mode']==="debug" && true==false &&
              <div className={["Test-Btn"].join(" ")}>{`SWITCH MODE - ${  
                records['mode']    }`}</div>
            }


            <div className={["Wrapper"].join(" ")}>
              <div className={["Title"].join(" ")}>
                {
                  config['general']['name'][language]
                }
              </div>
              <div className={["Statements"].join(" ")}>
                
                <div className={["Warning"].join(" ")}> { i18n["payment_msg_1"][language] } </div>
                <div className={["Warning"].join(" ")}> {
                i18n["payment_msg_2"][language] 
                } </div>

                <div className={["Statement"].join(" ")}> { i18n["payment_notify"][language]  } </div>
                <div className={["Statement"].join(" ")}> { i18n["payment_subtotal"][language]  } </div>
              </div>
              <form method="post" 
                onSubmit={
                  (e)=>{
                    const a = window.confirm( i18n['bk_success_alert'][language] )
                    if( a === false ) {
                      e.preventDefault();
                    }
                  }
                }
                action={ connect_url } className={['Holder'].join(" ")}>
                  {
                      Object.keys(records).map( (v,k) => {
                        return (
                          <input 
                            className={['Input'].join(" ")}
                            readOnly={true}
                            onChange={null}
                            type={(v === "chargetotal" || v === "total")?"text":"hidden"} name={v} value={
                              records[v]
                            } key={k}/>
                        )
                      } )
                  }

                  <input 
                    onClick={()=>{
                      //Please don't close the window until seeing the page of payment success!
                      //bk_success_alert
                      //alert(i18n['bk_success_alert'][language])
                    }}
                    type="submit" value={i18n["agree"][language]} className={['Agree'].join(" ")}/>
              </form>
            </div>
                  
          </div>
        )
      } else {
        return null
      }
    } catch(e) {
      return (
        <div className='Payment'>
          <div className='Wrapper'>
            <div className={['Title'].join(" ")}> 
              { 
                error['msg'].toUpperCase()
              } 
            </div>
          </div>
        </div>
      )
    }
  }
}

export default Payment;


