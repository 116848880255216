import React, {Component} from 'react';
import './index.scss';
import service from '../../../public/service';
import { Link } from 'react-router-dom'
import {FaTimes, FaPlus, FaMinus, FaCartPlus, FaChevronRight, FaChevronLeft} from "react-icons/fa"
import { addDays, getDay, subDays } from "date-fns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class Detail extends Component {
  constructor(props) {
    super(props)
    this.state={
      tab : "detail",
      quantity : 1,
      clicked : false,
      showGallery : false,
      gIndex : 0,
      shops : [],
      shop : "",
      //date : new Date(),
      date : null,
      isBuy : true,
      picked_options : {},
      picked_selections : {},
      error_options : {},
      error_selections : {},
      error_selections_options : {},
      includeDates : []
    }
    this.init = this.init.bind(this)
    this.updateOrder = this.updateOrder.bind(this) 
    this.placeOrder = this.placeOrder.bind(this)
    this.getI18n = this.getI18n.bind(this)
    this.changeOption = this.changeOption.bind(this)
    this.changeSelection = this.changeSelection.bind(this)
    this.changeSelectionOption = this.changeSelectionOption.bind(this)

    this.renderSelection = this.renderSelection.bind(this)
    this.addAmounts = this.addAmounts.bind(this)
  }
  
  componentDidMount() {
    const { selectedItem, selectedShop } = this.props
    var obj = {}
    if( selectedShop ) {
      obj["selectedShop"] = selectedShop
    }
    if( selectedItem ) {
      obj["picked_options"] = selectedItem['picked_options']
      obj["picked_selections"] = selectedItem['picked_selections']
      obj["quantity"] = selectedItem['quantity']
    }
    if( Object.keys( obj ).length > 0 ) {
      this.setState( obj )
    }
  }

  componentWillUnmount() {
    this.init()
  }

  componentDidUpdate(props, state) {
    //console.log( props.selectedItem )
    //if( props.selectedItem  !== this.props.selectedItem ) {
    //  console.log( props.selectedItem )
    //}
  }

  init() {

    const {selected} = this.props

    this.setState({
      tab : "detail",
      quantity : 1,
      clicked : false,
      showGallery : false,
      gIndex : 0,
      //shops : shops,
      brand : null,
      shop : "",
      shop_code : null,
      date : null,
      isBuy : true,

      error_selections : {},
      error_options : {},
      error_selections_options : {},

      picked_options : {},
      picked_selections : {},
    })

  }

  placeOrder() {
    const {quantity,  picked_options, picked_selections} = this.state
    const {selected, brands, updateParent, i18n, language, hasLogin, updatevalue, menuorder, selectedShop, placeMenuOrder } = this.props
    
    //console.log( picked_options )
    //console.log( picked_selections )
    //console.log( selected )
    //console.log( quantity )

    var error_options = {}, error_selections = {}, error_selections_options={}, add_amount = 0

    
    //CHECK OPTIONS
    const { tier_pricing, option_groups, selection_groups } = selected
    

    var valid = !tier_pricing || (tier_pricing && hasLogin)


    if( valid ) {
      var options = JSON.parse(  JSON.stringify(option_groups) ) 
      
      //CEHCK OPTIONS
      if( options ) {
        for (var o of options) {
            const p = picked_options[ o['id'] ]
            if( !p ) {
              if ( o['select_min'] > 0 ) {
                error_options[ o["id"] ] = true
              } 
            } else {
              if ( o['select_min'] > p['options'].length ) {
                error_options[ o["id"] ] = true
              }
              for (var u of p['options']) {
                add_amount += u['add_amount']
              }
            }
            
        }
      }

      //CEHCK SELCTIONS
      for (var idx in selection_groups) {
        var ps = []
        const s = selection_groups[idx]
        if( idx in picked_selections ) {
          ps = picked_selections[idx]['selections']
        }
        if(  (s['select_min'] > ps.length) || (s['select_max'] < ps.length)  ) {
          error_selections[idx] = true
        } else {
          for (var i of ps) {
            add_amount += i["add_amount"]
          }
        }

        if( ps.length > 0 ) {
          for (var ss of ps) {
            var s_option = ss["options"]?ss["options"]:{}
            if( ss['menuitem'] ) {
              if ( ss['menuitem']['option_groups'] ) {

                for ( var sss of ss['menuitem']['option_groups'] ) {
                  const ss_options = s_option[sss['id']]?s_option[sss['id']]:[]
                  if(  (sss['select_min'] > ss_options.length) || (sss['select_max'] < ss_options.length)  ) {
                  //if(  (1 > ss_options.length) || (sss['select_max'] < ss_options.length)  ) {
                    error_selections_options[ `${idx}_${sss['id']}` ] = true

                  } else {
                    for (var i of ss_options) {
                      add_amount += i["add_amount"]
                    }
                  }
                }
              }
            }

          }
        }


      }



      if( ( Object.keys(  error_options ).length > 0 ) || ( Object.keys(  error_selections ).length > 0 ) || ( Object.keys(  error_selections_options ).length > 0 ) ) {
        this.setState({
          error_options : error_options,
          error_selections : error_selections,
          error_selections_options :error_selections_options
        }, ()=>{
          alert( this.getI18n('to_entitle_placed_error')  )
        })
        return
      } 


      if( (quantity > 0)  ) {

        var _this = this
        this.setState({
          //clicked : true,
          error_options : error_options,
          error_selections : error_selections,
          error_selections_options :error_selections_options
        }, async ()=> {
          
          //console.log( selectedShop )
          //console.log( menuorder )

          placeMenuOrder(
            {
              "item" : selected,
              "add_amount" : add_amount,
              "picked_options" : picked_options,
              "picked_selections" : picked_selections,
              "quantity" : quantity
            },
            selectedShop,
            () => {
              updateParent({ selected : null }, ()=>{
                this.init()
              })
            }
          )
          


          


        })
      } else {
        alert(i18n['es_placed_error'][language])
      }
    } else {
      const a = window.confirm(i18n['required_login'][language])
      if(a) {
        updatevalue({
          showLogin : true
        })
      }
    }

  }

  updateOrder() {
    console.log("UPDATE")
    const {quantity,  picked_options, picked_selections} = this.state
    const {selected, brands, updateParent, i18n, language, hasLogin, updatevalue, menuorder, selectedShop, placeMenuOrder, updateMenuOrder, selectedItem } = this.props
    
    var error_options = {}, error_selections = {}, error_selections_options={}, add_amount = 0
    
    //CHECK OPTIONS
    const { tier_pricing, option_groups, selection_groups } = selected
    

    var valid = !tier_pricing || (tier_pricing && hasLogin)


    if( valid ) {
      var options = JSON.parse(  JSON.stringify(option_groups) ) 
      
      //CEHCK OPTIONS
      if( options ) {
        for (var o of options) {
            const p = picked_options[ o['id'] ]
            if( !p ) {
              if ( o['select_min'] > 0 ) {
                error_options[ o["id"] ] = true
              } 
            } else {
              if ( o['select_min'] > p['options'].length ) {
                error_options[ o["id"] ] = true
              }
              for (var u of p['options']) {
                add_amount += u['add_amount']
              }
            }
            
        }
      }

      //CEHCK SELCTIONS
      for (var idx in selection_groups) {
        var ps = []
        const s = selection_groups[idx]
        if( idx in picked_selections ) {
          ps = picked_selections[idx]['selections']
        }
        if(  (s['select_min'] > ps.length) || (s['select_max'] < ps.length)  ) {
          error_selections[idx] = true
        } else {
          for (var i of ps) {
            add_amount += i["add_amount"]
          }
        }

        if( ps.length > 0 ) {
          for (var ss of ps) {
            var s_option = ss["options"]?ss["options"]:{}
            if( ss['menuitem'] ) {
              if ( ss['menuitem']['option_groups'] ) {

                for ( var sss of ss['menuitem']['option_groups'] ) {
                  const ss_options = s_option[sss['id']]?s_option[sss['id']]:[]
                  if(  (sss['select_min'] > ss_options.length) || (sss['select_max'] < ss_options.length)  ) {
                  //if(  (1 > ss_options.length) || (sss['select_max'] < ss_options.length)  ) {
                    error_selections_options[ `${idx}_${sss['id']}` ] = true

                  } else {
                    for (var i of ss_options) {
                      add_amount += i["add_amount"]
                    }
                  }
                }
              }
            }

          }
        }


      }

      if( ( Object.keys(  error_options ).length > 0 ) || ( Object.keys(  error_selections ).length > 0 ) || ( Object.keys(  error_selections_options ).length > 0 ) ) {
        this.setState({
          error_options : error_options,
          error_selections : error_selections,
          error_selections_options :error_selections_options
        }, ()=>{
          alert( this.getI18n('to_entitle_placed_error')  )
        })
        return
      } 


      if( (quantity > 0)  ) {

        var _this = this
        this.setState({
          //clicked : true,
          error_options : error_options,
          error_selections : error_selections,
          error_selections_options :error_selections_options
        }, async ()=> {
          try {
            menuorder[selectedShop][selectedItem['index']] = {
              "item" : selected,
              "add_amount" : add_amount,
              "picked_options" : picked_options,
              "picked_selections" : picked_selections,
              "quantity" : quantity
            }
            updateMenuOrder(
              menuorder,
              () => {
                updateParent({ 
                  selected : null,
                  selectedItem : null,
                  selectedShop : null,
                  showPopup : false
                }, ()=>{
                  this.init()
                })
              }
            )
          } catch(e){

          }

          /*
          placeMenuOrder(
            {
              "item" : selected,
              "add_amount" : add_amount,
              "picked_options" : picked_options,
              "picked_selections" : picked_selections,
              "quantity" : quantity
            },
            selectedShop,
            () => {
              updateParent({ selected : null }, ()=>{
                this.init()
              })
            }
          )
          */
        })
      } else {
        alert(i18n['es_placed_error'][language])
      }
    } else {
      const a = window.confirm(i18n['required_login'][language])
      if(a) {
        updatevalue({
          showLogin : true
        })
      }
    }

  }

  getI18n(key) {
    const { i18n, language } = this.props
    return ( key in i18n )?i18n[key][language]:key
  }

  addAmounts() {
    var add_amount = 0
    const { picked_options, picked_selections } = this.state
    const { hasLogin, selected } = this.props
    const { tier_pricing } = service.massageAsset2( selected )

    if(picked_options) {
      for (var o of Object.values(picked_options) ) {
        for (var b of o['options']) {
          var valid = ((hasLogin && tier_pricing) || hasLogin || (!tier_pricing) )
          if(valid) {
            add_amount += b['add_amount']
          }
        }
      }
    }

    if( picked_selections ) {
      for (var s of Object.values(picked_selections) ) {
        for (var b of s['selections']) {
          add_amount += b['add_amount']
          if( "options" in b ) {
            for (var o of Object.values( b['options'] ) ) {
              for ( var oo of o ) {
                add_amount += oo['add_amount']
              }
            }
          }
        }
      }
    }
    return add_amount
  }

  changeOption(e, value, original_option) {
    var {picked_options} = this.state
    const {i18n, language} = this.props
    if( original_option['id'] in picked_options ) {
      var op = picked_options[original_option['id']]['options'].filter( (v) => v['id'] === value['id'] )
      
      if( op.length === 0 ) {
        if( original_option['select_max'] > picked_options[original_option['id']]['options'].length ) {
          picked_options[original_option['id']]['options'].push( value )
        } else {
          picked_options[original_option['id']]['options'].splice(0, 1)
          picked_options[original_option['id']]['options'].push( value )
        }
      } else {        
        var nop = picked_options[original_option['id']]['options'].filter( (v) => v['id'] !== value['id'] )
        picked_options[original_option['id']]['options'] = nop


      }
    } else {
      picked_options[original_option['id']] = JSON.parse( JSON.stringify( original_option ) )
      picked_options[original_option['id']]['options'] = []
      picked_options[original_option['id']]['options'].push( value )
    }
    this.setState({
      picked_options : picked_options
    })
  }

  changeSelection( e, value, orginal_selection, index ) {
    var {  picked_selections  } = this.state
    //const { id } = orginal_selection
    const id = index
    
    if( id in picked_selections ) {
      var o = picked_selections[id]["selections"].filter( (v)=>v['item_code'] === e.target.value )
      value = JSON.parse( JSON.stringify(value) )
      value["options"] = {}

      if( o.length === 0 ) {
        if(  orginal_selection['select_max'] > picked_selections[id]["selections"].length   ) {
          picked_selections[id]["selections"].push(  value )
        } else {
          picked_selections[id]["selections"].splice(0,1)
          picked_selections[id]["selections"].push(  value )
        }
      } else {
        picked_selections[id]["selections"] = picked_selections[id]["selections"].filter( (v)=>v['item_code'] !== e.target.value )
      }

    } else {
      if(  orginal_selection['select_min'] >= 0 && orginal_selection['select_max'] > 0  ) {
        picked_selections[id] = { "selections" : [] }
        picked_selections[id]["selections"].push(  value )
      }
    }
    this.setState({ picked_selections : picked_selections })

  }

  changeSelectionOption(  id, value, original_options, item_code, index ) {
    var {  picked_selections  } = this.state
    if( id in picked_selections ) {
      var og = picked_selections[id]['selections'].filter( (v)=>v['item_code'] === item_code )
      if( og.length === 1 ) {
        picked_selections[id]['selections'] = picked_selections[id]['selections'].filter( (v)=>v['item_code'] !== item_code )
        var o = og[0]

        if ( ! ("options" in o) ) {
          o["options"] = {}
        } 
        var oid = original_options["id"]
        if(  oid in o["options"]  ) {

          const gno = o["options"][oid].filter( (v) => v['id'] === value['id'] )
          if( gno.length > 0 ) {
            //HAS SELECTED
            o["options"][oid] = o["options"][oid].filter( (v) => v['id'] !== value['id'] )
          } else {
            //HAS NOT SELECTED
            if ( original_options['select_max'] > o["options"][oid].length ) {
              o["options"][oid].push( value )
            } else {
              o["options"][oid].splice(0, 1)
              o["options"][oid].push( value )
            }
          }

        } else {
          //var noo = JSON.parse(  JSON.stringify( original_options ))
          //noo["selected_options"] = []
          //var noo = {"options" : []}
          var noo = []
          if( original_options['select_max'] > 0 && original_options['select_min'] >=0 ) {
            //noo["selected_options"].push( value )
            noo.push( value )
          }
          //o["options"][oid] = noo
          o["options"][oid] = noo

        }
        picked_selections[id]['selections'].push(o)
        this.setState({ picked_selections : picked_selections })
      }

    }
   
  }

  renderSelection() {
    const { i18n, language, selected } = this.props
    const { picked_selections, error_selections, error_selections_options } = this.state
    const { selection_groups } = service.massageAsset2( selected )
    return (
      <div className={["D-Selections"]}>
        {
          selection_groups.map( (v,k) => {
            const {id} = v
            const rng = (v['select_min']!==v['select_max'])?` ${v['select_min']} - ${v['select_max']} `:` ${v['select_max']} `
            return (
              <div key={k} className={["D-Selection"]}>  
                <div className={["D-S-Title" , (k in error_selections)?"Error":""  ].join(" ") }>    { `${this.getI18n("to_entitle_selection")} ${k+1}` } </div>
                <div className={["D-S-SubTitle"]}> { `${this.getI18n(`es_pick_num_options`).replace("<val>", rng)    }` } </div>
                {
                  v['selections'].map( (val, key) => {
                    const {item_code, add_amount, menuitem} = val
                    const { ui_text, asset, name, option_groups} = service.massageAsset2( menuitem )
                    //var selected = (id in picked_selections), options = null
                    var selected = (k in picked_selections), options = null
                    //var aselected = picked_selections[id]
                    var aselected = picked_selections[k]
                    var sln = []
                    if( selected ) {
                      //sln = picked_selections[id]["selections"].filter( (v)=> v['item_code'] === item_code  )
                      sln = picked_selections[k]["selections"].filter( (v)=> v['item_code'] === item_code  )
                      if( sln.length === 0 ) {
                        selected = false
                      } else {
                        options = option_groups
                      }
                    }
                    return (
                      <div className={["D-S-Wrapper"].join(" ")} key={key} >
                        <div className={["D-S-W-Holder"].join(" ")}>
                          <input
                            className={["D-S-W-H-Checkbox"].join(" ")}
                            type="checkbox" 
                            checked={ selected }
                            value={ item_code }
                            onChange={ (e)=>{
                              this.changeSelection(e, val, v, k)
                            } }
                          />
                          {
                            ui_text['cover'] && 
                            <img
                              className={["D-S-W-H-Img"].join(" ")}
                              src={ service.assetImage( `${  asset[ ui_text['cover']['value'] ]['file_path'] }` ) }/>
                          }
                          {
                            ! ui_text['cover'] && 
                            <div
                              className={["D-S-W-H-Img"].join(" ")} />
                          }
                          <pre className={["D-S-W-H-Name"].join(" ")}>
                            {   ui_text['display']?ui_text['display'][language]:name   }
                          </pre>
                          <pre className={["D-S-W-H-Amount"].join(" ")}> { `+$${add_amount}` } </pre>
                        </div>
                        {
                          selected && options && options.length > 0 &&
                          options.map( (v1, k1) => {
                            const rngo =  (v1['select_min']!==v1['select_max'])?` ${v1['select_min']} - ${v1['select_max']} `:` ${v1['select_max']} `
                            return (
                                <div className={["D-S-W-Options"].join(" ")} key={k1}>
                                  <div className={["D-S-W-O-Title", (`${k}_${v1['id']}` in error_selections_options)?"Error":""].join(" ")} >     { `${this.getI18n("to_entitle_option")} - ${k1+1}` } </div>
                                  <div className={["D-S-W-O-SubTitle", (`${k}_${v1['id']}` in error_selections_options)?"Error":""].join(" ")} >  { this.getI18n(`es_pick_num_options`).replace("<val>", rngo)  } </div>
                                  {
                                    v1["options"].map( (v2, k2) => {
                                      const {add_amount} = v2
                                      const name = this.getI18n( `to_entitle_${v2['name']}` )

                                      var ng = []
                                      
                                    
                                      if( aselected ) {
                                        for( var s of aselected['selections'] ) {
                                          if( s['options'] ) {
                                            if ( v1['id'] in s['options'] ) {
                                              ng = s['options'][v1['id']].filter( (v)=> v['id'] === v2['id']  )
                                              if( ng.length === 1 ) {
                                                break
                                              }
                                            }
                                          }
                                        }
                                      }
                                      

                                      return (
                                        <div className={["D-S-W-O-Option"].join()} key={k2}>
                                          <input
                                            className={["D-S-W-O-Checkbox"].join(" ")}
                                            type="checkbox" 
                                            checked={ ng.length > 0 }
                                            value={ item_code }
                                            onChange={ (e)=>{
                                              //this.changeSelectionOption(id, v2, v1, e.target.value, key)
                                              this.changeSelectionOption(k, v2, v1, e.target.value)
                                            } }
                                          />
                                          <pre className={["D-S-W-O-Name"].join(" ")}> {name} </pre>
                                          <pre className={["D-S-W-O-Price"].join(" ")}> { `+$${add_amount}` } </pre>
                                        </div>
                                      )
                                    } )
                                  }
                                </div>
                              )
                            }
                          )

                          
                        }
                      </div>
                    )
                  } )
                }
              </div>
            )
          } )

        }
      </div>
    )
  }

  render() {
    const { brands, i18n, language, selected, updateParent, user, hasLogin, selectedItem,    selectedShop, menuorder } = this.props
    const { tab, quantity, clicked, showGallery, gIndex, isBuy, picked_options, error_options, picked_selections  } = this.state
    if(selected) {
      try {
        //console.log( user )
        var price_display = 0
        const { ui_text, asset, selection_groups, option_groups , price, tier_pricing, item_type} = service.massageAsset2( selected )
        var image = null, options = null, add_amount = 0, valid_day_before = 0

        add_amount = this.addAmounts()
        /*if(picked_options) {
          //add_amount = p['options'].reduce((a, b) => a['add_amount'] + b['add_amount'], 0)
          for (var o of Object.values(picked_options) ) {
            for (var b of o['options']) {
              var valid = ((hasLogin && tier_pricing) || hasLogin || (!tier_pricing) )
              if(valid) {
                add_amount += b['add_amount']
              }
            }
          }
        }*/

        if(option_groups) {
          options = option_groups
        }

        if( ui_text && ui_text['cover'] ) {
          if( asset && asset[ ui_text['cover']['value'] ] ) {
            image = `${service.asset}/${asset[ ui_text['cover']['value'] ]['file_path']}`
          }
        }

        if( ui_text && ui_text['valid_day_before'] ) {
          valid_day_before = parseInt( ui_text['valid_day_before']['value'], 10  )
        }

        const shops = service.pickupShops( brands, selected['pickup_shop_codes'] )

        const {original_price, discount_type} = ui_text

        var appendix = ""
        
        if( tier_pricing ) {
          if ( hasLogin ) {
            const gp = tier_pricing.filter( (v)=>v['tier_level']===user['user']['tier_level'] )
            if( gp.length > 0 ) {
              appendix = original_price?`${i18n[`es_member_price`][language]}:`:""
              price_display = gp[0]['price']>-1?gp[0]['price']:price
            } else {
              price_display = price
            }
          } else {
            appendix = original_price?`${i18n[`es_member_price`][language]}:`:""
            price_display = tier_pricing[0]['price']>-1?tier_pricing[0]['price']:price
          }
        } else {
          appendix = original_price?
                         `${(discount_type?i18n[`es_${discount_type['value']}_price`][language]:i18n[`es_special_price`][language])}:`:""
          price_display = price
        }
        //appendix = original_price?`${(discount_type?i18n[`es_${discount_type['value']}_price`][language]:i18n[`es_special_price`][language])}:`:""

        const isWrapper = selectedItem?true:false
        
        return (
          <div className={["To-Detail" , (selected?"Show":""), isWrapper?"In-Wrapper":""  ].join(" ")}>
              <div className={["D-Header"].join(" ")}>
                <Link to={`${window.location.pathname}${window.location.search}`}>
                  <div className={["D-I-Holder"  ].join(" ")} onClick={()=>{
                    this.init()
                    if(!isWrapper) {
                      updateParent( { selected : null } )
                    } else {
                      updateParent({
                        selected : null,
                        selectedItem : null,
                        selectedShop : null,
                        showPopup : false
                      })
                    }
                  }}> 
                    <FaTimes className={["Fa"].join(" ")}/>
                    <span>  { `${i18n["close_window"][language]}` } </span>
                  </div>
                </Link>
              </div>
              
              <div className={["D-Wrapper"].join(" ")}>
                <div className={["D-Content"].join(" ")}>
                  <div className={["D-C-Cover"].join(" ")} >
                    <div className={["D-C-C-Top"].join(" ")}>
                      <div className={["D-C-C-Wrapper"].join(" ")}>
                        {
                          original_price && original_price['value'] &&
                          <div className={["D-C-C-P-Original"].join(" ")}> { `${i18n['es_original_price'][language]} : $ ${  parseFloat(original_price['value'], 10).toLocaleString()}` } </div>
                        }
                        <div className={["D-C-C-P-Price"].join(" ")}> 
                            <span className={["Appendix"].join(" ")}>{ appendix }</span>
                            <span>{`$ ${ price_display.toLocaleString()}`}</span>
                        </div>
                      </div>
                      
                    </div>
                    { 
                      image &&
                      <img src={image} className={["D-C-C-Img"].join(" ")} />
                    }
                    {
                      !image &&
                      <div className={["D-C-C-Img"].join(" ")}></div>
                    }
                    <div className={["D-C-C-Gallery"].join(" ")}>
                      {
                        ui_text['gallery'] &&
                        ui_text['gallery']['value'].split(',').map( (v, k) => {
                          try {
                            var img = `${service.asset}/${asset[ v ]['file_path']}`
                            return (
                              <img key={k} src={img} onClick={ (e)=>{
                                this.setState({
                                  showGallery : true,
                                  gIndex : k
                                })
                              } }/>
                            )
                          } catch(e) {
                            return null
                          }
                        } )
                      }
                    </div>
                  </div>
                  <div className={["D-C-Content"].join(" ")}>
                    <div className={["D-C-Title"].join(" ")}>
                      {
                        item_type==="coupon" &&
                        <span className={["D-Badge"].join(" ")}>{ i18n["es_coupon"][language]  }</span>
                      }
                      {    
                        ui_text['display']?
                          ui_text['display'][language]:
                            selected['name']
                      }
                    </div>
                    { /*
                      <div className={["D-C-Valid"].join(" ")}>  
                        {
                          `${ selected['start_time'] } - ${ selected['stop_time']?selected['stop_time']:"-----" }`
                        }
                      </div>
                      */
                    }

                    {
                      (ui_text["desc"] || ui_text["terms"]) &&
                      <div className={["D-C-Terms"].join(" ")}> 
                        <div className={["D-C-Tabs"].join(" ")}>  
                          <div className={["D-C-Tab", (tab==="detail")?"Selected":""].join(" ")} onClick={ ()=>{ this.setState({tab : "detail"}) } }>{ i18n['es_pu_details'][language] }</div>
                          {
                            (ui_text["terms"]) &&
                            <div className={["D-C-Tab", (tab==="terms")?"Selected":"" ].join(" ")} onClick={ ()=>{ this.setState({tab : "terms" }) }} >{ i18n['es_pu_terms'][language] }</div>
                          }
                        </div>
                        <div className={["D-C-T-Content"].join(" ")}> 
                            {
                              ui_text["desc"] &&
                              (tab==="detail")?ui_text["desc"][language]:""
                            }
                            {
                              ui_text["terms"] &&
                              (tab==="terms")?(ui_text["terms"]?ui_text["terms"][language]:""):""
                            }
                        </div>
                      </div>
                    }

                  </div>
                </div>

                { /*ITEM OPTIONS*/
                  isBuy && options && options.length > 0 &&
                  <div className={["D-Options"].join(" ")}>
                    <div className={["Options"].join(" ")}>
                        <div className={["O-Title"].join(" ")}>{i18n['es_pick_options'][language]}</div>
                        {
                          options.map( (v1, k1) => {
                            const str = (v1['select_min']!==v1['select_max'])?` ${v1['select_min']}-${v1['select_max']} `:` ${v1['select_max']} `
                            return (
                              <div className={["D-Option-Wrapper", (v1['id'] in error_options)?"Error":""  ].join(" ")} key={k1}> 
                                <div className={["D-Option-Title"].join(" ")}>{
                                  `${  this.getI18n( v1['description'] ) }  [${ i18n['es_pick_num_options'][language].replace("<val>", str) }]`
                                }</div>
                                <div className={["D-Option-Group"].join(" ")}> 
                                  {
                                    v1['options'].map( (v2, k2) => {
                                      var o = []
                                      if( picked_options[ v1['id'] ] ) {
                                        o = picked_options[ v1['id'] ]['options'].filter( (vv)=>vv['id']===v2['id'] )
                                      }                                    
                                      return (
                                        <div className={["D-Option-Group-Option"].join(" ")} key={k2}>
                                          <input 
                                            className={["D-Option-Group-Option-Checkbox"].join(" ")}
                                            type="checkbox" 
                                            checked={ (o.length > 0)  }
                                            name={ this.getI18n( `to_entitle_${v2['name']}` ) } 
                                            value={ k2 }
                                            onChange={ (e)=>{
                                              this.changeOption(e, v2, v1 )
                                            } }
                                          />
                                          <div className={["D-Option-Group-Option-Name"].join(" ")}>  { this.getI18n( `to_entitle_${v2['name']}` ) } </div>
                                          <div className={["D-Option-Group-Option-Price"].join(" ")}> { `+ $${v2['add_amount'].toLocaleString()}` } </div>
                                        </div>
                                      )
                                    } )

                                  }
                                </div>
                              </div>
                            )
                          } )
                        }
                    </div>
                  </div>
                }

                {
                  isBuy && selection_groups && selection_groups.length > 0 &&
                  this.renderSelection()
                }
              </div>

              { /*CART*/
                isBuy &&
                <div className={["D-Cart"].join(" ")}>
                        <div className={["D-C-Q-Wrapper"].join(" ")}>
                            <div className={["Q-Text"].join(" ")}>{"QTY"}</div>
                            <div className={["Q-Wrapper"].join(" ")}>
                              <div className={["Fa-Wrapper"].join(" ")} onClick={ ()=>{
                                if( item_type !== "coupon" ) {
                                  if( quantity-1 >= 1 ) {
                                    this.setState({
                                      quantity : quantity-1
                                    })
                                  }
                                } else {
                                  this.setState({
                                    quantity : 1
                                  })
                                }
                              } }>
                                <FaMinus className={["Fa"].join(" ")}/>
                              </div>
                              <input
                                className={["Quantity"].join(" ")} 
                                value={quantity} onChange={ (e)=>{
                                if( item_type !== "coupon" ) {
                                  if( service.validateNum(e.target.value) || e.target.value.length === 0 ) {
                                    this.setState({
                                      quantity : (e.target.value.length !== 0)?parseInt( e.target.value , 10):0
                                    })
                                  }
                                } else {
                                  this.setState({
                                    quantity : 1
                                  })
                                }
                              } } />
                              <div className={["Fa-Wrapper"].join(" ")} onClick={ ()=>{
                                //if( quantity+1 < 0 ) {
                                  if( item_type !== "coupon" ) {
                                    this.setState({
                                      quantity : quantity+1
                                    })
                                  } else {
                                    this.setState({
                                      quantity : 1
                                    })
                                  }
                                //}
                              } }>
                                <FaPlus className={["Fa"].join(" ")}/>
                              </div>
                            </div>
                        </div>
                        <div className={["D-C-Confirm", clicked?"Clicked":"", (( quantity > 0)  )?"":"Disabled"].join(" ")} onClick={()=>{
                            //if( ((quantity > 0)  && (shop && shop!=="") && (date!==null)) || (item_type === "coupon") ) {
                              if((quantity > 0)) {
                                if( selectedItem ) {
                                  this.updateOrder() 
                                } else {
                                  this.placeOrder() 
                                }
                              }
                            //}
                          }}>
                            {
                              !selectedItem &&
                              <FaCartPlus className={["Text"].join(" ")}/>
                            }
                            {
                              selectedItem && <span className={["Sub"].join(" ")}>
                                { this.getI18n( "update" ) }
                              </span>
                            }
                            {
                              quantity > 0 &&
                              <span className={["Sub"].join(" ")}>{  
                                `$ ${( quantity * (price_display + add_amount) ).toLocaleString()}`
                              }</span>
                            }
                        </div>
                </div>
              }


              {/*PHOTO GALLERY*/}
              <div className={["D-S-Gallery", showGallery?"Show" : ""].join(" ")}>
                <div className={["D-Header"].join(" ")}>
                  <div className={["D-I-Holder"  ].join(" ")} onClick={()=>{
                    this.setState({
                      showGallery : false
                    })
                  }}> 
                    <FaTimes className={["Fa"].join(" ")}/>
                    <span>  { `${i18n["close_window"][language]}` } </span>
                  </div>
                </div>
                <div className={["D-Photo"].join(" ")}>
                  {
                    ui_text['gallery'] &&
                    ui_text['gallery']['value'].split(',').map( (v,k) => {
                      try {
                        var img = `${service.asset}/${asset[ v ]['file_path']}`
                        return (
                          <img key={k} 
                            src={img} 
                            className={['D-Img' , gIndex===k?"Selected":"" ].join(" ")}/>
                        )
                      } catch(e) {
                        return null
                      }
                    } )
                  }
                  <div className={["Left"].join(" ")} onClick={()=>{
                    try {
                      var l = ui_text['gallery']['value'].split(',')
                      var i = (gIndex-1>=0)?(gIndex-1):(l.length-1)
                      this.setState({ gIndex :i })
                    } catch(e){}

                  }}>
                    <FaChevronLeft className={["Fa"].join(" ")} />
                  </div>
                  <div className={["Right"].join(" ")} onClick={()=>{
                    if( showGallery ) {
                      var l = ui_text['gallery']['value'].split(',')
                      var i = (gIndex+1>=l.length)?0:(gIndex+1)
                      this.setState({ gIndex :i })
                    }
                  }}>
                    <FaChevronRight className={["Fa"].join(" ")} />
                  </div>
                  <div className={["Dots-Selector"].join(" ")}>
                    {
                      ui_text['gallery'] &&
                      ui_text['gallery']['value'].split(',').map( (v,k) => {
                        return (
                          <div onClick={ ()=>{
                            this.setState({ gIndex : k })
                          } } 
                            className={['Dot', k===gIndex?"Selected":""].join(" ")} key={k}></div>
                        )
                      })
                    }
                  </div>
                </div>
              </div>

          </div>
        )
      } catch(e){
        console.log(e)
        return null
      }
    } else {
      return null
    }
  }
}

export default Detail;


