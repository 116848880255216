import React, {Component} from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import { Link } from 'react-router-dom'
import { NavHashLink, HashLink } from 'react-router-hash-link';
import { FaBars, FaTimes, FaUser } from 'react-icons/fa';
import Lottie from "lottie-react";
import service from '../../public/service';
//import Logo from "../../assets/lottie/logo.json";
import Shop from "../../assets/lottie/eshop.json";

//require('react-web-vector-icons/fonts');

class Header extends Component {
  constructor(props) {
    super(props)
    this.state={
      offsetY : false,
      show : false,
      loop : false
    }
  }
  
  componentDidMount() {
    //var elem = document.getElementById("header_bar")
    //var header_bar = elem.offsetHeight
    //console.log( window.location )

    var header_bar = 0
    window.onscroll = () => {
      if(  (window.pageYOffset > header_bar) && (this.state.offsetY === false ) ) {
        this.setState({
          offsetY : true
        })
      } else if(  (window.pageYOffset <= header_bar) && ( this.state.offsetY === true ) ) {
        this.setState({
          offsetY : false
        })
      } 
    }
  }



  render() {
    const {loop} = this.state
    const {user, hasLogin, i18n, language,showLogin, updatevalue, config} = this.props

    var showHeader = true
    for (var s of service.header_excecptions) {
      if( window.location.pathname.indexOf(s) > -1 ) {
        showHeader = false
        break
      }
    }
    var zero_content = service.header_zero_content.includes( window.location.pathname )

    const IGNORE_LOGIN = ((window.location.pathname.indexOf("/catering") > -1) )

    return(
      <div className={["Header", this.state.offsetY?"Offset":"" ].join(" ")} id={"header"}>
        <div className={["Large-Frame"].join(" ") } >
          {
            showHeader &&
            <div className={["Logo-Frame", this.state.offsetY?"Disapper":""].join(" ")}>
              { 
                <img 
                  src={ window.logo } 
                  className={["Logo"].join(" ")}/>
              }
              { /*
                <Lottie 
                  animationData={ Logo } 
                  className={["Logo"].join(" ")} 
                  loop={loop}/>
                */
              }
            </div>
          }

          <div className={["Lower"].join(" ")} id="header_bar" >
            <div className={["Lower-Frame"].join(" ")}>
              {
                this.props.content && !zero_content &&
                Object.keys( this.props.content ).map( (v,k) => {
                  if( this.props.content[v].header === true  ) {
                    if( this.props.content[v].link ) {
                      return(
                        <a
                          href={this.props.content[v].link}
                          key={"A_"+k}
                          target="_blank"
                          className={["Item", (this.props.selected===v)?"Selected":""].join(" ")}
                        > 
                          {  this.props.content[v].display[this.props.language]  } 
                        </a>
                      )
                    } else {
                      if( this.props.content[v].page === false ) {
                        return (
                          <Link 
                            onClick={()=>{  
                              window.scrollTo(0,0) 
                              this.props.updatevalue({ selected  : v})
                            }}
                            to={  v  }
                            key={"L_"+k}
                            className={["Item", (this.props.selected===v)?"Selected":""].join(" ")}>
                              {  this.props.content[v].display[this.props.language]  }
                          </Link>
                        )
                      } else {
                        //https://ithelp.ithome.com.tw/articles/10279669
                        return (
                          <HashLink
                            smooth
                            onClick={(e)=>{
                              this.props.updatevalue({ selected  : v})
                            }}
                            scroll={(el) => {
                              window.scrollTo(0,0)
                              setTimeout( ()=>{
                                el.scrollIntoView({ 
                                  behavior: "auto", 
                                  block: 'start',
                                  inline: "start"
                                })
                              }, 300)
                            }}
                            to={"/"+this.props.content[v].page+"#"+v}
                            key={"H_"+k}
                            className={["Item", (this.props.selected===v)?"Selected":""].join(" ")}>
                              {  this.props.content[v].display[this.props.language]  }
                          </HashLink>
                        )
                      }
                    }
                  }
                } )
              }
              
              {
                this.props.hasEShop && !zero_content &&
                <Link 
                  className={["Item","Shop-Btn", (this.props.selected==="shop")?"Selected":""].join(" ")} 
                  onClick={()=>{  
                    window.scrollTo(0,0) 
                    this.props.updatevalue({ selected  : "shop"})
                  }}
                  to={  "shop"  }
                >
                  <div className={["Centered"].join(" ")}>
                  <Lottie animationData={ Shop } className={["Lottie"].join(" ")} loop={true}/>
                  </div>
                  <div className={["Text"].join(" ")}>{ i18n['hd_eshop'][language] }</div>
                </Link>
              }


              {
                this.props.hasBooking && (window.location.pathname.indexOf( "/shop/" ) === -1) && !zero_content &&
                <div className={["Item","Booking-Btn"].join(" ")} onClick={()=>{
                  this.props.toggleBooking( !this.props.showBooking )
                }}>
                  { this.props.i18n['insert_a_reservation'][this.props.language] }
                </div>
              }

              

            </div>
          </div>
        </div>
        <div className={['Languages']} >
            {
              this.props.languages.map(  
                (v, k) => {
                  if(this.props.language===v.key)  {
                    return null
                  } else {
                    return (
                      <div 
                        key={k} 
                        onClick={()=>{
                          this.setState({
                            show : false
                          })
                          this.props.updatevalue({language : v.key})
                        }}
                        className={['Option'].join(" ")}>
                        {v.name}
                      </div>
                    )
                  }
                }
              )
            }
        </div>

        {
          config['login'] && !IGNORE_LOGIN &&
          <div className={['User-Btn']} 
            onClick={
              () => {
                updatevalue({  
                  showLogin : true
                })
              }
            }
          > 
            <FaUser className={['Fa']}/>
            <span className={['Text']}>{ 
                !hasLogin?
                i18n['signin'][language]: 
                i18n['hi_name'][language].replace( "<name>", (user?user['user']['first_name']:"Member"))
            } </span>
          </div>
        }

        <div className={["Small-Frame"].join(" ") } >
          <div className={['Nav-Bar'].join(' ')} >
            { !this.state.show &&
              <FaBars className={['Icon'].join(" ")} onClick={ (e)=>{  this.setState({  show : true}) } } />
            }
            { this.state.show &&
              <FaTimes className={['Icon'].join(" ")} onClick={ (e)=>{  this.setState({  show : false}) } } />
            }
            <img 
              src={ window.logo_h } 
              className={["Logo"].join(" ")}/>
            <div className={["Pad"].join(" ")}></div>
          </div>
          <div className={ ['Nav-Menu', this.state.show?"Show":""].join(" ")  }>
            {
                this.props.content && !zero_content &&
                Object.keys( this.props.content ).map( (v,k) => {
                  if( this.props.content[v].header === true ) {
                    if( this.props.content[v].link ) {
                      return (
                        <a
                          onClick={ ()=>{
                            window.scrollTo(0,0)
                            this.setState({
                              show : false
                            })
                          } }
                          href={this.props.content[v].link}
                          key={k}
                          target="_blank"
                          className={["Item", (this.props.selected===v)?"Selected":""].join(" ")}
                        > 
                          {  this.props.content[v].display[this.props.language]  } 
                        </a>
                      )
                    } else {
                      if( this.props.content[v].page === false ) {
                        return (
                          <Link 
                            onClick={()=>{  
                              window.scrollTo(0,0) 
                              this.setState({
                                show : false
                              })
                              this.props.updatevalue({ selected  : v})
                            }}
                            to={  v  }
                            key={k}
                            className={["Item", (this.props.selected===v)?"Selected":""].join(" ")}>
                              {  this.props.content[v].display[this.props.language]  }
                          </Link>
                        )
                      } else {
                        return (
                          <HashLink
                            smooth
                            onClick={(e)=>{  }}
                            scroll={(el) => {
                              this.props.updatevalue({ selected  : v})
                              window.scrollTo(0,0)
                              this.setState({
                                show : false
                              })
                              setTimeout( ()=>{
                                el.scrollIntoView({
                                   behavior: "auto", 
                                   block: 'start' ,
                                   inline: "start"
                                })
                              }, 400)
                            }}
                            to={"/"+this.props.content[v].page+"#"+v}
                            key={k}
                            className={["Item", (this.props.selected===v)?"Selected":""].join(" ")}>
                              {  this.props.content[v].display[this.props.language]  }
                          </HashLink>
                        )
                      }
                    }
                  }
                } )
            }

            {
                this.props.hasEShop && !zero_content &&
                <Link 
                  className={["Item","Shop-Btn", (this.props.selected==="shop")?"Selected":""].join(" ")} 
                  onClick={()=>{  
                    window.scrollTo(0,1) 
                    this.setState({
                      show : false
                    }, ()=>{
                      this.props.updatevalue({ selected  : "shop"})
                    })
                    
                  }}
                  to={  "shop"  }
                >{ i18n['hd_eshop'][language] }</Link>
            }

            {
              this.props.hasBooking && (window.location.pathname.indexOf( "/shop/" ) === -1) && !zero_content &&
              <div className={["Item","Booking-Btn"].join(" ")} onClick={()=>{
                this.props.toggleBooking( !this.props.showBooking )
              }}>
                { this.props.i18n['insert_a_reservation'][this.props.language] }
              </div>
            }

            <div className={['Languages']} >
              {
                this.props.languages.map(  
                  (v, k) => {
                      return (
                        <div 
                          key={k} 
                          onClick={()=>{
                            this.setState({
                              show : false
                            })
                            this.props.updatevalue({language : v.key})
                          }}
                          className={['Option', (this.props.language===v.key)?"Selected":""].join(" ")}>
                          {v.name}
                        </div>
                      )
                    }
                )
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Header;


/*
<select className={['Languages']} value={this.props.language} onChange={ 
          (e)=>{
            this.props.updatevalue({
              language : e.target.value
            })
         } }>
            {
              this.props.languages.map(  
                (v, k) => {
                  return (
                    <option key={k} value={v.key} >
                      {v.name}
                    </option>
                  )
                }
              )
            }
        </select >
*/