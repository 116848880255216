import React from 'react';
//import ReactDOM from 'react-dom/client';
import { createRoot } from 'react-dom/client';
import './index.scss';
import App from './App';
import colors from './public/colors.js'
import * as serviceWorker from './serviceWorker';


setTimeout(
    () => {
        
        function addStyle(styleString) {
            const style = document.createElement("style");
            style.textContent = styleString;
            document.head.append(style);
        }
        
        function hexToRgbA(hex){
            var c;
            if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
                c= hex.substring(1).split('');
                if(c.length === 3){
                    c= [c[0], c[0], c[1], c[1], c[2], c[2]];
                }
                c= '0x'+c.join('');
                //console.log( [(c>>16)&255, (c>>8)&255, c&255].join(',') )
                return [(c>>16)&255, (c>>8)&255, c&255].join(',');
            } else {

            }
            //throw new Error('Bad Hex');
        }
        /*
        if( window.colors ) {
            for( var i in window.colors) {
                if( window.colors[i] !== null ) {
                    colors[ i ] = window.colors[i]
                }
            }
        } 
        */       
        //console.log( colors )
        
        addStyle(`
            :root {
            --l7-bgcolor: ${hexToRgbA(colors.bgcolor)};
            --l7-white-color: ${hexToRgbA(colors.whiteColor)};
            --l7-dark-color: ${hexToRgbA(colors.darkColor)};
            --l7-grey-color: ${hexToRgbA(colors.greyColor)};
            --l7-frame-color: ${hexToRgbA(colors.frameColor)};
            --l7-frame-color-sub: ${hexToRgbA(colors.frameColorSub)};
            --l7-frame-color-2: ${hexToRgbA(colors.frameColor2)};
            --l7-frame-color-2-sub: ${hexToRgbA(colors.frameColor2Sub)};
            --l7-accent-color: ${hexToRgbA(colors.accentColor)};
            --l7-accent-color-2: ${hexToRgbA(colors.accentColor2)};
            --l7-accent-color-3: ${hexToRgbA(colors.accentColor3)};
            --l7-text-color: ${hexToRgbA(colors.textColor)};
            --l7-text-color-white: ${hexToRgbA(colors.textColorWhite)};
            --l7-text-color-Light: ${hexToRgbA(colors.textColorLight)};
            }
        `);
        
        const container = document.getElementById('root');
        const root = createRoot(container); // createRoot(container!) if you use TypeScript
        root.render(<App tab="home" />);
    }, 500
)

serviceWorker.unregister();
