import React, {Component} from 'react';
import './index.scss';
import service from '../../public/service';
import { Link } from 'react-router-dom';

import Login from './Login';
import Purchase from './Purchase';
import PointHistory from './PointHistory';
import Unusable from './Unusable';
import Booking from './Booking';
import TogoHistory from './TogoHistory';

class User extends Component {
  constructor(props) {
    super(props)
    this.state={
      isLogin : false,
      user : null,
      standalone : false,
      page : "point"
    }
    this.update = this.update.bind(this)
    this.getUser = this.getUser.bind(this)
  }
  
  componentDidMount() {

    const {hasLogin, user} = this.props

    var session_key = null, user_id = null, page = "point"
    const params = service.getParamQuery()
    if( params['session'] ) {
      session_key = params['session']
    }
    if( params['id'] ) {
        user_id = params['id']
    }
    if( params['page'] ) {
        page = params['page']
    }

    if(session_key&&user_id) {
        service.session_key = session_key 
        this.getUser( parseInt( user_id , 10 ), page )
    } 
  
    if( hasLogin && !session_key ) {
      service.session_key = user["session_key"] 
      this.setState({ 
        isLogin : true, 
        user : user['user'], 
        standalone : false
      })
      //var user_id = user['user']['id'], page = "point"
      //this.getUser( parseInt( user_id , 10 ), page )
    } 
    
    
  }

  componentDidUpdate( props, state ) {
    //console.log( props )
    //console.log( this.props )
    if( props.hasLogin === false && this.props.hasLogin === true ) {
      this.setState({
        user : this.props.user,
        isLogin : true
      })
      this.getUser( this.props.user.id , "point" )
    }
  }

  componentWillUnmount() {

  }

  update(obj, cb) {
    this.setState(obj, cb)
  }

  async getUser(user_id, page) {
    let user = await service.getUser( user_id )
    if( user['id'] ) {
      this.setState({
        user : user,
        isLogin : true,
        standalone : true,
        page : page
      })
    }
  }

  render() {
    const { isLogin, user, standalone, page } = this.state
    const { i18n, language, config, gi18n } = this.props
    return (
      <div className={["User"].join(" ")} >
          <div className={["Control"].join(" ")}> 
            {
              !standalone && true === false &&
              <a
                href={ "/" }
                className={["Back"].join(" ")}>
                {i18n['back'][language]}
              </a>
            }
            {
              isLogin && !standalone && config['wallet'] &&
              <div
                onClick={ ()=>this.setState({ page : "home" }) }
                className={["Back", page==="home"?"Selected":""].join(" ")}>
                { i18n["wallet"][language].replace(/\n/g, "") }
              </div>
            }
            {
              isLogin &&
              <div
                onClick={ ()=>this.setState({ page :"point"}) }
                className={["Back", page==="point"?"Selected":"" ].join(" ")}>
                { i18n["ph_point_history"][language] }
              </div>
            }
            {
              isLogin &&
              <div
                onClick={ ()=>this.setState({ page : "purchase" }) }
                className={["Back", page==="purchase"?"Selected":""].join(" ")}>
                { i18n["ps_purchase_history"][language] }
              </div>
            }
            {
              isLogin &&
              <div
                onClick={ ()=>this.setState({ page :"unusable"}) }
                className={["Back", page==="unusable"?"Selected":""].join(" ")}>
                { i18n["ch_coupon_history"][language] }
              </div>
            }
            {
              isLogin && config['togo'] &&
              <div
                onClick={ ()=>this.setState({ page :"togo"}) }
                className={["Back", page==="togo"?"Selected":""].join(" ")}>
                { gi18n("ch_togo_history") }
              </div>
            }
            {
              isLogin && !standalone && config['booking'] &&
              <div
                onClick={ ()=>this.setState({ page : "booking" }) }
                className={["Back", page==="booking"?"Selected":""].join(" ")}>
                { i18n["booking"][language].replace(/\n/g, "") }
              </div>
            }
          </div>
          {
            isLogin &&
            <div className={["Content",  page !== "home"?"Top":""].join(" ")}> 
              {
                page === "home" &&
                <>
                {
                  user && user['wallet'] &&
                  <div className="Holder">
                    <div className="Title"> {  i18n["wallet_my_token"][language] } </div>
                    <div className="Token"> {   user['wallet']['token'].toLocaleString()   } </div>
                  </div>
                }
                {
                  !(user && user['wallet']) &&
                  <div className="Holder">
                    <div className="Title"> { i18n["wallet_my_token"][language] } </div>
                    <div className="Token"> {   (0).toLocaleString()   } </div>
                  </div>
                }
                {
                  config['topup'] &&
                  <Link className={["Topup-Btn"].join(" ")} to={
                      /*`/wallet/topup?session=${service.session_key}&id=${user["id"]}`*/
                      `/wallet/topup`
                  }> { i18n['wallet_topup'][language] } </Link>
                }
                </>
              }
              { 
                page === "purchase" &&
                <Purchase {...this.props} user={user}/>
              }
              { 
                page === "point" &&
                <PointHistory {...this.props} user={user} />
              }
              { 
                page === "unusable" &&
                <Unusable {...this.props} user={user} />
              }
              { 
                page === "booking" &&
                <Booking {...this.props} user={user} />
              }
              { 
                page === "togo" &&
                <TogoHistory {...this.props} user={user} />
              }

            </div>
          }
          {
            !isLogin &&
            <Login {...this.props} isLogin={isLogin} update={this.update}/>
          }
      </div>
    )

  }
}

export default User;


