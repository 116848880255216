import React, {Component} from 'react';
import './index.scss';
import service from '../../../public/service';

const COUNT = 100

const titles = [
  "trans_time",
  "purchase_type",
  "purchase_shop_code",
  "purchase_receipt_id",
  "purchase_spent_amount",
  "action",
  "point",
]

class PointHistory extends Component {
  constructor(props) {
    super(props)
    this.state={
      history : [],
      page : 0,
      totalPage : 0,
      shops : {}
    }
    this.getPoint = this.getPoint.bind(this)
    this.massage = this.massage.bind(this)
  }
  
  componentDidMount() {
    this.getPoint()
  }

  componentWillUnmount() {

  }

  async getPoint() {
    const {user, brands} = this.props
    var shops = service.rebrand( brands )
    if( service.session_key && user && ("id" in user) ) {
      let purchase = await service.purchaseSearch( user['id'] )
      let point = await service.pointTransaction( user['id'] )
      const result = this.massage( purchase['data'], point['data'] )
      /*
      this.setState({
        history : data['data'],
        totalPage : Math.floor( data['count']/COUNT ),
        shops : shops,
      })
      */
      this.setState({
        history : result,
        shops : shops,
      })
    }
  }

  massage( purchase, point ) {
    var p = {}, result = []
    for ( var r of purchase ) {
      p[ r['id'] ] = r
    }
    for (var t of point ) {
      if( t['subject'] ) {
        if(  t['subject']['id'] in p ) {
          t[ 'purchase' ] = p[ t['subject']['id'] ]
        }
      }
      result.push(  t )
    }
    return result
  }


  render() {

    const { i18n, language } = this.props
    const { history, shops } = this.state
    return (
      <div className={["PointHistory"].join(" ")}>
        <div className={["P-Title"].join(" ")}>{ i18n["ph_point_history"][language] }</div>
        <div className={["Row","Title"].join(" ")} >
            {
              titles.map( (v,k) => {
                return (
                  <div className={["Td"].join(" ")} key={k} style={ { flexBasis : `${(1/titles.length*100)}%` } }>
                    { i18n[`ph_${v}`][language] }
                  </div>
                )
              } )
            }
        </div>
        <div className={["P-Holder"].join(" ")}>
            {
              history.length > 0 &&
              history.map( (v, k) => {
                return(
                  <div className={["Row"].join(" ")} key={k}>
                    {
                      titles.map( (v1,k1) => {
                        
                        if( v1.indexOf("purchase_") === -1 ) {
                          return(
                            <div className={["Td"].join(" ")} key={k1} style={ { flexBasis : `${(1/titles.length*100)}%` } }>
                              { i18n[ `ph_${v[v1]}` ]?i18n[  `ph_${v[v1]}`  ][language]:v[v1] }
                            </div>
                          )
                        } else {
                          const vp = v1.replace("purchase_", "")
                          if( vp === "spent_time" || vp === "receipt_id" ) {
                            return (
                              <div className={["Td"].join(" ")} key={k1} style={ { flexBasis : `${(1/titles.length*100)}%` } }>
                                { 
                                  v['purchase']&&v['purchase'][ vp ]?v['purchase'][ vp ]:"--"
                                }
                              </div>
                            )
                          } else if( vp === "spent_amount" ) {
                            return (
                              <div className={["Td"].join(" ")} key={k1} style={ { flexBasis : `${(1/titles.length*100)}%` } }>
                                { 
                                  v['purchase']&&v['purchase'][ vp ].toLocaleString(undefined, {minimumFractionDigits: 1})
                                }
                              </div>
                            )
                          } else if( vp === "shop_code" ) {
                            try {
                              var ui_text = v['purchase']&&v['purchase'][ vp ]?(shops[ v['purchase'][ vp ] ]&&shops[ v['purchase'][ vp ] ]['ui_text']?shops[ v['purchase'][ vp ] ]['ui_text']:null):null
                              var b_ui_text = v['purchase']&&v['purchase'][ vp ]?(shops[ v['purchase'][ vp ] ]&&shops[ v['purchase'][ vp ] ]['brand']['ui_text']?shops[ v['purchase'][ vp ] ]['brand']['ui_text']:null):null
                              var name = null
                              if( b_ui_text && b_ui_text['display'] ) {
                                name = b_ui_text['display'][language]
                              }
                              if( ui_text && ui_text['display'] ) {
                                name += ("("+ui_text['display'][language]+")")
                              }
                              return (
                                <div className={["Td"].join(" ")} key={k1} style={ { flexBasis : `${(1/titles.length*100)}%` } }>
                                  { 
                                    name?name:"--"
                                  }
                                </div>
                              )
                            } catch(e) {
                              return (
                                <div className={["Td"].join(" ")} key={k1} style={ { flexBasis : `${(1/titles.length*100)}%` } }> -- </div>
                              )
                            }
                          } else if( vp === "type" ) {
                            return (
                              <div className={["Td"].join(" ")} key={k1} style={ { flexBasis : `${(1/titles.length*100)}%` } }>
                                { 
                                  v['purchase']&&v['purchase'][ 'shop_code' ]?i18n["ph_dinning"][language]:i18n["ph_topup"][language]
                                }
                              </div>
                            )
                          }
                        }
                      } )
                    }
                  </div>
                )
              })
            }
            {
              history.length === 0 &&
              <div className={["Row"].join(" ")} >
                { i18n['ph_no_record_found'][language] }
              </div>
            }
        </div>
      </div>
    )
  }
}

export default PointHistory;


