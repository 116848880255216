import React, {Component} from 'react';
import './index.scss';
import service from '../../../public/service';

const titles = [
  "type",
  "shop_code",
  "receipt_id",
  "spent_time",
  "spent_amount"
]

const COUNT = 100

class Purchase extends Component {
  constructor(props) {
    super(props)
    this.state={
      history : [],
      page : 0,
      totalPage : 0,
      shops : {}
    }
    this.getHisotry = this.getHisotry.bind(this)
  }
  
  componentDidMount() {

    this.getHisotry()

  }

  componentWillUnmount() {

  }

  async getHisotry() {
    const {user, brands} = this.props
    const shops = service.rebrand( brands )
    if( service.session_key && user && ("id" in user) )  {
      let data = await service.purchaseSearch( user['id'] )
      this.setState({
        history : data['data'],
        totalPage : Math.floor( data['count']/COUNT ),
        shops : shops
      })
    }
  }


  render() {
    const { i18n, language } = this.props
    const { history, page, totalPage, shops } = this.state
    return (
      <div className={["Purchase"].join(" ")}>
        <div className={["P-Title"].join(" ")}>{ i18n["ps_purchase_history"][language] }</div>
        <div className={["Row","Title"].join(" ")} >
            {
              titles.map( (v,k) => {
                return (
                  <div className={["Td"].join(" ")} key={k} style={ { flexBasis : `${(1/titles.length*100)}%` } }>
                    { i18n[`ps_${v}`][language] }
                  </div>
                )
              } )
            }
        </div>
        <div className={["P-Holder"].join(" ")}>
            {
              history.length > 0 &&
              history.map( (v, k) => {
                return(
                  <div className={["Row"].join(" ")} key={k}>
                    {
                      titles.map( (v1,k1) => {
                        if( v1 === "spent_time" || v1 === "receipt_id" ) {
                          return (
                            <div className={["Td"].join(" ")} key={k1} style={ { flexBasis : `${(1/titles.length*100)}%` } }>
                              { 
                                v[ v1 ]?v[ v1 ]:"--"
                              }
                            </div>
                          )
                        } else if( v1 === "spent_amount" ) {
                          return (
                            <div className={["Td"].join(" ")} key={k1} style={ { flexBasis : `${(1/titles.length*100)}%` } }>
                              { 
                                v[ v1 ].toLocaleString(undefined, {minimumFractionDigits: 1})
                              }
                            </div>
                          )
                        } else if( v1 === "shop_code" ) {
                          
                          var name = v[v1]
                          try {
                            var ui_text = v[ v1 ]?shops[ v[ v1 ] ]['ui_text']:null
                            var b_ui_text = v[ v1 ]?shops[ v[ v1 ] ]['brand']['ui_text']:null
                            
                            if( b_ui_text && b_ui_text['display'] ) {
                              name = b_ui_text['display'][language]
                            }
                            if( ui_text && ui_text['display'] ) {
                              name += ("("+ui_text['display'][language]+")")

                            }
                          } catch(e) {

                          }
                          
                          try {
                          return (
                            <div className={["Td"].join(" ")} key={k1} style={ { flexBasis : `${(1/titles.length*100)}%` } }>
                              { 
                                name?name:"--"
                              }
                            </div>
                          )
                          } catch(e) {
                            return (
                              <div className={["Td"].join(" ")} key={k1} style={ { flexBasis : `${(1/titles.length*100)}%` } }> -- </div>
                            )
                          }
                        } else if( v1 === "type" ) {
                          return (
                            <div className={["Td"].join(" ")} key={k1} style={ { flexBasis : `${(1/titles.length*100)}%` } }>
                              { 
                                v[ 'shop_code' ]?i18n["ph_dinning"][language]:i18n["ph_topup"][language]
                              }
                            </div>
                          )
                        }
                      } )
                    }
                  </div>
                )
              })
            }
            {
              history.length === 0 &&
              <div className={["Row"].join(" ")} >
                {i18n['ph_no_record_found'][language]}
              </div>
            }
        </div>
      </div>
    )
  }
}

export default Purchase;


