import React, {Component} from 'react';
//import ReactDOM from 'react-dom/client';
import './index.scss';
import { Link } from 'react-router-dom'
import service from '../../public/service';

const WIDTH  = 500

class Social extends Component {
  constructor(props) {
    super(props)
    this.state={
      selected : null,
      brands : []
    }
    this.resize = this.resize.bind(this)

  }
  
  componentDidMount() {
    this.props.updatevalue({ selected : this.props.page  })
    
    var arr = this.props.brands.filter( (v)=> {
      return ( v['ui_text']['fb'] )
    } )
    this.setState( {
        brands : arr,
        selected : (arr.length>0)?arr[0]:null,
        width : Math.min(window.innerWidth, WIDTH)
      }
    )
    window.addEventListener("resize" , this.resize)
    setTimeout( () =>{
      if (window.FB) {
        window.FB.XFBML.parse();
      }
    }, 300 )

  }

  componentWillUnmount() {
    window.removeEventListener("resize" , this.resize)
  }


  resize() {
    this.setState({
      width : window.innerWidth
    })

  }


  

  render() {
    try {
      const { i18n, language, selected, config, content} = this.props
      var _c = content[selected]
      const { ui_text, asset, brand_code } = this.state.selected
      const { image, display,badge, fb} = ui_text
      var elem = document.getElementById("header")
      var height = window.innerHeight-(elem?elem.offsetHeight:0) - 0
      const url = service.asset
      
      document.title =  ((language in _c.display)?(_c.display[language] + " | "):"") + config['general']['name'][language]
      
      
      return (
        <div className={["Social"].join(" ")}>

          <div className={['Left']} >
            <div className={['Social-Panel']} >
              {
                this.state.brands.map( (v,k) => {
                  const { brand_code, name, ui_text, asset } = v
                  const { display, image, image_mockup } = ui_text
                  return (
                    <div
                      key={k}
                      onClick={ ()=>{
                        this.setState({ selected : v }) 
                        setTimeout( () =>{
                          if (window.FB) {
                            window.FB.XFBML.parse();
                          }
                        }, 300 )
                      }} 
                      className={["SP-Holder", (selected.key===v.key)?"Selected":""].join(" ")}>
                      {
                        (image && asset[image['value']]) &&
                        <img alt={image['value']} className={["Logo"].join(" ")} src={  
                            `${url}/${asset[image['value']]['file_path']}`
                        } />
                      }
                    </div>
                  )
                } )
              }
            </div>
            { 
              selected &&
              <div className={["Lower"].join(" ")}> 
              <div className={["Upper"].join(" ")}> 
                  <div className={["Logo-Frame"].join(" ")}>
                        { 
                          (image && asset[ image["value"] ]) &&
                          <img className={["Logo"].join(" ")} src={  
                            `${url}/${asset[ image["value"] ]['file_path']}`
                          } />
                          
                        }

                      </div>

              </div>
              <div className={['Badges'].join(" ")}>
                  {
                    badge &&
                    badge[this.props.language].split("|").map( (v1, k1) => {
                      return (
                        <div className={["Badge"].join(" ")} key={k1}>
                          {v1}
                        </div>
                      )
                    } )
                  }
              </div>
              <Link 
                to={ 
                    "/brand/" + brand_code.toLowerCase()
                }
                className={["Goto"].join(" ")}> 
                { 
                  display &&
                  i18n["brand_info"][language].replace("<brand>", display[ this.props.language ]  )
                }
              </Link>
            </div>
            }
          </div>

          { selected &&
            <div className={["Social-Wrapper"].join(" ")}>
              

              <div className={["Right"].join(" ")}>

              {
                /*
                <iframe 
                  src={
                      "https://www.facebook.com/plugins/page.php?href=" + 
                      this.state.selected.fb +
                      "&tabs=timeline,messages" +
                      "&width=" + Math.min(this.state.width, WIDTH) +
                      "&height=" + height +
                      "&small_header=true" +
                      "&adapt_container_width=true"+
                      "&hide_cover=true"+
                      "&show_facepile=false"+
                      "&lazy=true"+
                      "&appId="+
                      this.props.config.fb_app_id
                  }
                  style={{
                    width :  Math.min(this.state.width, WIDTH) + "px",
                    height : height + "px"
                  }}
                  scrolling="no" 
                  frameBorder={0} 
                  allowFullScreen={false} 
                  allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share">
                </iframe>
                */
              }

              {
                ///*
                fb &&
                <div 
                  style={{
                    width :  Math.min(this.state.width, WIDTH) + "px",
                    height : height + "px"
                  }}
                  attribution="setup_tool"
                  className="fb-page" 
                  data-href={ fb['value'] } 
                  data-tabs="timeline,messages" 
                  data-width={ Math.min(this.state.width, WIDTH)  }
                  data-height={height}
                  data-small-header="true" 
                  data-adapt-container-width="true" 
                  data-hide-cover="true" 
                  data-show-facepile="false">
                    <blockquote 
                      cite={ fb['value'] } 
                      className="fb-xfbml-parse-ignore">
                      <a href={fb['value']}>{ display[ language]}</a>
                    </blockquote>
                </div>
                //*/
              }



              </div>

            </div>
          }
        </div>
      )
    } catch(e) {
      return null
    }
  }
}

export default Social;


/*
<div class="fb-post" data-href="https://www.facebook.com/Gleamapp/posts/4678883135455113" data-width="500" data-show-text="true">
    <blockquote cite="https://www.facebook.com/Gleamapp/posts/4678883135455113" class="fb-xfbml-parse-ignore">
    <a href="https://facebook.com/Gleamapp">Gleam</a> on&nbsp;
    <a href="https://www.facebook.com/Gleamapp/posts/4678883135455113">Wednesday, September 8, 2021</a>
    </blockquote>
</div>
*/