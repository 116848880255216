import React, {Component} from 'react';
import './index.scss';
import service from '../../public/service';
import Detail from "./Detail"
import { FaShoppingCart } from "react-icons/fa"

const filters = [
  /*
  {
      key : "item",
      value : [
        { "key" : "all", "value" : "all" },
        { "key" : "hot_item", "value" : "hot_item" },
        { "key" : "latest", "value" : "latest" },
      ]
  },
  */
  {
    key : "type",
    value : [
      { "key" : "all", "value" : "all" },
      { "key" : "coupon", "value" : "coupon" },
      { "key" : "food", "value" : "food" },
      { "key" : "gift", "value" : "gift" },
      { "key" : "special", "value" : "special" },
    ]
  },
  {
      key : "price",
      value : [
        { "key" : "all", "value" : "all" },
        { "key" : "price_1", "value" : "$0-$100" , "up" : 100, "down" : 0 },
        { "key" : "price_2", "value" : "$101-$500", "up" : 500, "down" : 101 },
        { "key" : "price_3", "value" : "$501-$1000", "up" : 1000, "down" : 501 },
        { "key" : "price_4", "value" : "$1001+","up" : 999999999, "down" : 1001 },
      ]
  },
]

const LIMIT = 60

class EShop extends Component {
  
  constructor(props) {
    super(props)
    this.interval = 0
    this.state={
      cover : [],
      items : [],
      page : 1,
      total : 9999,
      index : 0,
      height : 300,
      count : 0,
      selectedFilter : {"items" : "all", "price" : "all"},
      selected : null,
      origin_items : []
    }
    this.init = this.init.bind(this)
    this.changeFilter = this.changeFilter.bind(this)
    this.pagnation = this.pagnation.bind(this)
    this.searchItem = this.searchItem.bind(this)
    this.updatevalue = this.updatevalue.bind(this)
    this.getUser = this.getUser.bind(this)
  }
  
  componentDidMount() {
    const { items } = this.state
    const { hasLogin} = this.props
    
    if( items.length === 0 ) {

      if(!hasLogin) {
        var session_key = null, user_id = null, page = "point"
        const params = service.getParamQuery()
        if( params['session'] ) {
          session_key = params['session']
        }
        if( params['id'] ) {
            user_id = params['id']
        }
        if( params['page'] ) {
            page = params['page']
        }
        if(session_key&&user_id) {
          service.session_key = session_key 
          this.getUser( parseInt( user_id , 10 ))
        } 
      }

      this.init()
    }
  }

  componentWillUnmount() {
    clearInterval( this.interval )
  }

  componentDidUpdate(props, state) {
    if( !state['selected'] && !this.state['selected'] ) {
      this.searchItem()
    }
  }

  async getUser(user_id) {
    const {updatevalue} = this.props
    let user = await service.getUser( user_id )
    if( user['id'] ) {
      service.user = { "user" : user }
      
      updatevalue({
        hasLogin : true,
        user : { "user" : user }
      })
    }
  }

  async init() {
    await this.searchItem()
    const {page} = this.state
    await this.pagnation( page )
    var _this = this
    if( this.interval ) {
      clearInterval( this.interval )
    }
    this.interval = setInterval( ()=>{
      const {cover, index} = _this.state
      _this.setState({
        index : ((index+1<cover.length)?(index+1):0)
      })
    }, 5000 )
    window.addEventListener( "resize", ()=>{
      if( window.innerWidth <= (300*16/9) ) {
        var _this = this
        setTimeout( () => {
          _this.setState({
            height : window.innerWidth * 9/16
          })
        }, 300 )
      } else {
        this.forceUpdate()
      }
    } )

    var _this = this
    setTimeout( () => {
      if( window.innerWidth <= (300*16/9) ) {
        console.log( window.innerWidth * 9/16 )
          _this.setState({
            height : window.innerWidth * 9/16
          })
      } else {
        this.forceUpdate()
      }
    }, 500)

  }

  async searchItem() {
    const item_id = window.location.pathname.replace("/shop/", "")
    if( !isNaN(item_id)  ) {
        //let item = await service.getShopItemById( item_id )
        //let item = await service.getShopItemById( item_id )
        let item = await service.getEShopItemById( item_id )
        if( item['enabled'] && new Date(item['stop_time']) >= new Date() ) {
          //item['price'] = parseFloat((2000 * Math.random()).toFixed(1), 10)
          //item['original'] = (Math.random() > 0.5)?
            //parseFloat((parseFloat(  item['price'], 10 )*0.9).toFixed(2))
            //:null
          this.setState({
            selected : service.massageAsset2(item)
          })
        }
    }
  }

  async pagnation(page) {
    const {cover} = this.state
    var {origin_items} = this.state
    const offset = ((page-1) * LIMIT )+ 0
    //let data = await service.getShopItems(offset, LIMIT)
    let data = await service.getEshopItems(offset, LIMIT)
    if( "list" in data) {
      /*
      data["list"] = data["list"].map( (v) => {
        v['price'] = parseFloat((2000 * Math.random()).toFixed(1), 10)
        v['original'] = (Math.random() > 0.5)?
          parseFloat((parseFloat(  v['price'], 10 )*0.9).toFixed(2))
          :null
        return v
      })
      */
      const _cover = data['list'].filter( (v) => ((v['priority'] > 999) && (v["ui_text"] && v["ui_text"]['cover']) && (v["asset"])) )
      const count = parseInt(data['count'], 10)

      origin_items = origin_items.concat( data['list'] )
      origin_items = origin_items.filter((value, index) => {
        return index === origin_items.findIndex(obj => {
          return obj['id'] === value['id'];
        });
      });

      this.setState({
        page : page,
        cover : cover.length>0?cover:_cover,
        items : data["list"],
        origin_items : data["list"],
        count : count,
        totalPage : Math.ceil( count/LIMIT  )
      })
    }
  }

  changeFilter( key, value ) {
    var { selectedFilter, origin_items, items, page } = this.state
    selectedFilter[key] = value
    var obj = {}
    obj[ 'selectedFilter'] = selectedFilter
    if( key === "price" ) {
      if( value !== "all" ) {
        const f = filters.filter( (v) => v['key'] === key )
        const val = f[0]['value'].filter( (v) => v['key'] === value )[0]
        items = origin_items.filter( (v) => v['price'] >= val['down'] && v['price'] <= val['up'] )
        obj['items'] = items
        obj['count'] = items.length
      } else {
        this.pagnation(page)
      }
    } else if ( key === "type") {
      //console.log(value)
      if( value !== "all" ) {
        items = origin_items.filter( (v) => {
          const {ui_text} = v
          if (ui_text && ui_text['keywords']  ) {
            const a = ui_text['keywords']['value'].split(",")
            return a.includes( value )
          } else {
            return false
          }
        } )
        obj['items'] = items
        obj['count'] = items.length
      } else {
        this.pagnation(page)
      }
    }
    this.setState(  obj )
  }

  updatevalue(obj, cb) {
    this.setState(obj, cb)
  }

  render() {
    const { brands, i18n, language, config, cart, placeOrder, updatevalue, user, hasLogin } = this.props
    const { cover, items, index, height, selectedFilter, totalPage, page, count, selected } = this.state
    try {
      return (
        <>
          <div className={["EShop", selected?"Lock":"" ].join(" ")}>
            <div className={["E-Carousel"]} style={{
              "--height" : `${height}px`
              //"height" : `${height}px`
            }}>  
              <div className={["E-Wrapper"].join(" ")}>
                {
                  cover.map( (v, k) => {
                    const {ui_text} = v
                    const length = Math.max(cover.length - 1, 1)
                    if( ui_text && ui_text["cover"] ) {
                      const {asset} = service.massageAsset2( v )
                      const selected = (k===index)
                      const abj = (k-index)
                      const width = height * 16/9
                      const abj1 = (Math.abs(abj) < cover.length/2)?abj:(  (abj >= cover.length/2)?-(cover.length-abj):-(Math.abs(abj)-cover.length)   )
                      const diff = (window.innerWidth * 1.25 - width)/length
                      const l = (window.innerWidth - width)/2 + ( ( abj1 )  * diff )
                      //alert( `ABJ : ${abj1} W : ${width}` )
                      if( asset && asset[ ui_text["cover"]["value"] ] ) {
                        return (
                          <div
                            key={k} 
                            className={["EC-Image-Holder", selected?"Selected":""].join(" ")}
                            onClick={()=>this.setState({index : k})} 
                            style={{ 
                              left : `${l}px` ,
                              zIndex : !selected?(length - Math.abs(abj1)):(length + 10),
                              transform : `scale( ${ 1 - Math.abs((abj1))*0.075 } )`,
                              filter: `brightness(${ selected?1:Math.max((0.5 - Math.abs((abj1))*0.075), 0.1) })`
                          }}>
                          <img 
                            className={["EC-Image", selected?"Selected":""].join(" ")}
                            src={ `${service.asset}/${asset[ ui_text["cover"]["value"] ]["file_path"]}` } />
                          </div>
                        )
                      } else {
                        return null
                      }
                    } else {
                      return null
                    }
                  } )
                }
              </div>
              <div className={["E-Dots"].join(" ")}>
                {
                  cover.map( (v,k) => {
                    return(
                      <div onClick={ ()=>this.setState({index : k}) } key={k}
                        className={["E-Dot", (k===index)?"E-Dot-Selected":""].join(" ")}></div>
                    )
                  })
                }
              </div>
            </div>
            <div className={["E-Content" ].join(" ")}>
              <div className={["E-Filter" ].join(" ")}>
                {
                  filters.map( (v,k) => {
                    return (
                      <select className={["E-F-Select" ].join(" ")} key={k} 
                        value={ selectedFilter[v["key"]] }
                        onChange={(e)=>{
                          this.changeFilter( v["key"], e.target.value )
                      }}>
                        {
                          v['value'].map( (v1, k1) => {
                            const value = `es_ft_${v1['value']}`
                            return (
                              <option value={v1['key']} key={k1}> { 
                                (value in i18n)?i18n[value][language]:v1['value'].toUpperCase() 
                              } </option>
                            )
                          } )
                        }
                      </select>
                    )
                  } )
                }
                <div className={["E-ShoppingCart"].join(" ")} onClick={ (e)=>{
                  updatevalue({ showCart : true })
                } }>
                  <FaShoppingCart className={["Fa"].join(" ")} />
                  <div className={["Num"].join(" ")}>{ cart.length }</div>
                </div>
              </div>

              <div className={["E-Total" ].join(" ")}>
                { (`${i18n['es_ft_total'][language]} : ${count}`).toUpperCase()  }
              </div>

              <div className={["E-Items-Holder" ].join(" ")}>
                {
                  items.map( (v,k) => {
                    const {ui_text} = v

                    if( ui_text && ui_text["cover"] ) {
                      const {asset} = service.massageAsset2( v )
                      var image = null
                      if( asset && asset[ ui_text["cover"]["value"] ] ) {
                        image = `${service.asset}/${asset[ ui_text["cover"]["value"] ]["file_path"]}`
                      }
                      var price_display = 0
                      const {price, remaining, tier_pricing, item_type } = v
                      var {original_price, discount_type} = ui_text
                      
                      var appendix = ""
                      if( tier_pricing ) {
                        if ( hasLogin ) {
                          const gp = tier_pricing.filter( (v)=>v['tier_level']===user['user']['tier_level'] )
                          if( gp.length > 0 ) {
                            appendix = original_price?
                              `${i18n[`es_member_price`][language]}:`:""
                            price_display = gp[0]['price']>-1?gp[0]['price']:price
                          } else {
                            price_display = price
                          }
                        } else {
                          appendix = original_price?
                              `${i18n[`es_member_price`][language]}:`:""
                          price_display = tier_pricing[0]['price']>-1?tier_pricing[0]['price']:price
                        }
                      } else {
                        appendix = original_price?
                                       `${(discount_type?i18n[`es_${discount_type['value']}_price`][language]:i18n[`es_special_price`][language])}:`:""
                        price_display = price
                      }

                      return (
                        <div className={["E-Item", (remaining===0)?"Soldout":"" ].join(" ")} key={k} onClick={ ()=> {
                          if( remaining > 0 ) {
                            this.setState({ selected : v})
                          }
                        } }>
                          {
                            remaining === 0 &&
                            <div className={["Soldout-Text"].join(" ")}><div className={["Text"].join(" ")}>{ i18n["es_soldout"][language] }</div></div>
                          }

                          <div className={["E-Carrier" ].join(" ")}>
                            { 
                              image &&
                              <img src={image} className={["E-I-Cover"].join(" ")} />
                            }
                            {
                              !image &&
                              <div src={image} className={["E-I-Cover", "Mask"].join(" ")} >{"COMMING SOON"}</div>
                            }
                            <div className={["E-I-Title"].join(" ")}> 
                                {
                                  item_type==="coupon" &&
                                  <span className={["E-Badge"].join(" ")}>{ i18n["es_coupon"][language] }</span>
                                }
                                { ui_text['display']?ui_text['display'][language]:v['name'] }
                            </div>
                            { ui_text['desc'] && ui_text['desc'][language].length > 0 &&
                              <div className={["E-I-Desc"].join(" ")}> { ui_text['desc'][language] } </div>
                            }
                            <div className={["E-I-Price"].join(" ")}>
                              { 
                                original_price && original_price['value'] &&
                                <div className={["Original"].join(" ")}>{`${i18n['es_original_price'][language]} : $ ${  parseFloat(original_price['value'], 10).toLocaleString()}`}</div>
                              }
                              <div className={["Price"].join(" ")}>
                                <span className={["Appendix"].join(" ")}>{ appendix }</span>
                                <span>{`$ ${price_display.toLocaleString()}`}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    } else {
                      return null
                    }
                  } )
                }
              </div>

              <div className={["E-Items-Pagnation" ].join(" ")}>
                {
                  Array.from( { length : totalPage , value : 1 }, (_, i)=>i ).map( (v, k) => {
                    return (
                      <div 
                        key={k}
                        onClick={ ()=>this.pagnation( k+1 ) }
                        className={["E-Page", (k+1)===page?"Selected":"" ].join(" ")}>{k+1}</div>
                    )
                  } )
                }
              </div>
            </div>

          </div>
          <Detail 
            {...this.props} 
            updateParent={this.updatevalue}
            placeOrder={placeOrder}
            selected={selected}/>
        </>
      )
    } catch(e){
      console.log(e)
      return null
    }
  }
}

export default EShop;


