import React, {Component} from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';




class Debug extends Component {
  constructor(props) {
    super(props)
    this.state={
      username : "",
      password : ""
    }
  }
  
  componentDidMount() {

    //var a = "lucky7Admin"
    //var p = "passw0rd"
    //var s = a + "|" + p
    //var v = window.btoa(s)

  }

  render() {
    return (
      <div className={["Debug"].join(" ")} onContextMenu={ (e)=>{
        e.preventDefault()
      } }>
        <div className={["D-Frame"].join(" ")}>
          <div className='D-Title'>{"Debug Mode"}</div>
          <input 
            placeholder='Username'
            className={['Input'].join()} value={this.state.username} onChange={(e)=>{ this.setState({ username : e.target.value }) }}/>
          <input 
            type="password"
            placeholder='Password'
            className={['Input'].join()} value={this.state.password} onChange={(e)=>{ this.setState({ password : e.target.value }) }}/>
          <div className='Submit' onClick={ () => {
              var a = this.state.username
              var p = this.state.password
              var s = a + "|" + p
              var v = window.btoa(s)
              //console.log(v)
              //console.log( this.props.passcode )
              if( v === this.props.passcode ) {
                this.props.updatevalue({ debugMode : false })
              }
          } }>{"Submit"}</div>
        </div>
      </div>
    )
  }
}

export default Debug;