import React, {Component} from 'react';
import './index.scss';
import service from '../../public/service';

class Status extends Component {
  constructor(props) {
    super(props)
    this.state={

    }


  }
  
  componentDidMount() {
    try {
      const { title } = this.props
      const response = window.alert( title )
      if( response === undefined ) {
        //window.location.href = window.location.origin
        //window.close()
      }
    } catch(e) {

    }
  }

  componentWillUnmount() {

  }


  render() {

    const { title, msg } = this.props
    return (
      <div className={["Success"].join(" ")} onClick={ ()=>{
        window.location.href = window.location.origin
      } }>
          <img 
              src={ window.logo } 
              className={["Logo"].join(" ")}/>

          <div className="Message"> { title } </div>
          {
            msg &&
            <div className="Message">  { msg } </div>
          }

      </div>
    )
  }
}

export default Status;


