import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import './index.scss';
import { FaTimesCircle } from 'react-icons/fa';
import service from '../../public/service';
import { register } from '../../serviceWorker';

const _FRAME_ = {
  "email" : ["email"],
}

class PrepareBuy extends Component {
  constructor(props) {
    super(props)
    this.state={
      type : "email",
      phone : "",
      region : "+852",
      errors : {}
    }
    this.change = this.change.bind(this)
  }
  
  componentDidMount() {    
  }

  componentWillUnmount() {
  }

  change(key, text) {
    const {type} = this.state
    var { data, updatevalue } = this.props
    //if() {
      data[key] = text
      updatevalue({
        data : data
      })
    //}

  }


  render() {
    const {showPrepareBuy, updatevalue, config, hasLogin, i18n, language, data, order } = this.props
    const {regions} = config
    const { type, errors } = this.state
    var obj = data

    const IGNORE_LOGIN = ((window.location.pathname.indexOf("/catering") > -1) )
    const ISCAIERING = ((window.location.pathname.indexOf("/catering") > -1) )

    var frame = JSON.parse( JSON.stringify( _FRAME_ ) )
    if( IGNORE_LOGIN ) {
      frame["email"].push( ["region", "phone"] )
    }

    return (
      <div className={["PrepareBuy", showPrepareBuy?"Show":"Hide"].join(" ")}>
        <div className={["Content"].join(" ")}>
          <FaTimesCircle onClick={()=>{
            updatevalue({
              showPrepareBuy : false
            })
          }} className={['Close-Btn'].join(" ")} />

          {
            hasLogin && 
            <>            
            </>
          }

          {
            (!hasLogin || IGNORE_LOGIN) && frame[type].map( (v,k) => {

              if( typeof(v) === "string" ) {
                return(
                  <div className={['Line'].join(" ")} key={k}>
                    <div className={["Title", (v in errors)?"Warn":"" ].join(" ")}>
                      <span>{ i18n[v][language]  }</span>
                      <span> { (v in errors)?i18n['required'][language]:"" } </span>
                    </div>
                    <input 
                      autoComplete={"new-password"}
                      type={ (v.indexOf("password") > -1)?"password":"text" }
                      onChange={(e)=>{
                        var valid = true
                        if( v==="6_digit" ) {
                          valid = service.validateNum(e.target.value)
                        }
                        if(valid)
                          this.change( v, e.target.value )
                      }}
                      className={["Input"].join(" ")} 
                      value={ (v in obj)?obj[v]:"" } />
                  </div>
                )
              } else {
                return(
                  <div className={['Tel'].join(" ")} key={k}>
                    <div className={['Line'].join(" ")}>
                      <div className={["Title", ("region" in errors)?"Warn":"" ].join(" ")}>
                                <span>{ i18n[  "country" ][language] }</span>
                                <span> { ("region" in errors)?i18n['required'][language]:"" } </span>
                      </div>
                      <select className='Region' 
                        value={ obj['region']?obj['region']:"" }
                        onChange={(e)=>{
                          
                          this.change( v[0], e.target.value )
                          //console.log(e.target.value)
                      }}>
                        {
                          regions.map((v2,k2)=>{
                            return(
                              <option value={v2['value']} key={k2}>{ v2['value'] }</option>
                            )
                          })
                        }
                      </select>
                    </div>
                    <div className={['Line'].join(" ")}>
                      <div className={["Title", (v[1] in errors)?"Warn":"" ].join(" ")}>
                              <span>{ i18n[  v[1] ][language] }</span>
                              <span> { (v[1] in errors)?i18n['required'][language]:"" } </span>
                      </div>
                      <input onChange={(e)=>{
                          if( service.validateNum(e.target.value)  ) {
                            this.change( v[1], e.target.value )
                          }      
                        }}
                        className={["Input"].join(" ")} 
                        value={ (v[1] in obj)?obj[v[1]]:"" } />
                    </div>
                  </div>
                )
              }
            } )
          }
          {
            !hasLogin && !IGNORE_LOGIN && (type === "email") &&
            <div onClick={()=>{
              updatevalue({
                showLogin : true,
                showPrepareBuy : false
              })
              }}
              className={["Link"].join(" ")}>{ 
                `${i18n[  "signin" ][language]} ?`
              }</div>
          }

          {
            (!hasLogin || IGNORE_LOGIN) &&
            <div onClick={ ()=>{
              
              if( 
                  ("email" in data) && 
                  data['email'] && (data['email'].length > 0) && 
                  service.validateEmail(data['email']) &&
                  ((("phone" in data) && (data['phone'].length > 7)) || !ISCAIERING)
              ) {
                var r = window.confirm( i18n['es_comfirm_email'][language] )
                if( r ) {
                  updatevalue({
                    showPrepareBuy : false
                  }, ()=>{
                    order()
                  })
                } 
              } else {
                if( !ISCAIERING) {
                  alert(i18n["es_invalid_email"][language])
                } else {
                  alert( i18n["invalid_phone_email_message"][language] )
                }
              }


              } } className={['Btn', "L"].join(" ")}>
                  { 
                    i18n['es_order'][language]
                  }
            </div>
          }
          


        </div>

        <div className={["Frame"].join(" ")} onClick={()=>{

          

            updatevalue({
              showPrepareBuy : false
            })


        }}></div>
      </div>
    )
  }
}

export default PrepareBuy;


/*
[
            {
                "id" : 0,
                "title" : { 
                        "eng" : "Redemption Branch (With Gift Set)",
                        "zhtc" : "月餅禮券發售及禮盒換領分店",
                        "zhcn" : "月饼礼券发售及礼盒换领分店" 
                },
                "stores" : {
                    "kingsdelicacy" : ["CWB", "TST", "KT", "TKO" ],
                    "godofteppanyaki" : ["CWB"]    
                }
            },
            {
                "id" : 1,
                "title" : { 
                        "eng" : "Redemption Branch\n(No Gift Set)",
                        "zhtc" : "月餅禮券發售分店\n(不設禮盒換領)",
                        "zhcn" : "月饼礼券发售分店\n(不设礼盒换领)" 
                },
                "stores" : {
                    "godofteppanyaki" : ["WC", "TST", "KT"]    
                }
            }
        ]
        */