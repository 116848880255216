import React, {Component} from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import service from '../../public/service';

import { FaMapMarkerAlt, FaChevronLeft, FaTimes, FaExpandAlt, FaChevronRight, FaPhoneAlt,FaWhatsapp, FaStore } from 'react-icons/fa';

class Shop extends Component {
  constructor(props) {
    super(props)
    this.state={
      show : false,
      index : 0
    }
    this.open = this.open.bind(this)
    this.shift = this.shift.bind(this)
  }
  
  componentDidMount() {
    //console.log(   this.props.body )
    this.props.updatevalue({ selected :  this.props.page  })
  }

  open(index) {
    this.setState({
      index : index,
      show : !this.state.show
    })
  }

  shift(previous) {
    var index  = this.state.index
    try {
      const { ui_text } = this.props.body
      const { gallery } = ui_text
      const arr = gallery['value'].split(",")
      if( previous) {
        index = ( (index-1) < 0 )?(arr.length-1):(index-1)
      } else {
        index = ( (index+1) > (arr.length-1) )?0:(index+1)
      }
      this.setState({
        index : index
      })
    } catch(e) {

    }
  }

  render() {
    
    const {parent, body, language, i18n, updatevalue, config} = this.props
    const {shop, brand_code, badge} = parent

    const {name, shop_code, asset, ui_text}  = body
    const {image, cover, gallery, display, business_hour_display, tel, location, address, whatsapp} = ui_text
    const url = service.asset
    return (
      <div className={["Shop", this.state.show?"Show":""].join(" ")} id={"shop"}>
        <div className={["Panel"].join(" ")}>
          <HashLink
            smooth
            scroll={(el) => {
              window.scrollTo(0,0)
              setTimeout( ()=>{
                el.scrollIntoView({ behavior: "auto", block: 'start' })
              }, 100)
              
            }}
            to={"/#brand"}
            className={["Back"].join(" ")}>
            <FaChevronLeft className={["Icon"].join(" ")} />
            <div className={['Text'].join(" ")}> { i18n['back'][language] } </div>
          </HashLink>
          <div className={["Shops-Holder"].join(" ")}>
            {
              shop &&
              shop.map( (v,k) => {
                const { ui_text, asset } = v
                const {cover, display} = ui_text
                return(
                  <Link 
                    key={k}
                    onClick={ () => window.scrollTo(0,0) }
                    to={ "/brand/" +brand_code.toLowerCase() + "/shop/" + v["shop_code"].toLowerCase() }
                    className={["Shop-Frame"].join(" ")} > 
                    { 
                      (cover && asset[cover['value']]) &&
                      <img className={["Icon"].join(" ")} src={ 
                        `${url}/${ asset[cover['value']]['file_path'] }`
                      }  />
                    }
                    {
                      !(cover && asset[cover['value']]) &&
                      <FaStore className={["Icon", "Fa"].join(" ")} />
                    }
                    <div className={["Text"].join(" ")}>  { display?display[language]:name }  </div>
                  </Link>
                )
              } )
            }
          </div>
        </div>
            
        {
          body && body['reservation_setting'] && body['reservation_setting']['open'] && config['booking'] &&
          <div className={["Booking-Wrapper"].join(" ")}>  
            <div className={["Booking-Btn"].join(" ")} 
              onClick={
                () => {
                  updatevalue({
                    shopId : body['id'],
                    showBooking : true
                  })
                }
              }
            >
              { i18n['insert_a_reservation'][language] }
            </div>
          </div>  
        }

        <div className={["Upper"].join(" ")}>
          <div className={["Left"].join(" ")}>
            {
              (parent['ui_text'] && parent['ui_text']["image"] && parent.asset[ parent['ui_text']["image"]['value']  ] ) &&
              <img className={['Logo']} src={ 
                `${url}/${ parent["asset"][ parent['ui_text']["image"]['value']  ]['file_path'] }`
              }/>
            }
            <div className={["Badges"].join(" ")}>
              {
                badge &&
                badge[language].split("|").map( (v1, k1) => {
                  return (
                    <div className={["Badge"].join(" ")} key={k1}>
                      {v1}
                    </div>
                  )
                } )
              }
            </div>
            <div className={["Address-Frame"].join(" ")}>
              {
                location &&
                <a className={["Name"]} href={ location['value'] } target={"_blank"}>
                  <FaMapMarkerAlt className={["Icon"]} />
                  <div className={["Text"]}> { display[language] }  </div>
                </a>      
               }
              {
                address &&
                <div className={['S-Detail'].join(" ")}>
                  { address[language]  }
                </div>
              }
            </div>
            {
              tel &&
              <a className={['Tel'].join(" ")} onClick={ () => {
                var a = tel['value'].replace(/ /g, "").split(")")
                if( a.length > 1 ) {
                  window.open( ('tel:' + a[1]), "_self" )
                }
              }} >

                <FaPhoneAlt className={["Icon"].join(" ")} />
                <div className={['Text'].join(" ")} >{ i18n['tel'][language].replace( "<phone>", tel['value']  )  }</div>
              </a>
            }
            {
              whatsapp &&
              <a className={['Tel'].join(" ")} onClick={ () => {
                window.open( ('https://wa.me/' + whatsapp['value'].replace(/ /g, "").replace("+", "").replace("(", "").replace(")", "")  ), "_blank" )
              }} >
                <FaWhatsapp className={["Icon"].join(" ")} />
                <div className={['Text'].join(" ")} >{ whatsapp['value'] }</div>
              </a>
            }

            { (true === false) && (this.props.body.food || this.props.body.drink) && 
              <div className={["Menu-Frame"].join(" ")}>
                <div className={["Title"].join(" ")}> { this.props.i18n["menu"][this.props.language] } </div>
                { this.props.body.food &&
                  <a
                    target={"_brank"} 
                    href={ window.location.origin + "/resources/menu/" + this.props.parent.key + "/" + this.props.body.id + "/" + this.props.body.id + ".pdf" }
                    className={["Link"].join(" ")}> { this.props.i18n["food"][this.props.language] } </a>
                }
                { this.props.body.drink &&
                  <a 
                    target={"_brank"} 
                    href={ window.location.origin + "/resources/menu/" + this.props.parent.key + "/" + this.props.body.id + "/" + this.props.body.id + "D.pdf" }
                    className={["Link"].join(" ")}> { this.props.i18n["drink"][this.props.language] } </a>
                }
              </div>
            }

            {
              business_hour_display &&
              <div className={['Operation'].join(" ")}>
                <div className={['Title'].join(" ")}>  {   i18n['operation'][language]  } </div>
                <div className={['Daytime'].join(" ")}>  
                  {   
                    business_hour_display[language].split("\\n").map( (v,k) => {
                      return (
                        <div className={["Line"].join(" ")} key={k}>{v}</div>
                      )
                    })  
                  } 
                </div>
              </div>
            }
          </div>
          <div className={["Right"].join(" ")}>
            { (cover && asset[cover['value']]) &&
              <img className={['S-Cover'].join(" ")} src={ 
                  `${url}/${ asset[ cover['value'] ]['file_path'] }`
              } />
            }
            { !(cover && asset[cover['value']])  &&
              <div className={['S-Cover', "Mock"].join(" ")}>
                { i18n['commingsoon'][language] }
              </div>
            }
          </div>
        </div>
        
        <div className={["Bottom"].join(" ")}>
                  {
                    gallery &&
                    gallery["value"].split(",").map( (v,k) => {
                      try {
                        return(
                          <div className={['Holder'].join(" ")} key={k}>
                            <img 
                              onClick={ (e)=>{
                                this.open(k)
                              } }
                              className={["Image"].join(" ")} 
                              src={  
                                `${url}/${asset[v]["file_path"]}`
                              } />
                          </div>
                        )
                      } catch(e) {
                        return null
                      }
                    } )
                  }
        </div>

        <div className={ ['Gallery', this.state.show?"Show":""].join(" ") }>
          <div className={["Controls"].join(" ")} >
            <FaExpandAlt className={["Expand"].join(" ")}/>
            <FaTimes className={["Close"].join(" ")} onClick={(e)=>{
              this.open(0)
            }}/>
          </div>
          <div className={["Holder"].join(" ")} >
            <FaChevronLeft 
              onClick={ (e)=>this.shift(true) } 
              className={["Left"].join(" ")} />
            <div className={['Middle'].join(" ")}>
              {
                gallery &&
                gallery["value"].split(",").map((v,k)=>{
                  return(
                    <img
                      key={k}  
                      className={["Image", this.state.index===k?"Selected":"" ].join(" ")} 
                      src={ 
                        `${url}/${asset[v]["file_path"]}`
                      } />
                  )
                })
              }
            </div>
            <FaChevronRight 
              onClick={ (e)=>this.shift(false) }
              className={["Right"].join(" ")} />
          </div>
        </div>
      </div>
    )
  }
}

export default Shop;


/*
<ResponsiveMasonry
                columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}}
            >
                <Masonry>
                  {
                    this.props.body.images.map( (v,k) => {
                      return(
                        <img 
                          onClick={ (e)=>{
                            this.open(k)
                          } }
                          key={k}
                          className={["Image"].join(" ")} 
                          src={  window.location.origin + "/resources/img/brand/" + this.props.parent.key + "/"  + this.props.body.id + "/" + v } />
                      )
                    } )
                  }
                </Masonry>
            </ResponsiveMasonry>
*/