import React, {Component} from 'react';
import './index.scss';
import service from '../../../public/service';
import { Link } from 'react-router-dom'
import {FaTimes, FaMinus, FaPlus, FaTimesCircle} from "react-icons/fa"

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class Cart extends Component {
  
  constructor(props) {
    super(props)
    this.state={
      //cart : []
    }
    this.init = this.init.bind(this)
    this.selectBrand = this.selectBrand.bind(this)
    this.onChange = this.onChange.bind(this)
    this.remove = this.remove.bind(this)
  }
  
  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(props, state) {
    //if( JSON.stringify(state.cart) !== JSON.stringify( props.cart )  ) {
    //  this.setState({ cart : props.cart})
    //}
  }

  init() {
  }

  checkPickupDates(dates, shop_code) {
    try {
      const {brands} = this.props
      
      const shops = service.pickupShops( brands )
      const s = shops.filter( (v)=>v['shop_code']===shop_code )[0]
      
      const {ui_text} = s
      const pickup_dates = dates
      var _pickup_dates = []
      if( ui_text['business_hour'] ) {
        const bh = ui_text['business_hour']['value'].split("|")
        const days = bh[0].split(",")
        _pickup_dates = pickup_dates.filter( (v)=> days.includes(   ( ((new Date(v)).getDay()===0)?7:((new Date(v)).getDay()) ).toString()    ) )
      } else {
        _pickup_dates = pickup_dates
      }
      return _pickup_dates
    } catch(e) {
      return []
    }
  }

  selectBrand(index, id) {
    const { brands,language, updatecart, cart } = this.props
    const s_brand = brands.filter( (v) => v['id'] === parseInt(id, 10) )
    var shops = []
    if( s_brand.length > 0 ) {
      shops = s_brand[0]['shop']
    }
    cart[index]['pickup']["brand"] = parseInt(id, 10)
    cart[index]['pickup']["shop"] = parseInt( shops[0]['id'], 10)
    updatecart( cart, () => {
      this.setState({
        shops : shops
      })
    } )
  }

  onChange(index, type, key, v) {
    const {updatecart, cart } = this.props
    if( type === "pickup" ) {
      cart[index][type][key] = v
    } else {
      cart[index][type] = v
    }
    updatecart( cart )
  }

  remove(v, k) {
    var {updatecart, cart } = this.props
    cart.splice( k, 1)
    updatecart( cart )
  }




  render() {
    const { brands, i18n, language, config, cart, showCart, updatevalue, eshopOrder, hasLogin, user } = this.props
    var total = 0
    return (
      <div className={["Cart", showCart?"Show":"" ].join(" ")}>
        <div className={["C-Header"].join(" ")}>
          <div className={["C-I-Holder"  ].join(" ")} onClick={()=>{
                  //this.init()
              updatevalue( { showCart : false } )
           }}> 
            <FaTimes className={["Fa"].join(" ")}/>
            <span>  { `${i18n["close_window"][language]}` } </span>
          </div>
        </div>
        <div className={["C-Content"].join(" ")}>
          <div className={["C-Title"].join(" ")}> { `${i18n['es_ct_cart'][language]} (${cart.length})` } </div>
          <div className={["C-Holder"].join(" ")}>
            {
              cart.map( (v,k) => {
                try {
                  var valid_day_before = 0
                  const { quantity, pickup, item, add_amount } = v
                  const { ui_text, asset, pickup_dates, price, tier_pricing, item_type } = service.massageAsset2(item)
                  const image = (ui_text && ui_text["cover"] && asset && asset[ui_text["cover"]['value']])?
                                  `${service.asset}/${asset[ui_text["cover"]['value']]['file_path']}`:null
                                  const name =  (ui_text && ui_text["display"])?ui_text["display"][language]:item['name']         
                  const { brand, shop, date } = pickup


                  
                  var shops = service.pickupShops( brands, item['pickup_shop_codes'] )

                  

                  var shop_name = ""
                  try {
                    const s = shops.filter( (v)=> v['shop_code'] === shop )[0]
                    var shop_name = (s['ui_text'] && s['ui_text']['display'])?s['ui_text']['display'][language]:s['name']
                  } catch(e) {
                  }

                  if( ui_text && ui_text['valid_day_before'] ) {
                    valid_day_before = parseInt( ui_text['valid_day_before']['value'], 10  )
                  }

                  const includeDates = this.checkPickupDates( pickup_dates, shop  )
                  var start = service.timeDiff(includeDates[ 0 ] , valid_day_before * 24 * 60  )
                  const minDate = Math.max(new Date( start ) ,new Date())
                  const _includeDates = includeDates.map( (v) => new Date( v ) )
                  var price_display = 0
                  if(  tier_pricing ) {
                    if( hasLogin ) {
                      const gp = tier_pricing.filter( (v) => v['tier_level'] === user['user']['tier_level'] )
                      if( gp.length > 0 ) {
                        price_display = gp[0].price>-1?gp[0].price:price
                      }
                    }  else {
                      price_display = (tier_pricing[0].price > -1)?tier_pricing[0].price:price
                    }
                  } else {
                    price_display = price
                  }
                  

                  total += ((price_display +add_amount) * quantity)

                  return (
                    <div className={["Line"].join(" ")} key={k}>
                      <div className={["L-C"].join(" ")}>
                        {
                          image && <Link to={`shop/${item['id']}`} >
                            <img src={image} className={["L-Img"].join(" ")}/>
                          </Link>
                        }
                        {
                          !image && <Link to={`shop/${item['id']}`} >
                            <div className={["L-Img"].join(" ")}></div>
                          </Link>
                        }
                      
                        <div className={["L-Col"].join(" ")}>
                          <Link to={`shop/${item['id']}`} >
                            <div className={["L-Title"].join(" ")}>
                                {
                                  item_type==="coupon" &&
                                  <span className={["L-Badge"].join(" ")}>{ i18n["es_coupon"][language] }</span>
                                }
                                {name}
                            </div>
                          </Link>
                          <div className={["L-Pickup"].join(" ")}> 
                            {
                              item_type !== "coupon" &&
                              <>
                                <select className={['Select'].join(" ")} value={ shop } onChange={(e)=>{
                                  //this.changeShop(k, "pickup", "shop", e.target.value)
                                  this.onChange( k, "pickup", "shop", e.target.value )
                                  this.onChange( k, "pickup", "date", null )
                                }}>
                                  <option value={""}>{i18n['es_pu_point'][language]}</option>
                                  {
                                    shops.map( (v1,k1) => {         
                                      try {
                                        const name = (v1['ui_text'] && v1['ui_text']['display'])?v1['ui_text']['display'][language]:v1['name']
                                        return (
                                          <option key={k1} value={ /*v1['id']*/ 
                                            v1['shop_code']
                                          }>{name}</option>
                                        )
                                      } catch(e) {
                                        return null
                                      }
                                    } )
                                  }
                                </select>
                                <DatePicker 
                                  dateFormat={'dd/MM/yyyy'}
                                  includeDates={ _includeDates }
                                  wrapperClassName={"Calendar"}
                                  minDate={ minDate}
                                  selected={ date } 
                                  placeholderText={i18n['es_pu_date'][language]}
                                  onChange={(date) =>{
                                    this.onChange( k, "pickup", "date", date )
                                  }} />
                              </>
                            }

                          </div>
                        </div>
                      </div>

                      <div className={["L-Q-Col"].join(" ")}>
                        <div className={["L-Q-Price"].join(" ")}> { `$ ${ ((price_display+add_amount) * quantity).toLocaleString(undefined, {
                          minimumFractionDigits: 1,
                          maximumFractionDigits: 1
                        }) }` } </div>
                        <div className={["L-Q-Wrapper"].join(" ")}>
                            {
                              item_type !== "coupon" &&
                              <div className={["Fa-Wrapper"].join(" ")} onClick={ ()=>{
                                if( quantity >= 1 ) {
                                  this.onChange( k, "quantity", "", quantity-1 )
                                }
                              } }>
                                <FaMinus className={["Fa"].join(" ")}/>
                              </div>
                            }
                            <input
                              className={["Quantity"].join(" ")} 
                              value={quantity} onChange={ (e)=>{
                              if ( item_type !== "coupon"  ) {
                                if( service.validateNum(e.target.value) || e.target.value.length === 0 ) {
                                  var q = (e.target.value.length !== 0)?parseInt( e.target.value , 10):0
                                  this.onChange( k, "quantity", "", q )
                                }
                              } 
                            } } />
                            {
                              item_type !== "coupon" &&
                              <div className={["Fa-Wrapper"].join(" ")} onClick={ ()=>{
                                this.onChange( k, "quantity", "", quantity+1 )
                              } }>
                                <FaPlus className={["Fa"].join(" ")}/>
                              </div>
                            }
                        </div>
                      </div>

                      <div className={"L-Remove"} onClick={()=>{
                        this.remove(v, k)
                      }}>
                          <FaTimesCircle className={"L-Remove-Fa"}  />
                      </div>
                    </div>
                  )
                } catch(e) {
                  console.log(e)
                  return (
                    <div className={["Line", "Error"].join(" ")}>{"Error"}</div>
                  )
                }
              } )
            }
          </div>
          <div className={["L-Buy"].join(" ")} onClick={ ()=>{
            //console.log("purchase")
            eshopOrder()
          } }>
            <div className={["btn", (cart.length===0)?"Disabled":""].join(" ")}>{`${i18n['es_ct_buy'][language]} - $${ total.toLocaleString(undefined,{
              minimumFractionDigits : 1,
              maximumFractionDigits : 1
            })  }`}</div>
          </div>
        </div>

      </div>
    )
  }
}

export default Cart;


