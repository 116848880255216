import React, {Component} from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';

import '../../public/service'
import service from '../../public/service';


class Terms extends Component {
  constructor(props) {
    super(props)
    this.state={
      messages : null,
    }
  }
  
  componentDidMount() {
    service.fetchFile("other/"+this.props.page+".json").then(
      (res) => {
        this.setState({
          messages : res
        })
      }
    )
  }

  componentDidUpdate(props, state) {
    if( this.props.page !== props.page ) {

      
      service.fetchFile("other/"+this.props.page+".json").then(
        (res) => {
          this.setState({
            messages : res
          })
        }
      )
    }
  }

  render() {
    var content ={}
    if( this.props.page ) {
      content = this.props.content[ this.props.page ]
      document.title =  ((this.props.language in content .display)?(content .display[this.props.language] + " | "):"") + this.props.config['general']['name'][this.props.language]
      
    }
    if( this.state.messages ) {
      var messages = this.state.messages[this.props.language].split("\n")
      return (
        <div className={["Terms"].join(" ")}>
          <div className={["T-Header"].join(" ")}>
            { ("display" in content)?content.display[this.props.language]:"" }
          </div>
          <div className={["Wrapper"].join(" ")}>
            {
              messages.map( (v,k) => {
                var a = v.split("\t")
                if( a.length === 1 ) {
                  return (
                    <div key={k} className={["Sentence"].join(" ")}> 
                      <div className={["Title"].join(" ")}> { a[0] } </div>
                    </div>
                  )
                } else {
                  return (
                    <div key={k} className={["Sentence"].join(" ")}> 
                      {
                        a.map( (val, key) => {
                          if( val === "") {
                            return (
                              <div className={["Empty"].join(" ")} key={key}>
                              </div>
                            )
                          } else {
                            return (
                              <div className={["Text"].join(" ")} key={key}>
                                {val}
                              </div>
                            )
                          }
                        })
                      }
                    </div>
                  )
                }

              } )
            }
          </div>
        </div>
      )
    } else {
      return null
    }
  }
}

export default Terms;


