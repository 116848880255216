import React, {Component} from 'react';
import './index.scss';
import service from '../../../public/service';
import {FaTimes, FaMinus, FaPlus, FaTimesCircle} from "react-icons/fa"

import Detail from '../Detail';

class ToCart extends Component {
  
  constructor(props) {
    super(props)
    this.state={
      //cart : []
      showPopup : false,
      selectedItem : null,
      selectedShop : null,
      selected : null,
    }
    this.init = this.init.bind(this)
    this.updatevalue = this.updatevalue.bind(this)
    this.onChange = this.onChange.bind(this)
    this.remove = this.remove.bind(this)

    this.getBrandName = this.getBrandName.bind(this)
    this.selectItem = this.selectItem.bind(this)
  }
  
  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(props, state) {
    //if( JSON.stringify(state.cart) !== JSON.stringify( props.cart )  ) {
    //  this.setState({ cart : props.cart})
    //}
  }

  init() {
    this.setState({
      showPopup : false,
      selectedItem : null,
      selectedShop : null,
      selected : null
    })
  }

  getBrandName(key) {
    const {brands, language} = this.props
    var name = key
    for (var b of brands) {
      if( b['shop'] ) {
        for ( var s of b['shop']) {
          if( s['shop_code']  === key) {
            const {ui_text} = s
            name = ui_text['display']?ui_text['display'][language]:key
            break
          }
        }
      }
    }
    return name
  }

  updatevalue(obj, cb) {
    this.setState(obj, cb)
  }

  onChange(index, type, key, v, shopcode) {
    const {updateMenuOrder, menuorder } = this.props

    console.log( menuorder[shopcode] )
    console.log( index )
    if( shopcode in menuorder ) {
      menuorder[shopcode][index][type] = v
    }

    updateMenuOrder( menuorder  )
  }

  remove(v, k, shopcode) {
    var { updateMenuOrder, menuorder } = this.props
    
    if( shopcode in  menuorder) {
      menuorder[shopcode].splice( k, 1)
      if(menuorder[shopcode].length === 0) {
        delete menuorder[shopcode]
      }

      updateMenuOrder( menuorder )
    }
  }

  selectItem(item, showcode, index) {
    item['index'] = index
    this.setState({
      selectedItem : item,
      selected : item['item'],
      selectedShop : showcode,
      showPopup : true
    })
  }

  render() {
    const {showPopup, selectedShop, selectedItem, selected} = this.state
    const { gi18n, language, menuorder, showMenuOrder, updatevalue, updateParent, hasLogin, user, togoOrder } = this.props
    var total = 0

    var length = 0
    for ( var i in menuorder ) {
      length += menuorder[i].length
    }


    return (
      <div className={["ToCart", showMenuOrder?"Show":"" ].join(" ")}>
        <div className={["C-Header"].join(" ")}>
          <div className={["C-I-Holder"  ].join(" ")} onClick={()=>{
                  //this.init()
              //updatevalue( { showCart : false } )
              updateParent({ showMenuOrder : false })
           }}> 
            <FaTimes className={["Fa"].join(" ")}/>
            <span>  { `${gi18n("close_window")}` } </span>
          </div>
        </div>
        <div className={["C-Content"].join(" ")}>
          <div className={["C-Title"].join(" ")}> { `${ gi18n('to_entitle_count') } (${length})` } </div>
          <div className={["C-Holder"].join(" ")}>
            {
              Object.keys( menuorder ).map( (val, key) => {
                return (
                  <div className={["C-Shop"].join(" ")} key={key}>
                    <div className={["C-S-Title"].join(" ")}> { this.getBrandName(val) } </div>
                    {
                      menuorder[val].map( (v,k) => {
                        try {
                          var valid_day_before = 0
                          const { quantity, pickup, item, add_amount } = v
                          const { ui_text, asset, pickup_dates, price, tier_pricing, item_type } = service.massageAsset2(item)
                          const image = (ui_text && ui_text["cover"] && asset && asset[ui_text["cover"]['value']])?
                                          `${service.asset}/${asset[ui_text["cover"]['value']]['file_path']}`:null
                                          const name =  (ui_text && ui_text["display"])?ui_text["display"][language]:item['name']         
                          
                          var price_display = 0
                          if(  tier_pricing ) {
                            if( hasLogin ) {
                              const gp = tier_pricing.filter( (v) => v['tier_level'] === user['user']['tier_level'] )
                              if( gp.length > 0 ) {
                                price_display = gp[0].price>-1?gp[0].price:price
                              }
                            }  else {
                              price_display = (tier_pricing[0].price > -1)?tier_pricing[0].price:price
                            }
                          } else {
                            price_display = price
                          }
                          

                          total += ((price_display +add_amount) * quantity)


                          return (
                            <div className={["Line"].join(" ")} key={k}>
                              <div className={["L-C"].join(" ")}>
                                {
                                  image && <img src={image} className={["L-Img"].join(" ")} onClick={()=>{
                                    this.selectItem( v, val, k )
                                  }}/>
                                }
                                {
                                  !image && <div className={["L-Img"].join(" ")} onClick={()=>{
                                    this.selectItem( v, val, k )
                                  }}></div>
                                }
                              
                                <div className={["L-Col"].join(" ")} onClick={()=>{
                                    this.selectItem( v, val, k )
                                  }}>
                                    <div className={["L-Title"].join(" ")}>
                                        {name}
                                    </div>
                                </div>
                              </div>

                              <div className={["L-Q-Col"].join(" ")}>
                                <pre className={["L-Q-Price"].join(" ")}> { `$ ${ ((price_display+add_amount) * quantity).toLocaleString(undefined, {
                                  minimumFractionDigits: 1,
                                  maximumFractionDigits: 1
                                }) }` } </pre>
                                <div className={["L-Q-Wrapper"].join(" ")}>
                                    {
                                      item_type !== "coupon" &&
                                      <div className={["Fa-Wrapper"].join(" ")} onClick={ ()=>{
                                        if( quantity-1 >= 0 ) {
                                          this.onChange( k, "quantity", "", quantity-1, val )
                                        }
                                      } }>
                                        <FaMinus className={["Fa"].join(" ")}/>
                                      </div>
                                    }
                                    <input
                                      className={["Quantity"].join(" ")} 
                                      value={quantity} onChange={ (e)=>{
                                      if ( item_type !== "coupon"  ) {
                                        if( service.validateNum(e.target.value) || e.target.value.length === 0 ) {
                                          var q = (e.target.value.length !== 0)?parseInt( e.target.value , 10):0
                                          this.onChange( k, "quantity", "", q, val )
                                        }
                                      } 
                                    } } />
                                    {
                                      item_type !== "coupon" &&
                                      <div className={["Fa-Wrapper"].join(" ")} onClick={ ()=>{
                                        this.onChange( k, "quantity", "", quantity+1, val )
                                      } }>
                                        <FaPlus className={["Fa"].join(" ")}/>
                                      </div>
                                    }
                                </div>
                              </div>

                              <div className={"L-Remove"} onClick={()=>{
                                this.remove(v, k, val )
                              }}>
                                  <FaTimesCircle className={"L-Remove-Fa"}  />
                              </div>
                            </div>
                          )
                        } catch(e) {
                          return (
                            <div className={["Line", "Error"].join(" ")}>{"Error"}</div>
                          )
                        }
                      } )
                    }
                  </div>
                )
              } )
            }

            
          </div>
          <div className={["L-Buy"].join(" ")} onClick={ ()=>{
            //console.log("purchase")
            //eshopOrder()
            togoOrder()
          } }>
            <div className={["btn", (menuorder.length===0)?"Disabled":""].join(" ")}>{`${ gi18n('to_entitle_checkout') } - $${ total.toLocaleString(undefined,{
              minimumFractionDigits : 1,
              maximumFractionDigits : 1
            })  }`}</div>
          </div>
        </div>

        <div className={["To-Popup", showPopup?"Show":"" ].join(" ")}>
          <div className={["Frame" ].join(" ")} onClick={()=>this.init()}> </div>
          <div className={["Holder" ].join(" ")}>
            {
              selected &&
              <Detail 
                  {...this.props} 
                  updateParent={this.updatevalue}
                  selected={selected}
                  selectedShop={selectedShop}
                  selectedItem={selectedItem}/>
            }
          </div>
        </div>

      </div>
    )
  }
}

export default ToCart;


