import React, {Component} from 'react';
import './index.scss';
import service from '../../../public/service';
import { AiOutlineWhatsApp,  AiOutlineMail, AiOutlineCopy } from 'react-icons/ai';
import { FaChevronCircleLeft, FaChevronCircleRight } from 'react-icons/fa';

//`${window.location.origin}/wallet/topup?session=${session_key}&id=${user_id}&language=${language}`
var html = `
<div style="display: flex;flex-direction: column;align-items: center;">
    <div style="position: relative;height : 100%;width : calc(100% - 2rem);max-width: 951px;display: flex;flex-direction: column;transition: all 0.25s ease-in-out;background-color: transparent;padding: 0rem 1rem;padding-bottom: 2rem;min-height: calc(100vh - 2rem);margin: auto;">
        <div style="background-image:url(https://lucky7-lambda.s3.ap-southeast-1.amazonaws.com/assets/gifts/frame/{value_fr}.png);position: relative;flex-shrink: 0;z-index : 1;height: 1;width: 300px;height : calc( 300px * 9 / 16 );background-position: center;background-size: 100%;background-repeat: no-repeat;margin: 2rem auto;border-radius: 0.75rem;border: 1px solid #E4DBC0;animation:tilt-shaking 3s linear infinite">
            <div style="position: absolute;top : 0.25rem;right : 0.5rem;z-index: 2;display: flex;flex-direction: row;justify-content: center;align-items: center;"> 
            <span style="font-size: small;color : #B28E43;margin-right: 0.25rem;">$</span>
            <span className='value'>{value_v}</span>
            </div>
        </div>
        <div style="position: relative; z-index : 2;margin: 0 auto;font-size: 180%;text-align: center;"> 
            <pre style="font-family: cursive;color : #2d184b;word-wrap: break-word;white-space: pre-wrap;">Dear {value_t}</pre>
            <h1  style="font-family: cursive;color : #2d184b;word-wrap: break-word;white-space: pre-wrap;">{value_m}</h1>
            <pre style="font-family: cursive;color : #2d184b;word-wrap: break-word;white-space: pre-wrap;">{value_a}</pre>
            <div >
                <pre style="font-family: cursive;color : #2d184b;word-wrap: break-word;white-space: pre-wrap;padding-top: 4rem;">From</pre>
                <pre style="font-family: cursive;color : #2d184b;word-wrap: break-word;white-space: pre-wrap;">{value_f}</pre>
            </div>        
        </div>
        <div style="position: absolute;top: 0px;left: 0px;width: 100%;height: 100%;z-index : 0;opacity: 0.25;background:radial-gradient(50% 50% at 100% 0,#7d5c9e 0%  5% ,#e3dabf 6%  15%,#7d5c9e 16% 25%,#e3dabf 26% 35%,#7d5c9e 36% 45%,#e3dabf 46% 55%,#7d5c9e 56% 65%,#e3dabf 66% 75%,#7d5c9e 76% 85%,#e3dabf 86% 95%,#0000 96%),radial-gradient(50% 50% at 0 100%,#7d5c9e 0%  5% ,#e3dabf 6%  15%,#7d5c9e 16% 25%,#e3dabf 26% 35%,#7d5c9e 36% 45%,#e3dabf 46% 55%,#7d5c9e 56% 65%,#e3dabf 66% 75%,#7d5c9e 76% 85%,#e3dabf 86% 95%,#0000 96%),radial-gradient(50% 50%,#7d5c9e 0%  5% ,#e3dabf 6%  15%,#7d5c9e 16% 25%,#e3dabf 26% 35%,#7d5c9e 36% 45%,#e3dabf 46% 55%,#7d5c9e 56% 65%,#e3dabf 66% 75%,#7d5c9e 76% 85%,#e3dabf 86% 95%,#0000 96%),radial-gradient(50% 50%,#7d5c9e 0%  5% ,#e3dabf 6%  15%,#7d5c9e 16% 25%,#e3dabf 26% 35%,#7d5c9e 36% 45%,#e3dabf 46% 55%,#7d5c9e 56% 65%,#e3dabf 66% 75%,#7d5c9e 76% 85%,#e3dabf 86% 95%,#0000 96%) 24px 24px;background-size: 48px 48px;background-color: #7d5c9e;"    ></div>
    </div>
</div>
`


const frames = [
  {"key" : "theme"},
  {"key" : "value"},
  {"key" : "to"},
  {"key" : "from"},
  {"key" : "message"},
  {"key" : "appendix"}
]

const pages = [
  {"key" : "find"},
  {"key" : "giftcard"},
  {"key" : "share"}
]

class Gift extends Component {
  constructor(props) {
    super(props)
    //console.log( props.config )
    this.state={
      page : 0,
      formData : {},
      isValidUser : false,
      phone : "",
      region : "+852",
      hasSearch : false,
      error : null,
      card_value : [],
      price_value : [],
      messages : [],
      hasTreat : false,
      url : null,
      //user_id : null
      target : {},

      errors : {}
    }
    this.init = this.init.bind(this)
    this.i18n = this.i18n.bind(this)
    this.createP = this.createP.bind(this)
    this.onChange = this.onChange.bind(this)
    this.changeTheme = this.changeTheme.bind(this)
    this.onPreview = this.onPreview.bind(this)
    this.onTreat = this.onTreat.bind(this)
    this.onChangePhone = this.onChangePhone.bind(this)
    this.sendEmail = this.sendEmail.bind(this)
    this.treat = this.treat.bind(this)

    this.copy = this.copy.bind(this)
    this.refresh = this.refresh.bind(this)

    this.giftView = this.giftView.bind(this)
    this.findView = this.findView.bind(this)
    this.shareView = this.shareView.bind(this)
    
  } 
  
  componentDidMount() {
    /*
    var {formData} = this.state
    const { card_value  } = this.props
    if( card_value.length > 0 ) {
      formData['theme'] = card_value[0]
      this.setState({
        formData : formData
      })
    }*/
    this.init()
  }

  componentDidUpdate( props, state ) {
    /*
    var {formData} = this.state
    if( state.formData !== formData || !state.formData['theme']  ) {
      if( !formData['theme'] ) {
        const { card_value  } = this.props
        formData['theme'] = card_value[0]
        this.setState({
          formData : formData
        })
      }
    }
    */
  }

  componentWillUnmount() {

  }

  i18n(key) {
    const {i18n, language} = this.props
    return (key in i18n)?i18n[key][language]:key
  }

  onChange(k, v) {
    var {formData} = this.state
    formData[k] = v
    this.setState({
      formData : formData,
      errors : {}
    })
  }

  changeTheme(next) {
    var {formData} = this.state
    var {card_value} = this.state
    var current = 0, _next = 0
    if( formData['theme'] ) {
      for (var i in card_value) {
        if ( formData['theme'] === card_value[i] ) {
          current = parseInt(i, 10)
          break
        }
      }
      
      if( next ) {
        if( current === card_value.length - 1 ) {
          _next = 0
        } else {
          _next = current + 1
        }
      } else {
        if( current === 0 ) {
          _next = card_value.length - 1
        } else {
          _next = current - 1
        }
      }
      formData['theme'] = card_value[_next]
    } else {
      formData['theme'] = card_value[0]
    }
    this.setState({
      formData: formData,
      errors : {}
    })
  }

  createP(check) {
    const {formData} = this.state
    var errors = {}
    if( Object.keys(formData).length === 6 || ((Object.keys(formData).length === 5) && !formData["appendix"]) && (formData["value"] !== "--") ) {
      var o = {}
      for (var i in formData ) {
        var valid = false
        if( i === "theme" ) {
          o[ "fr" ] = service.encode(formData[i])
          valid = true
        } else if( i === "appendix" ) {
          o[ i.substring(0,1) ] = service.encodeChar( formData[i] )
          valid = true
        } else if( i === "value" ) {
          if( formData[i].length > 0 && formData[i] !== "--" ) {
            o[ i.substring(0,1) ] = service.encodeChar( formData[i] )
            valid = true
          }
        } else {
          if( formData[i].length > 0 ) {
            o[ i.substring(0,1) ] = service.encodeChar( formData[i] )
            valid = true
          }
        }
        if( !valid && (i !== "appendix") ) {
          errors[i] = true
        }
      }

      

      if( Object.keys(o).length >= 5 ) {
        const r = service.encode(JSON.stringify(o))
        const url = `${window.location.origin}/giftcard?r=${r}`
        return url
      } else {
        if(check) {
          this.setState({ errors : errors })
        }
        return null
      }
    } else {
      if(check) {
        for (var i of frames) {
          if( i['key']!=="appendix" ) {
            if( !(i['key'] in formData) ) {
              errors[ i['key'] ] = true
            }
          }
        }
        this.setState({ errors : errors })
      }
      return null
    } 
  }

  onPreview() {
    const {updatevalue} = this.props
    const {formData} = this.state
    const url = this.createP(true)

    if( url  ) {

        updatevalue({
          showWebView : true,
          url : url
        })

    } else {
      alert( this.i18n("gc_empty_value")  )
      
    }
  }

  onTreat() {
    
    const {formData} = this.state
    const url = this.createP(true)

    if(url) {
      if( Object.keys(formData).length === 6 || ((Object.keys(formData).length === 5) && !formData["appendix"]) && (formData["value"] !== "--")  ) {
        this.treat()
      } 
    } else {
      alert(this.i18n("gc_empty_value"))
    }
    
  }

  async init() {
    let data = await service.fetchAsset("gifts", "card_index.json")
    this.setState({
      card_value : data['card_value'],
      price_value : data['price_value'],
      messages : data['messages'],
      formData : data['card_value'].length>0?{ "theme" : data['card_value'][0] }:{}
    })
  }

  async userfind() {
    const {user} = this.props    
    const {region, phone} = this.state
    var {formData} = this.state
    const _phone = `${region.replace("+","")}:${phone}`

    if( phone.length === 0 ) {
      alert( this.i18n("gc_empty_value") )
      return
    }

    if( _phone === user['user']['phone']  ) {
      alert( this.i18n("ph_tam_yourself") )
      return
    }
    
    let res = await service.userfind( _phone )
    var obj = {hasSearch : true}
    if("user_id" in res) {
      let data = await service.getUser(res['user_id'])
      obj["isValidUser"] = true 
      obj['page'] = 1
      obj['target'] = data
      formData['to'] = data['first_name']
      formData['from'] = user['user']['first_name']
      obj['formData'] = formData
    } else {
      if(res["msg"] === "invocation limit reached from this ip") {
        obj['error'] = this.i18n("gc_reach_limit")
      }
      obj["isValidUser"] = false
    }
    

    
    

    /*
    var obj = {hasSearch : true}
    obj["isValidUser"] = false
    obj['page'] = 1
    obj["isValidUser"] = true
    obj["user_id"] = 1
    let data = await service.getUser(1)
    obj['target'] = data
    formData['to'] = data['first_name']
    formData['from'] = user['user']['first_name']
    obj['formData'] = formData
    */


    this.setState(obj)
  }

  async treat() {
    const { target, formData} = this.state
    const {updatevalue, user, updateUser} = this.props
    var obj = {}
    obj['to_user_id'] = target['id']
    obj['token_amount_max'] = formData['value']

    //const msg = `Are you sure to send ${target['first_name']} a $${formData['value']} gift card?`
    const msg = this.i18n("gc_send_value_to_user").replace("<name>", ` ${target['first_name']} `).replace("<value>", ` ${formData['value']} `)

    const a = window.confirm(msg)
    let data = await service.treatAMeal(obj)
    //console.log(data)
    if ("id" in data) {
      if( a ) {
        this.setState({
          page : pages.length-1,
          hasTreat : true
        }, ()=>{
          updateUser()
        })
      } 
    } else {
      alert(this.i18n("ph_tam_fail"))
    }
    
  
  }

  copy(msg) {
    const tempElement = document.createElement("pre");
    tempElement.innerHTML = decodeURI(msg);
    document.body.appendChild(tempElement);
    // Select the HTML content
    const range = document.createRange();
    range.selectNode(tempElement);
    // Copy the selected HTML content to the clipboard
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    document.execCommand("copy");
    selection.removeAllRanges();
    document.body.removeChild(tempElement);
    alert( this.i18n("gc_copy_success") )

    
  }

  onChangePhone(value) {
    if( service.validateNum(value) ) {
      this.setState({ phone : value , hasSearch : false, isValidUser : false, error : null, errors : {}})
    }
  }

  async sendEmail(msg) {
    const {formData, user_id, target } = this.state

    //let data = await service.getUser(user_id)

    const email = `mailto:${ target['email'] }?subject=${formData['message']}&body=${msg}`
    window.open( email , "_new" )
  }

  refresh() {
    var {formData, card_value} = this.state
    formData['theme'] = card_value[0]
    this.setState({
      page : 0,
      formData : formData,
      isValidUser : false,
      phone : "",
      region : "+852",
      hasSearch : false,
      error : null,
      hasTreat : false,
      url : null,
      target : {},
      errors : {}
    })
  }



  findView() {
    const {  config, user } = this.props
    const { isValidUser, region, phone, hasSearch, error} = this.state
    const { regions } = config

    var msg = this.i18n('ph_tam_nonmember_invite').replace("<name>", `${user['user']['first_name']} `)
    const _phone = `${region.replace("+","")}:${phone}`
    return (
      <div className={["Find", "Container" ].join(" ")} > 
              <div className={["Find-Container"]}>
                <div className={["Find-Friend"]}> { this.i18n("gc_find_a_friend") } </div>
                <div className={["Phone-Wrapper"].join(" ")}>
                    <select className={["Phone-Region"].join(" ")}
                      alue={ region } onChange={ (e)=>this.setState({ region :  e.target.value}) }>
                      {
                        regions.map( (v,k) => {
                          return (
                            <option key={k} value={v['value']}>{ v['value'] }</option>
                          )
                        } )
                      }
                    </select>
                    <input 
                      className={["Phone-Input"].join(" ")} 
                      value={phone} 
                      onChange={ (e)=>this.onChangePhone(e.target.value) }/>
                </div>  
                <div className={["Button"].join(" ")} onClick={()=>this.userfind()}> { this.i18n('search') } </div>
                {
                  (phone.length > 0) && hasSearch && !isValidUser && error === null &&
                  <div className={["Share"].join(" ")}>  
                    <div className={["Share-Message"].join(" ")}>{ this.i18n('ph_tam_nonmember_msg1') }</div>
                    <div className={["Share-Message"].join(" ")}>{ this.i18n('ph_tam_nonmember_msg2').replace("<phone>", ` ${phone} `) }</div>
                    <div className={["Share-Message"].join(" ")}>{ this.i18n('ph_tam_nonmember_msg3') }</div>
                    <div className={["Share-Invite"].join(" ")}> 
                      {
                        /*
                        <a href={ `whatsapp://send?text=${msg}&phone=${phone}` }>
                          <AiOutlineMail className={['Fa'].join(" ")} />
                        </a>
                        <a href={ `whatsapp://send?text=${msg}&phone=${region}${phone}` }>
                        <AiOutlineWhatsApp className={['Fa'].join(" ")} />
                        </a>
                        */
                      }
                      <AiOutlineCopy className={["Fa"].join(" ")} onClick={ ()=>this.copy(msg)} />

                      <AiOutlineWhatsApp className={['Fa'].join(" ")} onClick={
                        () => {
                          window.open(`whatsapp://send?text=${msg}&phone=${region}${phone}`, "__new__" )
                        }
                      }/>
                    </div>
                  </div>
                } 
                {
                  (phone.length > 0) && hasSearch && !isValidUser && error !== null &&
                  <div className={["Share"].join(" ")}>
                    <div className={["Share-Message", "Red"].join(" ")}>{ error }</div>
                  </div>
                }
              </div>       
            </div>
    )
  }

  giftView() {
    const {formData,  card_value, price_value, messages, errors } = this.state
    return (
      <div className={["Gift", "Container"].join(" ")} >
              {
                frames.map( (v, k) => {
                  if( v['key'] === "theme" ) {
                    return (
                      <div key={k} className={["G-Wrapper"].join(" ")}> 
                        <div className={["G-Title", (v['key'] in errors)?"Error":""  ].join(" ")}> { this.i18n(`gc_${v['key']}`) } </div>
                        <div className={["G-Theme"].join(" ")}>
                          <div className={["G-Fa-Holder"].join(" ")} onClick={ () => this.changeTheme() }>
                            <FaChevronCircleLeft className={['G-Fa'].join(" ")}/>
                          </div>
                          <div className={["G-Image-Holder"].join(" ")}>
                              {
                                card_value.map( (i, ik) => {
                                  return (
                                    <img key={ik}
                                      className={['G-Image', ((i===formData["theme"])?"Selected":"") ].join(" ")} src={`${service.asset}/assets/gifts/frame/${i}.png`}/>
                                  )
                                } )  
                              }
                          </div>
                          <div className={["G-Fa-Holder"].join(" ")} onClick={ () => this.changeTheme(true) }>
                            <FaChevronCircleRight className={['G-Fa'].join(" ")}/>
                          </div>
                        </div>
                      </div>
                    )
                  } else if( v['key'] === "value" ) {
                    return (
                      <div key={k} className={["G-Wrapper"].join(" ")}> 
                        <div className={["G-Title", (v['key'] in errors)?"Error":"" ].join(" ")}> { this.i18n(`gc_${v['key']}`) } </div>
                        <select className='G-Select' value={ formData[ v['key'] ]?formData[ v['key'] ]:"" } onChange={ (e)=>{
                          this.onChange(v['key'], e.target.value)
                        } } >
                          <option value={"--"}>{this.i18n("gc_pick_one")}</option>
                          {
                            price_value.map( (v1, k1) => {
                              return (
                                <option key={k1} value={v1}>{ `$${v1.toLocaleString()}` }</option>
                              )
                            } )
                          }
                        </select>
                      </div>
                    )
                  } else if( v['key'] === "appendix" ) {
                    return (
                      <div key={k} className={["G-Wrapper"].join(" ")}> 
                        <div className={["G-Title"].join(" ")}> { this.i18n(`gc_${v['key']}`) } </div>
                        <textarea className={["G-TextArea"].join(" ")} value={ formData[ v['key'] ]?formData[ v['key'] ]:""  } onChange={ (e) => this.onChange(v['key'], e.target.value) }/>
                      </div>
                    )
                  } else {
                    return (
                      <div key={k} className={["G-Wrapper"].join(" ")}> 
                        <div className={["G-Title", (v['key'] in errors)?"Error":"" ].join(" ")}> { this.i18n(`gc_${v['key']}`) } </div>
                        <input className={["G-Input"].join(" ")} value={ formData[ v['key'] ]?formData[ v['key'] ]:""  } onChange={ (e) => this.onChange(v['key'], e.target.value) }/>
                        { 
                          v["key"] === "message" &&
                          <div className={["G-Badges"].join(" ")}>
                            {
                              messages.map( (v2,k2) => {
                                return (
                                  <div 
                                    onClick={
                                      () => {
                                        this.onChange("message",  this.i18n(`gc_${v2}_message`) )
                                      }
                                    }
                                    className={["G-Badge"].join(" ")} key={k2}> 
                                    {  this.i18n(`gc_${v2}_message`)  }
                                  </div>
                                )
                              } )
                            }
                          </div>
                        }
                      </div>
                    )
                  }
                } )
              }
              <div className={["G-Wrapper", "Row"].join(" ")} >
                <div className={["G-Button"].join(" ")} onClick={ () => this.onPreview() }>{this.i18n(`gc_preview_btn`)}</div>
                <div className={["G-Button"].join(" ")} onClick={ () => this.onTreat() }>{this.i18n(`gc_gift_btn`)}</div>
              </div>
            </div>
    )
  }

  shareView() {
    const {user, config  } = this.props
    const {formData, page, region, phone, target} = this.state

    const url = this.createP()
    //console.log( `whatsapp://send?text=${msg}&phone=${r_phone}` )
    //console.log(url)
    //http://localhost:3000/wallet?language=hk&session=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoyMTAsImNyZWF0aW9uX3RpbWUiOiIyMDI0LTAyLTI5IDEzOjMyOjQzIiwiZXhwaXJhdGlvbl90aW1lIjoiMjAyNC0wMy0wNyAxMzozMjo0MyIsImFkbWluX2dyb3VwIjoiU3lzdGVtIiwiZ3JvdXBzIjpbIlN0YWZmIiwiU3VwcG9ydCIsIlN5c3RlbSJdfQ.CVMbNTtBaxcvfdSZ31euppbSZ5YxhgwXBG6qGo7LvN8&id=210&popup=0&header=0&footer=0&phone=852:90221206
    if(page === 2 && !url ) {
      this.setState({
        hasTreat : false, 
        page : 1
      })
      return
    } else {
      const phone_display = user?`+${user['user']['phone'].replace(":", " ")}`:""
      const msg = url?
                ( encodeURI(`${user['user']['last_name']}, ${user['user']['first_name']} (${phone_display})\n${this.i18n('et_treat_you_a_meal')}\n${this.i18n('wallet_token_amount')} : $${formData['value']}\n\nDownload : https://www.lucky7h.hk/forward \n\nMessage:\n${url}`) ):
                ""
      const r_phone = `${region}${phone}`

      return (
          <div className={["Share", "Container"].join(" ")} >
            {
              url &&
              <div className={["Share-Wrapper"].join(" ")} >
                
                <div className={["Share-Title"].join(" ")}>{ this.i18n(`gc_treat_success`) }</div>
                <div className={["Share-Messages"].join(" ")}>
                  <div className={["Share-Message"].join(" ")}>{ this.i18n(`gc_treat_msg1`) }</div>
                  <div className={["Share-Message"].join(" ")}>{ this.i18n(`gc_treat_msg2`).replace("<name>", ` ${target['first_name']} ` ) }</div>
                </div>
                <div className={["Link-Holder"].join(" ")} >
                  <div className={["Link"].join(" ")} onClick={ ()=>this.copy(msg)}> 
                    <AiOutlineCopy className={["Fa"].join(" ")}/>
                  </div>

                  <div className={["Link"].join(" ")} onClick={ ()=>this.sendEmail(msg) }>
                    <AiOutlineMail className={["Fa"].join(" ")}/>
                  </div>
                  <a className={["Link"].join(" ")} href={`whatsapp://send?text=${msg}&phone=${r_phone}`}>
                    <AiOutlineWhatsApp className={["Fa"].join(" ")}/>
                  </a>
                </div>

                <div className={["Button"].join(" ")} onClick={()=>{
                  this.refresh()
                }}> { this.i18n("back") } </div>

              </div>
            }
          </div>
      )
    }
  }



  render() {

    const {  hasLogin, user, showHeader  } = this.props
    const {page, isValidUser, hasTreat} = this.state

    if( hasLogin && user ) {
      console.log( hasTreat )
      return (
        <div className={['Gift-Wrapper', !showHeader?"Top":""].join(' ')}>
          <div className={['Control'].join(' ')}>
            {
              pages.map( (v,k) => {
                return (
                  <div key={k} className='Option-Wrapper'>
                    <div 
                      className={["Option", page===k?"Selected":""].join(" ")} 
                      onClick={ ()=>{
                        if( v.key !== "share" ) {
                          if( isValidUser && !hasTreat ) {
                            this.setState({
                              page : k
                            })
                          }
                        } else {
                          if( isValidUser && hasTreat ) {
                            this.setState({
                              page : k
                            })
                          }
                        }
                      } }> 
                      {   this.i18n(`gc_${v['key']}`) } 
                    </div>
                    {
                      k < pages.length -1 &&
                      <div className='Fa'>{">>"}</div>
                    }
                  </div>
                )
              } )
            }
          </div>
          <div className={['Holder'].join(' ')} style={{
            transform : `translateX( -${100 * page}%)`
          }}>
            {
              this.findView()
            }
            {
              this.giftView()
            }
            {
              this.shareView()
            }
          </div>
        </div>
      )
    } else {
      window.location.href = window.location.origin
      return null
    }
  }
}

export default Gift;



