import React, {Component} from 'react';
import ReactDOM from 'react-dom/client';
import { Link } from 'react-router-dom'
import './index.scss';
import service from '../../public/service';


class Cover extends Component {
  constructor(props) {
    super(props)
    this.state={
      offsetY : false
    }
  }
  
  componentDidMount() {
    this.props.updatevalue({ selected : this.props.page  })
    
  }

  render() {
    if(this.props.content) {
      var content = this.props.content['home']

      var _c = this.props.content[this.props.selected]
      if( _c ) {
        document.title =  ((this.props.language in _c.display)?(_c.display[this.props.language] + " | "):"") + this.props.config['general']['name'][this.props.language]
      }
      return (
        <div className={["Cover"].join(" ")}>
          <video 
            playsInline
            muted
            autoPlay={true}
            loop={true}
            src={  window.video  }  
            className={["Video"].join(" ")} />
          <div className={["Brand-Container"].join(' ')} id={"brand"} >
            {
              this.props.brands.map( (v, k) => {
                
                if( v.enabled ) {
                  const { ui_text, asset, brand_code, name } = v
                  const {image, image_mockup, display, key, badge } = ui_text
                  const source = service.asset     
                  try {

                    return (
                      <Link
                        to={"../brand/"+brand_code.toLowerCase() }
                        className={['Box'].join(" ")} key={k}>
                          <img className={['Img'].join(" ")} src={ `${source}/${asset[ image['value'] ]['file_path']}` }  />
                          <div className={['Cover-Frame'].join(" ")}>
                            { image_mockup  && asset[ image_mockup['value'] ] &&
                              <img className={['Cover'].join(" ")} src={ `${source}/${asset[ image_mockup['value'] ]['file_path']}` } />
                            }
                            { !(image_mockup  && asset[ image_mockup['value'] ]) &&
                              <div className={['Cover-M'].join(" ")} >
                                { this.props.i18n['commingsoon'][this.props.language] }
                              </div>
                            }
                          </div>
                          <div className={["Text-Frame"].join(" ")}> 
                            <div className={["Name"].join(" ")}>{ display[this.props.language] }</div>
                            <div className={["Badges"].join(" ")}>
                              {
                                badge&&
                                badge[this.props.language].split("|").map( (v1, k1) => {
                                  return (
                                    <div className={["Badge"].join(" ")} key={k1}>
                                      {v1}
                                    </div>
                                  )
                                } )
                              }
                            </div>
                          </div>
                      </Link>
                    )
                  } catch(e) {
                    return null
                  }
                } else {
                  
                  return null
                }
              } )
            }
          </div>
          <div className={['Cover-Message-Wrapper'].join(" ")} id={"aboutus"}>
              {
                content.messages.map( (value,key) => {
                  return (
                    <div className={['Container'].join(" ")} key={key}>
                      {
                        value.map( (v,k) => {
                          if( (v.type === "title") || (v.type === "message") ) {
                            return(
                              <div 
                                key={k}
                                className={[ (v.type==="title")?"Title":"Message"  ]}> 
                                  {v.message[this.props.language]} 
                              </div>
                            )
                          } else {
                            return (
                              <div
                                className={["Image-Holder"].join(" ")} key={k}>
                                {
                                  v.images.map( (val, key) => {
                                    return(
                                      <img 
                                        style={{
                                          width : 100/v.images.length + "%"
                                        }}
                                        src={ window.location.origin+"/resources/img/" + val } 
                                        key={key} className={["Image"].join(" ")}/>
                                    )
                                  } )
                                }
                              </div>
                            )
                          }
                        } )
                      }
                    </div>
                  )
                } )
              }
          </div>
        </div>
      )
    } else {
      return null
    }
  }

}

export default Cover;